/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http'
import { Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { StrictHttpResponse } from '../../strict-http-response'
import { RequestBuilder } from '../../request-builder'

import { ConversationViewModel } from '../../models/conversation-view-model'

export interface ConversationRemoveFromBlacklist$Plain$Params {
  body?: Array<string>
}

export function conversationRemoveFromBlacklist$Plain(
  http: HttpClient,
  rootUrl: string,
  params?: ConversationRemoveFromBlacklist$Plain$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<Array<ConversationViewModel>>> {
  const rb = new RequestBuilder(rootUrl, conversationRemoveFromBlacklist$Plain.PATH, 'post')
  if (params) {
    rb.body(params.body, 'application/*+json')
  }

  return http.request(rb.build({ responseType: 'text', accept: 'text/plain', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<ConversationViewModel>>
    })
  )
}

conversationRemoveFromBlacklist$Plain.PATH = '/v2/conversations/remove-from-blacklist'
