import { CompanyVerificationStateEnum } from '@alliance/shared/domain-gql'

import { VerificationByQES } from '../models'
import { CompanyVerificationBlockingReasonFragment, QesVerificationFragment } from '../services/company-data/company-data.generated'

export const getVerificationByQes = (qesVerification: QesVerificationFragment | null, blockingReasons: CompanyVerificationBlockingReasonFragment[] | null): VerificationByQES => {
  const isPrivatePerson = blockingReasons?.find(item => item.__typename === 'CompanyIsPrivatePersonBlockingReason')

  if (isPrivatePerson) {
    return { isVerified: false, reason: 'privatePerson' }
  }

  const companyAlreadyExists = blockingReasons?.find(item => item.__typename === 'CompanyIsDuplicateByQesBlockingReason')

  if (companyAlreadyExists) {
    return { isVerified: false, reason: 'companyAlreadyExists' }
  }

  if (qesVerification && qesVerification.state === CompanyVerificationStateEnum.Verified) {
    return { isVerified: true }
  }

  return { isVerified: false, reason: null }
}
