import { ComponentRef, ElementRef, Injectable, Injector, Renderer2, RendererFactory2, Type, ViewContainerRef } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class RenderAsLazyService {
  private renderer: Renderer2

  public constructor(private injector: Injector, private rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null)
  }

  public render<C>(viewContainerRef: ViewContainerRef, component: Type<C>): ComponentRef<C> {
    const componentRef = viewContainerRef.createComponent(component, { injector: this.injector })

    const hostElement = viewContainerRef.element.nativeElement as HTMLElement

    const element = (componentRef.location as ElementRef<HTMLElement>).nativeElement

    this.renderer.appendChild(hostElement, element)

    componentRef.changeDetectorRef.markForCheck()

    return componentRef
  }

  public renderWithContextInjector<C>(viewContainerRef: ViewContainerRef, component: Type<C>, injector: Injector): ComponentRef<C> {
    const componentRef = viewContainerRef.createComponent(component, { injector })

    const hostElement = viewContainerRef.element.nativeElement as HTMLElement

    const element = (componentRef.location as ElementRef<HTMLElement>).nativeElement

    this.renderer.appendChild(hostElement, element)

    componentRef.changeDetectorRef.markForCheck()

    return componentRef
  }

  public render2<C>(viewContainerRef: ViewContainerRef, component: Type<C>, injector?: Injector): ComponentRef<C> {
    const componentRef = viewContainerRef.createComponent(component, { injector: injector || this.injector })

    componentRef.changeDetectorRef.markForCheck()

    return componentRef
  }
}
