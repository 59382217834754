import { CandidatesSearchSubPagesEnum, COMPANY_PAGE_REGEXP, VACANCY_LIST_PAGE_ONLY_CITY_REGEXP, VACANCY_PAGE_REGEXP } from '@alliance/shared/constants'
import { log } from '@alliance/shared/logger'
import { SentryInitOptions } from '@alliance/shared/sentry'

export enum APP_TRANSACTION_NAMES {
  // seeker pages
  home = 'home',
  vacancyList = 'vacancyList',
  vacancy = 'vacancy',
  company = 'company',
  // employer pages

  employerHome = 'employerHome',
  employerApplies = 'employerApplies',
  employerVacancies = 'employerVacancies',
  candidates = 'candidates',
  cv = 'cv'
}

export const APP_SENTRY_INIT_OPTIONS: SentryInitOptions = {
  dsn: 'https://aa4e88684f2e4bfaae2f78c814d574f6@o165775.ingest.sentry.io/5715474',
  tracesSampleRate: '0.01',
  transactionNamesList: Object.values(APP_TRANSACTION_NAMES),
  transactionMapping: (contextName: string): string => {
    let result = contextName

    try {
      /* grouping urls with different params and set human-readable name */
      switch (true) {
        case result === '/':
          result = APP_TRANSACTION_NAMES.home
          break
        case result === '/employer' || result === '/my':
          result = APP_TRANSACTION_NAMES.employerHome
          break
        case result === '/candidates' || (/\/candidates\/.+\/.+/.test(result) && Object.values(CandidatesSearchSubPagesEnum).every(page => !result.includes(page))):
          result = APP_TRANSACTION_NAMES.candidates
          break
        case /\/candidates\/\d+$/.test(result):
          result = APP_TRANSACTION_NAMES.cv
          break
        case result === '/my/vacancies':
          result = APP_TRANSACTION_NAMES.employerVacancies
          break
        case /\/my\/vacancies\/\d+\/applies$/.test(result):
          result = APP_TRANSACTION_NAMES.employerApplies
          break
        case result.startsWith('/zapros/'):
          result = APP_TRANSACTION_NAMES.vacancyList
          break
        case COMPANY_PAGE_REGEXP.test(result):
          result = APP_TRANSACTION_NAMES.company
          break
        case VACANCY_LIST_PAGE_ONLY_CITY_REGEXP.test(result):
          result = APP_TRANSACTION_NAMES.vacancyList
          break
        case VACANCY_PAGE_REGEXP.test(result):
          result = APP_TRANSACTION_NAMES.vacancy
          break
      }
    } catch (e) {
      log.warn({ where: 'desktop', category: 'try_catch', message: 'APP_SENTRY_INIT_OPTIONS: transaction mapping error', error: e })
    }

    return result
  }
}
