import { Injectable, NgZone } from '@angular/core'
import { MonoTypeOperatorFunction, Observable } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class NgZoneHelperService {
  public constructor(private ngZone: NgZone) {}

  public outsideNgZone<T>(): MonoTypeOperatorFunction<T> {
    return (source: Observable<T>): Observable<T> =>
      new Observable<T>(observer =>
        this.ngZone.runOutsideAngular(() =>
          source.subscribe({
            next: (value: T) => observer.next(value),
            error: (error: unknown) => observer.error(error),
            complete: () => observer.complete()
          })
        )
      )
  }

  public insideNgZone<T>(): MonoTypeOperatorFunction<T> {
    return (source: Observable<T>): Observable<T> =>
      new Observable<T>(observer =>
        source.subscribe({
          next: (value: T) => this.ngZone.run(() => observer.next(value)),
          error: (error: unknown) => this.ngZone.run(() => observer.error(error)),
          complete: () => this.ngZone.run(() => observer.complete())
        })
      )
  }
}
