import { Params } from '@angular/router'

import { LOGIN_CONTEXT_TYPE_PARAM_KEY, USER_SIDE_PARAM_KEY } from '@alliance/shared/constants'
import { UserSideEnum } from '@alliance/shared/utils'

export type EmployerLoginContextType = 'vacancyForm' | 'cvdb' | 'vacanciesList' | 'appliesList'

export const prepareLoginContextQueryParamsByType = (type: EmployerLoginContextType): Params => ({
  [USER_SIDE_PARAM_KEY]: UserSideEnum.employer,
  [LOGIN_CONTEXT_TYPE_PARAM_KEY]: type
})

export const prepareLoginContextQueryParamsByUrl = (url: string): Params => {
  if (url.startsWith('/my/vacancies/all/applies')) {
    return prepareLoginContextQueryParamsByType('appliesList')
  }

  if (url.startsWith('/my/vacancies/create')) {
    return prepareLoginContextQueryParamsByType('vacancyForm')
  }

  if (url.startsWith('/my/vacancies')) {
    return prepareLoginContextQueryParamsByType('vacanciesList')
  }

  return {}
}
