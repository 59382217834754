/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { BaseService } from '../base-service'
import { NotificationDataAccessConfiguration } from '../notification-data-access-configuration'
import { StrictHttpResponse } from '../strict-http-response'

import { MessageNotRead } from '../models/message-not-read'
import { notReadMessagesGetAll$Json } from '../fn/not-read-messages/not-read-messages-get-all-json'
import { NotReadMessagesGetAll$Json$Params } from '../fn/not-read-messages/not-read-messages-get-all-json'
import { notReadMessagesGetAll$Plain } from '../fn/not-read-messages/not-read-messages-get-all-plain'
import { NotReadMessagesGetAll$Plain$Params } from '../fn/not-read-messages/not-read-messages-get-all-plain'

@Injectable({ providedIn: 'root' })
export class NotReadMessagesService extends BaseService {
  constructor(config: NotificationDataAccessConfiguration, http: HttpClient) {
    super(config, http)
  }

  /** Path part for operation `notReadMessagesGetAll()` */
  static readonly NotReadMessagesGetAllPath = '/v1/not-read-messages'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notReadMessagesGetAll$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  notReadMessagesGetAll$Plain$Response(params?: NotReadMessagesGetAll$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MessageNotRead>>> {
    return notReadMessagesGetAll$Plain(this.http, this.rootUrl, params, context)
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notReadMessagesGetAll$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notReadMessagesGetAll$Plain(params?: NotReadMessagesGetAll$Plain$Params, context?: HttpContext): Observable<Array<MessageNotRead>> {
    return this.notReadMessagesGetAll$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<Array<MessageNotRead>>): Array<MessageNotRead> => r.body))
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notReadMessagesGetAll$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  notReadMessagesGetAll$Json$Response(params?: NotReadMessagesGetAll$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MessageNotRead>>> {
    return notReadMessagesGetAll$Json(this.http, this.rootUrl, params, context)
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notReadMessagesGetAll$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notReadMessagesGetAll$Json(params?: NotReadMessagesGetAll$Json$Params, context?: HttpContext): Observable<Array<MessageNotRead>> {
    return this.notReadMessagesGetAll$Json$Response(params, context).pipe(map((r: StrictHttpResponse<Array<MessageNotRead>>): Array<MessageNotRead> => r.body))
  }
}
