import { filter, identity, Observable, Subject } from 'rxjs'

import { NgZoneHelperService } from '@alliance/shared/utils'

import { BroadcastMessage } from '../models/broadcast-message.interface'
import { AbstractBroadcastChannel } from '../models/abstact-broadcast-channel'

export class BroadcastChannelService implements AbstractBroadcastChannel {
  private readonly broadcastChannel: BroadcastChannel

  private readonly onMessage = new Subject()

  public constructor(broadcastChannelName: string, private readonly ngZoneHelperService: NgZoneHelperService) {
    this.broadcastChannel = new BroadcastChannel(broadcastChannelName)

    this.broadcastChannel.onmessage = (message: MessageEvent): void => this.onMessage.next(message.data)
  }

  public publish<MessageType, PayloadType>(message: BroadcastMessage<MessageType, PayloadType>): void {
    this.broadcastChannel.postMessage(message)
  }

  public messagesOfType<MessageType, PayloadType>(type: MessageType, options: { runInZone: boolean } = { runInZone: false }): Observable<BroadcastMessage<MessageType, PayloadType>> {
    return this.onMessage.pipe(
      options.runInZone ? this.ngZoneHelperService.insideNgZone() : identity,
      filter((message): message is BroadcastMessage<MessageType, PayloadType> => !!message),
      filter(message => message.type === type)
    )
  }
}
