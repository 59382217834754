import { sentenceCaseTransform } from '@alliance/shared/pipes/sentence-case'
import { RUA_DOMAIN } from '../constants'
import { UA_BREADCRUMBS_FILTER, UA_SEO_MAPPING } from '../vac-list-translate/uk'

const ROBOTA_UA_SENTENCE_CASE = sentenceCaseTransform(RUA_DOMAIN)

export default {
  home: {
    title: `Робота в Україні. Працевлаштування й пошук роботи на ${RUA_DOMAIN}`,
    description: `Сайт пошуку роботи ${RUA_DOMAIN}. Зручні фільтри, якісна база вакансій і резюме - знайди кращу роботу у нас!`
  },
  employer: {
    title: `Підбір персоналу та база кандидатів — найбільша в Україні | ${ROBOTA_UA_SENTENCE_CASE}`,
    description: `Понад {perMonth} нових резюме щомісяця! {total} мільйона опублікованих резюме. Зручний пошук, безкоштовні вакансії, швидкий результат. Найміть співробітників на ${ROBOTA_UA_SENTENCE_CASE}`
  },
  page: ' | Стр. {page}',
  list: {
    ...UA_SEO_MAPPING
  },
  vacancyList: {
    ukraine: 'Україні',
    h1: '{keywords, select, null{Робота} other{Робота {keywords}}} в {cityName}',
    default: {
      title: `Вакансії в Україні. Знайти роботу на ${RUA_DOMAIN}`,
      description: `На нашому сайті завжди самі свіжі вакансії. Переглядайте список вакансій по розділах і містам.`
    }
  },
  company: {
    title: `Робота в {companyName}. Вакансії в {companyName} | ${RUA_DOMAIN}`,
    description: `{vacanciesCount} {vacanciesCount, plural, one{вакансія} few{вакансії} other{вакансій}} {companyName} на ${RUA_DOMAIN}. Хочете працювати в {companyName}. Тільки актуальні вакансії на ${RUA_DOMAIN}.`
  },
  vacancy: {
    title: `Вакансія: {vacancyName} {salary} в {cityInflected} - {companyName} | ${RUA_DOMAIN}`,
    description: `Вакансія - {vacancyName} у {cityInflected}, {companyName} ► {badges}.`,
    uah: 'грн'
  },
  prozora: {
    in: 'в',
    title: `Середня зарплата{cityInflected}. Рівень, статистика заробітної плати на ${RUA_DOMAIN}`,
    description: `Яка середня зарплата{keyword}{cityInflected}. Середній дохід, рівень, статистика заробітної плати на ${RUA_DOMAIN}`,
    ukraine: 'Україні',
    h1: {
      statistic: 'Статистика',
      forPosition: 'для позиції',
      in: 'по'
    }
  },
  cvwelcome: {
    title: `Створити резюме - скласти резюме онлайн | Конструктор резюме від ${RUA_DOMAIN}`,
    description: `${RUA_DOMAIN} - Створи резюме онлайн. Конструктор резюме від ${RUA_DOMAIN} допоможе скласти резюме онлайн безкоштовно. Шаблон резюме з фото ${RUA_DOMAIN}`,
    h1: {
      publish: 'Розмістити',
      resume: 'резюме'
    }
  },
  jsonLd: {
    androidAppName: `${RUA_DOMAIN} - робота в Україні (для пошукачів)`,
    androidEmployerAppName: `${RUA_DOMAIN} - робота в Україні`, // TEMPORARY TEXT
    iosAppName: `Робота і вакансії на ${RUA_DOMAIN}`,
    iosEmployerAppName: `Робота і вакансії на ${RUA_DOMAIN}`, // TEMPORARY TEXT
    breadcrumbs: {
      home: 'Головна',
      vacancies: 'Вакансії',
      resume: 'Резюме',
      resumeInUkraine: 'Резюме в областях України',
      job: 'Робота',
      inCity: 'в {cityInflected}',
      allUkraine: 'Вся Україна',
      city: ' в {cityName}',
      keyword: `{keywords, select, null{Робота} other{Робота {keywords}}}`,
      filter: {
        ...UA_BREADCRUMBS_FILTER
      }
    }
  },
  searchByRootPage: {
    title: `Пошук резюме - Знайти резюме співробітника в Україні — Підбір персоналу на ${RUA_DOMAIN}`,
    description: `Підбір персоналу на robota.ua - зручно і ефективно. Шукайте резюме за рубриками, у містах і іншим параметрам.`,
    defaultHeadingText: 'Пошук резюме ',
    h1: {
      rubrics: 'за рубриками',
      professions: 'за професіями',
      cities: 'за містами'
    }
  },
  searchByRubrics: {
    title: `Вакансії в Україні. Знайти роботу на ${RUA_DOMAIN}`,
    description: 'На нашому сайті завжди самі свіжі вакансії. Переглядайте список вакансій по розділах і містам.',
    h1: 'Пошук вакансій за рубриками'
  },
  searchTabCity: {
    title: `Пошук роботи по областям України - ${RUA_DOMAIN}`,
    description: `Зручний пошук роботи по областям України ✔ Кращі пропозиції роботодавців ➨ Швидкий і зручний алгоритм пошуку роботи на сайті ${RUA_DOMAIN}`,
    h1: 'Пошук роботи по містах України'
  },
  searchTabRegion: {
    title: `Пошук роботи по областям України - ${RUA_DOMAIN}`,
    description: `Зручний пошук роботи по областям України ✔ Кращі пропозиції роботодавців ➨ Швидкий і зручний алгоритм пошуку роботи на сайті ${RUA_DOMAIN}`,
    h1: 'Пошук роботи по областях України'
  },
  searchProfAlphabet: {
    title: `Пошук роботи за професіями в Україні - ${RUA_DOMAIN}`,
    description: `Зручний пошук роботи за професіями в Україні. Статистика кількості вакансій по кожній професії в Україні на сайті ${RUA_DOMAIN}`,
    h1: 'Пошук роботи за професіями'
  },
  searchProfCity: {
    title: `Пошук роботи за професіями в містах України - ${RUA_DOMAIN}`,
    description: `Зручний пошук вакансій за професіями у всіх містах України на порталі ${RUA_DOMAIN}`,
    h1: 'Пошук вакансій за професіями в Україні'
  },
  searchProfSelectCity: {
    title: `Пошук роботи за професіями в {cityName} - ${RUA_DOMAIN}`,
    description: `Зручний пошук роботи за професіями в {cityName}. Кращі пропозиції роботодавців ➨ Швидкий і зручний алгоритм пошуку роботи на сайті ${RUA_DOMAIN}`,
    h1: 'Пошук вакансій за професіями в {cityName}'
  },
  searchCompanyIndustry: {
    title: `Пошук роботи по компаніях України - ${RUA_DOMAIN}`,
    description: `Зручний пошук роботи по компаніях України ✔ Кращі пропозиції роботодавців ➨ Швидкий і зручний алгоритм пошуку роботи на сайті ${RUA_DOMAIN}`,
    h1: 'Пошук роботи по компаніях'
  },
  searchCompanyName: {
    pageLeadingText: '| Стр. {page}',
    default: {
      title: `Компанії в Україні. Каталог компаній на ${RUA_DOMAIN}`,
      description: `Компанії в Україні. Працевлаштування, вакансії і робота в Україні. Галузевий каталог компаній на порталі ${RUA_DOMAIN}`
    },
    h1: 'Пошук роботи в компаніях'
  },
  candidates: {
    pageLeadingText: '| Стр. {page}',
    ukraine: {
      title: `Пошук резюме. Кандидати по всій Україні | ${ROBOTA_UA_SENTENCE_CASE}`,
      description: `На ${ROBOTA_UA_SENTENCE_CASE} доступна велика база резюме, яка спрощує пошук кандидатів по всій Україні. Зручний функціонал дозволяє шукати працівників за запитом, розділами та параметрами.`
    },
    ukraineAndKeyword: {
      title: `{keyword} по всій Україні. Пошук співробітників — ${ROBOTA_UA_SENTENCE_CASE}`,
      description: `Знайти кандидатів на ${ROBOTA_UA_SENTENCE_CASE} стало ще простіше. Зручний пошук співробітників за запитом та розділами допоможуть підібрати кваліфікований персонал по всій Україні.`
    },
    otherCountries: {
      title: `Пошук працівників в інших країнах — ${ROBOTA_UA_SENTENCE_CASE}`,
      description: `На ${ROBOTA_UA_SENTENCE_CASE} доступна велика база резюме, яка спрощує пошук кандидатів в інших країнах. Зручний функціонал дозволяє шукати працівників за запитом, розділами та параметрами.`
    },
    otherCountriesAndKeyword: {
      title: `{keyword} в інших країнах. Пошук співробітників — ${ROBOTA_UA_SENTENCE_CASE}`,
      description: `Знайти кандидатів на ${ROBOTA_UA_SENTENCE_CASE} стало ще простіше. Зручний пошук співробітників за запитом та розділами допоможуть підібрати кваліфікований персонал в інших країнах.`
    },
    city: {
      title: `Підбір працівників у місті {cityName} на сайті ${ROBOTA_UA_SENTENCE_CASE}`,
      description: `Знайти співробітників на ${ROBOTA_UA_SENTENCE_CASE} стало ще простіше. Зручний пошук кандидатів за запитом та розділами допоможуть підібрати кваліфікований персонал у місті {cityName}.`
    },
    cityAndKeyword: {
      title: `{keyword} {cityName}. Пошук співробітників — ${ROBOTA_UA_SENTENCE_CASE}`,
      description: `Знайти кандидатів на ${ROBOTA_UA_SENTENCE_CASE} стало ще простіше. Зручний пошук співробітників за запитом та розділами допоможуть підібрати кваліфікований персонал у місті {cityName}.`
    }
  },
  candidatePage: {
    resume: 'Резюме',
    in: 'в',
    jobSearch: 'пошук роботи',
    keyInfo: 'Ключова інформація',
    lastJob: 'останнє місце роботи',
    unknown: 'невідомо',
    years: '{amount} {amount, plural, one{рік} few{роки} other{років}}',
    months: '{amount} {amount, plural, one{місяць} few{місяці} other{місяців}}'
  }
}
