import { Environment } from '@alliance/shared/environment'
import { ModuleWithProviders, NgModule } from '@angular/core'
import { TagsSuggesterTagsSuggesterApiService } from './services/tags-suggester-tags-suggester-api.service'
import { TagsSuggesterTopService } from './services/tags-suggester-top.service'
import { TagsSuggesterTopForCvService } from './services/tags-suggester-top-for-cv.service'
import { TagsSuggesterCvService } from './services/tags-suggester-cv.service'
import { TagsSuggesterSuggestService } from './services/tags-suggester-suggest.service'
import { TagsSuggesterEmployerSuggestService } from './services/tags-suggester-employer-suggest.service'
import { TagsSuggesterDataAccessConfiguration, TagsSuggesterDataAccessConfigurationParams } from './tags-suggester-data-access-configuration'

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    TagsSuggesterTagsSuggesterApiService,
    TagsSuggesterTopService,
    TagsSuggesterTopForCvService,
    TagsSuggesterCvService,
    TagsSuggesterSuggestService,
    TagsSuggesterEmployerSuggestService,
    {
      provide: TagsSuggesterDataAccessConfiguration,
      useFactory: (environment: Environment): TagsSuggesterDataAccessConfigurationParams => ({
        rootUrl: environment.tagsSuggesterApi
      }),
      deps: [Environment]
    }
  ]
})
export class TagsSuggesterDataAccessModule {
  static forRoot(params: TagsSuggesterDataAccessConfigurationParams): ModuleWithProviders<TagsSuggesterDataAccessModule> {
    return {
      ngModule: TagsSuggesterDataAccessModule,
      providers: [
        {
          provide: TagsSuggesterDataAccessConfiguration,
          useValue: params
        }
      ]
    }
  }
}
