import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { IntersectionObserveeDirective } from './directives/intersection-observee.directive'
import { IntersectionObserverDirective } from './directives/intersection-observer.directive'
import { IntersectionRootDirective } from './directives/intersection-root.directive'

@NgModule({
  declarations: [IntersectionObserveeDirective, IntersectionObserverDirective, IntersectionRootDirective],
  exports: [IntersectionObserveeDirective, IntersectionObserverDirective, IntersectionRootDirective],
  imports: [CommonModule]
})
export class SharedWebApisIntersectionObserverModule {}
