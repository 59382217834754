import { DeleteNotificationsInput, ViewNotificationsInput } from '@alliance/shared/domain-gql'
import { Injectable } from '@angular/core'
import { ApolloCache } from '@apollo/client/core'
import { Observable, of } from 'rxjs'
import { catchError, map, take } from 'rxjs/operators'
import { NotificationItemFragment, NotificationItemFragmentDoc } from '../notification-list/notification-list.generated'
import { DeleteAllNotificationsGQL, DeleteNotificationsGQL, ViewNotificationsGQL } from './notification-actions.generated'

@Injectable({ providedIn: 'root' })
export class NotificationActionsService {
  public constructor(
    private readonly viewNotificationsGQL: ViewNotificationsGQL,
    private readonly deleteNotificationsGQL: DeleteNotificationsGQL,
    private readonly deleteAllNotificationsGQL: DeleteAllNotificationsGQL
  ) {}

  public viewNotifications$(input: ViewNotificationsInput): Observable<boolean> {
    return this.viewNotificationsGQL
      .mutate(
        { input },
        {
          update: (cache, { data }) => {
            if (data?.viewNotifications?.errors) {
              return
            }

            const { notificationIds } = input

            notificationIds.forEach(id => this.updateInCache(id, { isViewed: true }, cache))
          }
        }
      )
      .pipe(
        take(1),
        map(({ data }) => !data?.viewNotifications?.errors),
        catchError(() => of(false))
      )
  }

  public deleteNotifications$(input: DeleteNotificationsInput): Observable<boolean> {
    return this.deleteNotificationsGQL
      .mutate(
        { input },
        {
          update: (cache, { data }) => {
            if (data?.deleteNotifications?.errors) {
              return
            }

            const { notificationIds } = input

            notificationIds.forEach(id => this.deleteFromCache(id, cache))
          }
        }
      )
      .pipe(
        take(1),
        map(({ data }) => !data?.deleteNotifications?.errors),
        catchError(() => of(false))
      )
  }

  public deleteAllNotifications$(notificationIds: string[]): Observable<boolean> {
    return this.deleteAllNotificationsGQL
      .mutate(
        {},
        {
          update: (cache, { data }) => {
            if (data?.deleteAllNotifications?.errors) {
              return
            }

            notificationIds.forEach(id => this.deleteFromCache(id, cache))
          }
        }
      )
      .pipe(
        take(1),
        map(({ data }) => !data?.deleteAllNotifications?.errors),
        catchError(() => of(false))
      )
  }

  private updateInCache<T = unknown>(id: string, updateFragment: Partial<NotificationItemFragment>, cache: ApolloCache<T>): void {
    const objectId = cache.identify({ __typename: 'Notification', id })

    if (!objectId) {
      return
    }

    const fragmentInCache = cache.readFragment<NotificationItemFragment>({
      id: objectId,
      fragment: NotificationItemFragmentDoc,
      fragmentName: 'NotificationItem'
    })

    if (!fragmentInCache) {
      return
    }

    cache.writeFragment({
      id: objectId,
      fragment: NotificationItemFragmentDoc,
      fragmentName: 'NotificationItem',
      data: {
        ...fragmentInCache,
        ...updateFragment
      }
    })
  }

  private deleteFromCache<T = unknown>(id: string, cache: ApolloCache<T>): void {
    const objectId = cache.identify({ __typename: 'Notification', id })

    if (!objectId) {
      return
    }

    cache.evict({
      id: objectId,
      broadcast: true
    })

    cache.gc()
  }
}
