import { DOCUMENT } from '@angular/common'
import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, inject, Inject, Input, Renderer2 } from '@angular/core'
import { INTERSECTION_ROOT } from '../intersection-observer/tokens/intersection-root'

@Component({
  selector: 'alliance-cdk-sticky-observer, [allianceCdkStickyObserver]',
  templateUrl: './sticky-observer.component.html',
  styleUrls: ['./sticky-observer.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: INTERSECTION_ROOT,
      useFactory: (): ElementRef => {
        const document = inject(DOCUMENT)

        return new ElementRef(document)
      }
    }
  ]
})
export class StickyObserverComponent {
  @Input() public set bottomRootMargin(value: [number, number, number, number]) {
    this._bottomRootMargin = `${value.map(String).join('px ')}px`
  }

  @HostBinding('class.alliance-cdk-sticky-observer-top-sticky')
  public isTopSticky = false

  @HostBinding('class.alliance-cdk-sticky-observer-bottom-sticky')
  public isBottomSticky = false

  public _bottomRootMargin = '0px 0px 0px 0px'

  public constructor(private readonly elementRef: ElementRef, private readonly renderer: Renderer2, @Inject(DOCUMENT) public readonly document: Document) {}

  public handleTopSentinelIntersection(entries: IntersectionObserverEntry[]): void {
    for (const { boundingClientRect, rootBounds } of entries) {
      if (rootBounds && boundingClientRect.top < rootBounds.top) {
        this.isTopSticky = true
      }

      if (rootBounds && boundingClientRect.bottom >= rootBounds.top && boundingClientRect.bottom < rootBounds.bottom) {
        this.isTopSticky = false
      }
    }
  }

  public handleBottomSentinelIntersection(entries: IntersectionObserverEntry[]): void {
    for (const { boundingClientRect, rootBounds, intersectionRatio } of entries) {
      if (rootBounds && boundingClientRect.bottom > rootBounds.top && intersectionRatio === 1) {
        this.renderer.removeClass(this.elementRef.nativeElement, 'alliance-cdk-sticky-observer-bottom-sticky')
      } else {
        this.renderer.addClass(this.elementRef.nativeElement, 'alliance-cdk-sticky-observer-bottom-sticky')
      }
    }
  }
}
