export enum OpenContactsResult {
  ContactsWereAlreadyOpened = 'ContactsWereAlreadyOpened',
  Succeed = 'Succeed',
  ServiceActivationIsRequired = 'ServiceActivationIsRequired',
  CompanyIsRestricted = 'CompanyIsRestricted',
  CompanyIsNotVerified = 'CompanyIsNotVerified',
  PhonesAreHidden = 'PhonesAreHidden',
  RateLimited = 'RateLimited',
  NoAvailableServices = 'NoAvailableServices',
  CompanyQesVerificationIsNotPassed = 'CompanyQesVerificationIsNotPassed'
}
