import { AllowedIndexFiltersEnum, SEOObject } from '../../openapi/model/vacancy-list.model'
import { RUA_DOMAIN } from '../constants'
import { PublishedVacanciesGenderEnum } from '@alliance/shared/domain-gql'

const genderTemplate = `{ gender, select, ${PublishedVacanciesGenderEnum.Male}{чоловіків} ${PublishedVacanciesGenderEnum.Female}{жінок} other{} }`
const genderBreadcrumbTemplate = `{ gender, select, ${PublishedVacanciesGenderEnum.Male}{чоловік} ${PublishedVacanciesGenderEnum.Female}{жінка} other{} }`

export const UA_SEO_MAPPING: Record<string, SEOObject> = {
  cityId: {
    h1: '{keywords, select, null{Робота} other{Робота {keywords}}} в {cityId}',
    title: `▷ Робота в {cityId} | Пошук вакансій в {cityId} - знайди свою роботу на ${RUA_DOMAIN}`,
    description: `Шукаєте роботу в {cityId}? ✔ Робота в {cityId} - {totalItems} актуальних вакансій в {cityId} для вас! ✌ Знайди свою роботу в {cityId} на ${RUA_DOMAIN}`
  },
  'cityId+keywords': {
    h1: '{keywords, select, null{Робота} other{Робота {keywords}}} в {cityId}',
    title: `{keywords, select, null{Робота} other{Робота {keywords}}} в {cityId}, пошук {keywords, select, null{вакансій} other{вакансій {keywords}}} в {cityId} | ${RUA_DOMAIN}`,
    description: `${RUA_DOMAIN} ✪ шукаєте {keywords, select, null{роботу} other{роботу {keywords}}} в {cityId}? ➤ {totalItems} актуальних {keywords, select, null{вакансій} other{вакансій {keywords}}} в {cityId}. ☛ Актуальні вакансії`
  },
  'cityId+districtIds': {
    h1: `Робота {districtIds} район в {cityId}`,
    title: `▷ Робота {districtIds} район в {cityId} | Пошук вакансій {districtIds} район в {cityId} - знайди свою роботу на ${RUA_DOMAIN}`,
    description: `Шукаєте роботу {districtIds} район в {cityId}? ✔ {totalItems} актуальних вакансій для вас! ✌ Знайди свою роботу на ${RUA_DOMAIN}`
  },
  'cityId+microDistrictIds': {
    h1: `Работа мікрорайон {microDistrictIds} в {cityId}`,
    title: `▷ Робота мікрорайон {microDistrictIds} в {cityId} | Пошук вакансій {microDistrictIds} мікрорайон в {cityId} - знайди свою роботу на ${RUA_DOMAIN}`,
    description: `Шукаєте роботу мікрорайон {microDistrictIds} в {cityId}? ✔ {totalItems} актуальних вакансій для вас! ✌ Знайди свою роботу на ${RUA_DOMAIN}`
  },
  'cityId+clusterKeywords+keywords': {
    h1: `Робота {keywords} {clusterKeywords} в {cityId}`,
    title: `▷ Робота {keywords} {clusterKeywords} в {cityId} | Пошук вакансій {keywords} {clusterKeywords} - знайди свою роботу на ${RUA_DOMAIN}`,
    description: `Шукаєте роботу {keywords} {clusterKeywords} в {cityId}? ✔ {totalItems} актуальних вакансій для вас! ✌ Знайди свою роботу на ${RUA_DOMAIN}`
  },
  'cityId+gender': {
    h1: `Робота популярна у ${genderTemplate} в {cityId}`,
    title: `▷ Робота популярна у ${genderTemplate} в {cityId} | Пошук вакансій популярних у ${genderTemplate} - знайди свою роботу на ${RUA_DOMAIN}`,
    description: `Шукаєте роботу популярну у ${genderTemplate} в {cityId}? ✔ {totalItems} актуальних вакансій для вас! ✌ Знайди свою роботу на ${RUA_DOMAIN}`
  },
  'cityId+gender+keywords': {
    h1: `Робота {keywords} популярна серед ${genderTemplate} в {cityId}`,
    title: `▷ Робота {keywords} популярна серед ${genderTemplate} в {cityId} | Пошук вакансій {keywords}, популярних серед ${genderTemplate} – знайди свою роботу на ${RUA_DOMAIN}`,
    description: `Шукаєте роботу {keywords}, популярну серед ${genderTemplate} в {cityId}? ✔ {totalItems} актуальних вакансій для вас! ✌ Знайдіть свою роботу на ${RUA_DOMAIN}`
  },
  'cityId+districtIds+gender': {
    h1: `Робота популярна у ${genderTemplate} {districtIds} район в {cityId}`,
    title: `▷ Робота популярна у ${genderTemplate} {districtIds} район в {cityId} | Пошук вакансій популярних у ${genderTemplate} - знайди свою роботу на ${RUA_DOMAIN}`,
    description: `Шукаєте роботу популярну у ${genderTemplate} {districtIds} район в {cityId}? ✔ {totalItems} актуальних вакансій для вас! ✌ Знайди свою роботу на ${RUA_DOMAIN}`
  },
  'cityId+gender+microDistrictIds': {
    h1: `Робота популярна у ${genderTemplate} мікрорайон {microDistrictIds} в {cityId}`,
    title: `▷ Робота популярна у ${genderTemplate} мікрорайон {microDistrictIds} в {cityId} | Пошук вакансій популярних у ${genderTemplate} - знайди свою роботу на ${RUA_DOMAIN}`,
    description: `Шукаєте роботу популярну у ${genderTemplate} мікрорайон {microDistrictIds} в {cityId}? ✔ {totalItems} актуальних вакансій для вас! ✌ Знайди свою роботу на ${RUA_DOMAIN}`
  },
  'cityId+metroBranches': {
    h1: `Робота {metroBranches} в {cityId}`,
    title: `▷ Робота {metroBranches} в {cityId} | Пошук вакансій {metroBranches} в {cityId} - знайди свою роботу на ${RUA_DOMAIN}`,
    description: `Шукаєте роботу {metroBranches} в {cityId}? ✔ {totalItems} актуальних вакансій для вас! ✌ Знайди свою роботу на ${RUA_DOMAIN}`
  },
  'cityId+gender+metroBranches': {
    h1: `Робота популярна у ${genderTemplate} {metroBranches} в {cityId}`,
    title: `▷ Робота популярна у ${genderTemplate} {metroBranches} в {cityId} | Пошук вакансій популярних у ${genderTemplate} - знайди свою роботу на ${RUA_DOMAIN}`,
    description: `Шукаєте роботу популярну у ${genderTemplate} {metroBranches} в {cityId}? ✔ {totalItems} актуальних вакансій для вас! ✌ Знайди свою роботу на ${RUA_DOMAIN}`
  },
  'age+cityId': {
    h1: `Робота популярна у пошукачів віком {age} в {cityId}`,
    title: `▷ Робота популярна у пошукачів віком {age} в {cityId} | Пошук вакансій популярних у пошукачів віком {age} - знайди свою роботу на ${RUA_DOMAIN}`,
    description: `Шукаєте роботу популярну у пошукачів віком {age} в {cityId}? ✔ {totalItems} актуальних вакансій для вас! ✌ Знайди свою роботу на ${RUA_DOMAIN}`
  },
  'age+cityId+keywords': {
    h1: `Робота {keywords} для пошукачів віком {age} в {cityId}`,
    title: `▷ Робота {keywords} для пошукачів віком  {age} в {cityId} | Пошук вакансій популярних у пошукачів віком {age} - знайди свою роботу на ${RUA_DOMAIN}`,
    description: `Шукаєте роботу {keywords} для пошукачів віком {age} в {cityId}? ✔ {totalItems} актуальних вакансій для вас! ✌ Знайди свою роботу на ${RUA_DOMAIN}`
  },
  'age+cityId+gender': {
    h1: `Робота популярна у ${genderTemplate} віком {age} в {cityId}`,
    title: `▷ Робота популярна у ${genderTemplate} віком  {age} в {cityId} | Пошук вакансій популярних серед ${genderTemplate} - знайди свою роботу на ${RUA_DOMAIN}`,
    description: `Шукаєте роботу популярна у ${genderTemplate} віком {age} в {cityId}? ✔ {totalItems} актуальних вакансій для вас! ✌ Знайди свою роботу на ${RUA_DOMAIN}`
  },
  'cityId+salary': {
    h1: `Робота з заробітною платою {salary} в {cityId}`,
    title: `▷ Робота з заробітною платою {salary} в {cityId} | Пошук вакансій з заробітною платою {salary} - знайди свою роботу на ${RUA_DOMAIN}`,
    description: `Шукаєте роботу з заробітною платою {salary} в {cityId}? ✔ {totalItems} актуальних вакансій для вас! ✌ Знайди свою роботу на ${RUA_DOMAIN}`
  },
  'additional+cityId': {
    h1: `Робота {additional} в {cityId}`,
    title: `▷ Робота {additional} в {cityId} | Пошук вакансій {additional} - знайди свою роботу на ${RUA_DOMAIN}`,
    description: `Шукаєте роботу {additional} в {cityId}? ✔ {totalItems} актуальних вакансій для вас! ✌ Знайди свою роботу на ${RUA_DOMAIN}`
  },
  'additional+cityId+keywords': {
    h1: `Робота {keywords} {additional} в {cityId}`,
    title: `▷ Робота {keywords} {additional} в {cityId} | Пошук вакансій {keywords} {additional} - знайди свою роботу на ${RUA_DOMAIN}`,
    description: `Шукаєте роботу {keywords} {additional} в {cityId}? ✔ {totalItems} актуальних вакансій для вас! ✌ Знайди свою роботу на ${RUA_DOMAIN}`
  },
  'cityId+employment': {
    h1: `Робота {employment} зайнятість в {cityId}`,
    title: `▷ Робота {employment} зайнятість в {cityId} | Пошук вакансій {employment} зайнятість - знайди свою роботу на ${RUA_DOMAIN}`,
    description: `Шукаєте роботу {employment} зайнятість в {cityId}? ✔ {totalItems} актуальних вакансій для вас! ✌ Знайди свою роботу на ${RUA_DOMAIN}`
  },
  'cityId+season': {
    h1: `Робота на {season} в {cityId}`,
    title: `▷ Робота на {season} в {cityId} | Пошук вакансій на {season} - знайди свою роботу на ${RUA_DOMAIN}`,
    description: `Шукаєте роботу на {season} в {cityId}? ✔ {totalItems} актуальних вакансій для вас! ✌ Знайди свою роботу на ${RUA_DOMAIN}`
  },
  'cityId+keywords+season': {
    h1: `Робота {keywords} на {season} в {cityId}`,
    title: `▷ Робота {keywords} на {season} в {cityId} | Пошук вакансій на {season} - знайди свою роботу на ${RUA_DOMAIN}`,
    description: `Шукаєте роботу {keywords} на {season} в {cityId}? ✔ {totalItems} актуальних вакансій для вас! ✌ Знайди свою роботу на ${RUA_DOMAIN}`
  },
  'cityId+time': {
    h1: `Робота {time} в {cityId}`,
    title: `▷ Робота {time} в {cityId} | Пошук вакансій {time} - знайди свою роботу на ${RUA_DOMAIN}`,
    description: `Шукаєте роботу {time} в {cityId}? ✔ {totalItems} актуальних вакансій для вас! ✌ Знайди свою роботу на ${RUA_DOMAIN}`
  },
  'cityId+keywords+time': {
    h1: `Робота {keywords} {time} в {cityId}`,
    title: `▷ Робота {keywords} {time} в {cityId} | Пошук вакансій {keywords} {time} - знайди свою роботу на ${RUA_DOMAIN}`,
    description: `Шукаєте роботу {keywords} {time} в {cityId}? ✔ {totalItems} актуальних вакансій для вас! ✌ Знайди свою роботу на ${RUA_DOMAIN}`
  },
  'cityId+employmentType': {
    h1: `Робота {employmentType} в {cityId}`,
    title: `▷ Робота {employmentType} в {cityId} | Пошук вакансій {employmentType} - знайди свою роботу на ${RUA_DOMAIN}`,
    description: `Шукаєте роботу {employmentType} в {cityId}? ✔ {totalItems} актуальних вакансій для вас! ✌ Знайди свою роботу на ${RUA_DOMAIN}`
  },
  'cityId+employmentType+keywords': {
    h1: `Робота {keywords} {employmentType} в {cityId}`,
    title: `▷ Робота {keywords} {employmentType} в {cityId} | Пошук вакансій {keywords} {employmentType} - знайди свою роботу на ${RUA_DOMAIN}`,
    description: `Шукаєте роботу {keywords} в {employmentType} в {cityId}? ✔ {totalItems} актуальних вакансій для вас! ✌ Знайди свою роботу на ${RUA_DOMAIN}`
  },
  'cityId+intermediary': {
    h1: `Робота {intermediary} в {cityId}`,
    title: `▷ Робота {intermediary} в {cityId} | Пошук вакансій {intermediary} - знайди свою роботу на ${RUA_DOMAIN}`,
    description: `Шукаєте роботу {intermediary} в {cityId}? ✔ {totalItems} актуальних вакансій для вас! ✌ Знайди свою роботу на ${RUA_DOMAIN}`
  },
  'cityId+intermediary+keywords': {
    h1: `Робота {keywords} {intermediary} в {cityId}`,
    title: `▷ Робота {keywords} {intermediary} в {cityId} | Пошук вакансій {keywords} {intermediary} - знайди свою роботу на ${RUA_DOMAIN}`,
    description: `Шукаєте роботу {keywords} на {intermediary} в {cityId}? ✔ {totalItems} актуальних вакансій для вас! ✌ Знайди свою роботу на ${RUA_DOMAIN}`
  },
  'cityId+scheduleType': {
    h1: `Робота з графіком {scheduleType} в {cityId}`,
    title: `▷ Робота з графіком {scheduleType} в {cityId} | Пошук вакансій з графіком {scheduleType} - знайди свою роботу на ${RUA_DOMAIN}`,
    description: `Шукаєте роботу з графіком {scheduleType} в {cityId}? ✔ {totalItems} актуальних вакансій для вас! ✌ Знайди свою роботу на ${RUA_DOMAIN}`
  },
  'cityId+keywords+scheduleType': {
    h1: `Робота {keywords} з графіком {scheduleType} в {cityId}`,
    title: `▷ Робота {keywords} з графіком {scheduleType} в {cityId} | Пошук вакансій {keywords} з графіком {scheduleType} - знайди свою роботу на ${RUA_DOMAIN}`,
    description: `Шукаєте роботу {keywords} з графіком {scheduleType} в {cityId}? ✔ {totalItems} актуальних вакансій для вас! ✌ Знайди свою роботу на ${RUA_DOMAIN}`
  },
  'cityId+gender+scheduleType': {
    h1: `Робота популярна у ${genderTemplate} з графіком {scheduleType} в {cityId}`,
    title: `▷ Робота популярна у ${genderTemplate} з графіком {scheduleType} в {cityId} | Пошук вакансій популярних у ${genderTemplate} з графіком {scheduleType} - знайди свою роботу на ${RUA_DOMAIN}`,
    description: `Шукаєте роботу популярну у ${genderTemplate} з графіком {scheduleType} в {cityId}? ✔ {totalItems} актуальних вакансій для вас! ✌ Знайди свою роботу на ${RUA_DOMAIN}`
  }
}

export const UA_BREADCRUMBS_FILTER: Partial<Record<AllowedIndexFiltersEnum, string>> = {
  [AllowedIndexFiltersEnum.districtIds]: 'Район: {districtIds}',
  [AllowedIndexFiltersEnum.microDistrictIds]: 'Мікрорайон : {microDistrictIds}',
  [AllowedIndexFiltersEnum.gender]: `Стать: ${genderBreadcrumbTemplate}`,
  [AllowedIndexFiltersEnum.clusterKeywords]: 'Кваліфікація: {clusterKeywords}',
  [AllowedIndexFiltersEnum.metroBranches]: 'Метро: {metroBranches}',
  [AllowedIndexFiltersEnum.age]: 'Вік: {age}',
  [AllowedIndexFiltersEnum.additional]: 'Додатково: {additional}',
  [AllowedIndexFiltersEnum.season]: 'Сезон: {season}',
  [AllowedIndexFiltersEnum.employment]: 'Тип зайнятості: {employment}',
  [AllowedIndexFiltersEnum.time]: 'Час: {time}',
  [AllowedIndexFiltersEnum.employmentType]: 'Працевлаштування: {employmentType}',
  [AllowedIndexFiltersEnum.intermediary]: 'Посередник: {intermediary}',
  [AllowedIndexFiltersEnum.salary]: 'Заробітна плата: {salary}',
  [AllowedIndexFiltersEnum.scheduleType]: 'Графік роботи: {scheduleType}'
}
