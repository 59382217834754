import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { CompanyVerificationService } from '@alliance/employer/company/domain'

@Injectable({
  providedIn: 'root'
})
export class CompanyInfoService {
  public isCompanyVerified$: Observable<boolean>

  public constructor(private companyVerificationService: CompanyVerificationService) {
    this.isCompanyVerified$ = this.companyVerificationService.isVerified$
  }
}
