import { CvdbService, TicketService4Detail } from '@alliance/employer/data-access'
import { Injectable } from '@angular/core'
import { Store } from '../base/store'
import { BillingModel, BillingStorePendingModel } from './billing.model'
import { ResumeContactsFilters, ResumeContactsScenariosEnum } from './models/open-contacts'

@Injectable({
  providedIn: 'root'
})
export class BillingStore extends Store<BillingModel> {
  public constructor() {
    super(new BillingModel())
  }

  public set vacancyServices(value: TicketService4Detail[]) {
    this.setStore({ vacancyServices: value })
  }

  public set contactServices(value: CvdbService[]) {
    this.setStore({ contactServices: value })
  }

  public set contactFilter(value: ResumeContactsFilters) {
    this.setStore({ contactFilter: value })
  }

  public set cvRelatedData(info: { scenario: ResumeContactsScenariosEnum; currentService: CvdbService | null; servicesToActivate: CvdbService[]; activatedServices: CvdbService[] }) {
    this.setStore({
      contactScenario: info.scenario,
      currentContactsService: info.currentService,
      contactServicesToActivate: info.servicesToActivate,
      suitableActivatedServices: info.activatedServices
    })
  }

  public set resumeServicesOpenContactsCount(count: number) {
    this.setStore({
      resumeServicesOpenContactsCount: count
    })
  }

  public setPending(name: keyof BillingStorePendingModel, status: boolean): void {
    const pending = { ...this.getValue('pending'), [name]: status }
    this.setStore({ pending })
  }
}
