import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { RejectVacancyGQL } from './vacancy-reject.generated'
import { ResultedVacancyItemFragment } from '../graphql-responses/vacancy-result.generated'
import { Injectable } from '@angular/core'

@Injectable()
export class VacancyRejectService {
  public constructor(private rejectVacancyGQL: RejectVacancyGQL) {}

  public reject(id: string, reason: string): Observable<ResultedVacancyItemFragment | null> {
    return this.rejectVacancyGQL
      .mutate({
        input: {
          vacancyId: id,
          reason
        }
      })
      .pipe(map(({ data }) => data?.vacancyRejectPublication?.vacancy ?? null))
  }
}
