import { CompanyApiDataAccessModule, EmployerDataAccessModule, PromoDataAccessModule, ServiceApiDataAccessModule, VacancyApiDataAccessModule } from '@alliance/employer/data-access'
import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { AnalyticsService } from './analytics.service'
import { provideTransferInterceptorUrlHandler } from '@alliance/shared/ssr/transfer-state'
import { EmployerTransferInterceptorUrlHandlerService } from './services/employer-transfer-interceptor-url-handler.service'

@NgModule({
  imports: [CommonModule, HttpClientModule, RouterModule, CompanyApiDataAccessModule, EmployerDataAccessModule, ServiceApiDataAccessModule, VacancyApiDataAccessModule, PromoDataAccessModule],
  providers: [AnalyticsService, provideTransferInterceptorUrlHandler(EmployerTransferInterceptorUrlHandlerService)]
})
export class EmployerApiModule {}
