import { RxStateService } from '@alliance/shared/models'
import { Inject, Injectable } from '@angular/core'
import { catchError, filter, map, Observable, of, switchMap, take } from 'rxjs'
import {
  ConversationCountersDto,
  ConversationsFetchType,
  ConversationViewModel,
  ConversationViewModelPagedResponse,
  MessageUpdateContextViewModel,
  MessageViewModel,
  ConversationService,
  ConversationsService
} from '@alliance/chat/data-access'
import { ConversationActionEnum } from './models/enums'
import { ConversationContextExtended } from './models/conversationContextExtended'
import { ChatCountService } from './chat-count.service'
import { AuthService } from '@alliance/shared/auth/api'
import { LoggingToBigQueryService, TransferService } from '@alliance/shared/utils'
import { openChatFromListEvent } from './events/chat-events'
import { WINDOW } from '@ng-web-apis/common'
import { BuildFeedbackUrlService } from '@alliance/shared/feedback/utils'

@Injectable({ providedIn: 'root' })
export class ChatStateService extends RxStateService<{
  chatIsOpen: boolean
  conversationListData: ConversationViewModelPagedResponse | null
  chatCounters: ConversationCountersDto | null
  chatTypeList: ConversationsFetchType
}> {
  public pageSize = 20

  public constructor(
    private chatCountService: ChatCountService,
    public transferService: TransferService,
    private conversationsService: ConversationsService,
    private conversationsServiceV2: ConversationService,
    private authService: AuthService,
    private bqService: LoggingToBigQueryService,
    @Inject(WINDOW) private window: Window,
    private buildFeedbackUrlService: BuildFeedbackUrlService
  ) {
    super()

    this.initState({
      chatIsOpen: false,
      conversationListData: this.select('chatIsOpen').pipe(
        filter(val => val),
        take(1),
        switchMap(() => this.authService.token$.pipe(switchMap(token => (token ? this.getConversationList$() : of(null)))))
      ),
      chatCounters: this.chatCountService.select('chatCounters'),
      chatTypeList: ConversationsFetchType.Regular
    })
  }

  public getConversationList$(pageNumber: number = 1, fetchType: ConversationsFetchType = ConversationsFetchType.Regular, session?: string): Observable<ConversationViewModelPagedResponse | null> {
    return this.conversationsServiceV2
      .conversationGetConversations$Json({
        pageSize: this.pageSize,
        pageNumber,
        fetchType,
        session
      })
      .pipe(
        map(conversationListData => ({
          ...conversationListData,
          data: this.sortList(conversationListData?.data ?? [])
        })),
        catchError(() => of(null))
      )
  }

  public getConversationById$(conversationId: string): Observable<ConversationViewModel | null> {
    return this.conversationsServiceV2.conversationGetConversation$Json({ conversationId, fetchType: ConversationsFetchType.All }).pipe(catchError(() => of(null)))
  }

  public fetchMoreConversations(fetchType: ConversationsFetchType = ConversationsFetchType.Regular): void {
    const pageNumber: number = this.get('conversationListData')?.pageNumber ?? 0
    const session = this.get('conversationListData')?.session ?? undefined
    this.hold(this.getConversationList$(pageNumber + 1, fetchType, session), newListItemsData => {
      this.set(state => ({
        conversationListData: {
          ...newListItemsData,
          data: [...(state.conversationListData?.data ?? []), ...(newListItemsData?.data ?? [])]
        }
      }))
    })
  }

  public initConversations$(context: ConversationContextExtended): Observable<ConversationViewModel | null> {
    // init after redirect from wizard or click on notification
    if (context?.conversationId) {
      const conversation = (this.get().conversationListData?.data ?? []).find(item => item.conversationId === context.conversationId) ?? null
      const initAfterWizard = context?.conversationId && context?.vacancyId
      if (!conversation) {
        return this.getConversationById$(context.conversationId).pipe(
          map(conversationData => {
            if (!conversationData) {
              return null
            }
            return {
              ...conversationData,
              lastMessage: initAfterWizard ? null : conversationData.lastMessage
            }
          })
        )
      }
      return of({
        ...conversation,
        lastMessage: initAfterWizard ? null : conversation.lastMessage
      })
    }
    // default init
    return this.createConversations$(context)
  }

  public createConversations$(context: ConversationContextExtended): Observable<ConversationViewModel | null> {
    return this.conversationsService.conversationsPost$Json({ body: context }).pipe(
      filter((data): data is ConversationViewModel => !!data),
      map(conversation => {
        this.set(state => ({
          conversationListData: {
            ...state.conversationListData,
            data: [conversation, ...(state.conversationListData?.data ?? [])].filter(
              (conversationItem, index, conversationList) => index === conversationList.findIndex(item => item.conversationId === conversationItem.conversationId)
            )
          },
          chatCounters: {
            ...state.chatCounters,
            hasRegularConversations: true
          }
        }))
        return conversation
      }),
      catchError(() => of(null))
    )
  }

  public updateConversationsList(msg: MessageViewModel | MessageUpdateContextViewModel): void {
    const currentConversation = (this.get().conversationListData?.data ?? []).find(conversation => conversation.conversationId === msg.conversationId)

    // Если приходит новое сообщение в канал, отсутствующий в нашем списке, то мы обновляем список каналов.
    if (!currentConversation) {
      this.hold(this.getConversationList$(), data => this.set({ conversationListData: data }))
    }
    // обновляем последнее сообщение и каунт непрочитаных сообщений
    if (msg.payloadType === 'message') {
      const message = msg as MessageViewModel
      this.updateCurrentConversation({
        ...currentConversation,
        unreadMessagesCount:
          !message.owned && currentConversation?.lastMessage?.id !== message.id
            ? this.chatCountService.changeCounter(currentConversation?.unreadMessagesCount, 1)
            : currentConversation?.unreadMessagesCount,
        lastMessage: message
      })
    }
    // обновляем каунт непрочитаных сообщений
    if (msg.payloadType === 'messageupdate') {
      const message = msg as MessageUpdateContextViewModel
      const readMsg = message.messageIds?.length ?? 0
      this.updateCurrentConversation({
        ...currentConversation,
        unreadMessagesCount: this.chatCountService.changeCounter(currentConversation?.unreadMessagesCount, -readMsg)
      })
    }
  }

  public performAction(conversationAction: ConversationActionEnum, conversationId: string): Observable<ConversationViewModel[] | null> {
    switch (conversationAction) {
      case ConversationActionEnum.Unarchive:
        return this.conversationsServiceV2.conversationRemoveFromArchivated$Json({ body: [conversationId] }).pipe(catchError(() => of([])))
      case ConversationActionEnum.Archive:
        return this.conversationsServiceV2.conversationArchivateConversation$Json({ body: [conversationId] }).pipe(catchError(() => of([])))
      case ConversationActionEnum.Block:
        return this.conversationsServiceV2.conversationAddToBlacklist$Json({ body: [conversationId] }).pipe(catchError(() => of([])))
      case ConversationActionEnum.Unblock:
        return this.conversationsServiceV2.conversationRemoveFromBlacklist$Json({ body: [conversationId] }).pipe(catchError(() => of([])))
      case ConversationActionEnum.Delete:
        return this.conversationsServiceV2.conversationDeleteConversation$Json({ body: [conversationId] }).pipe(catchError(() => of([])))
      case ConversationActionEnum.Report:
        this.window.open(this.buildFeedbackUrlService.feedbackUrl, '_blank')
        return of(null)
      default:
        return of(null)
    }
  }

  public openChatFromListEvent(conversationId?: string): void {
    if (conversationId) {
      this.bqService.pushToDataLayer(openChatFromListEvent(conversationId))
    }
  }

  private updateCurrentConversation(currenConversation: ConversationViewModel): void {
    this.set(state => ({
      conversationListData: {
        ...state.conversationListData,
        data: this.sortList(
          (state.conversationListData?.data ?? []).map(conversation => {
            if (conversation.conversationId === currenConversation.conversationId) {
              return currenConversation
            }
            return conversation
          })
        )
      }
    }))
  }

  private sortList(list: ConversationViewModel[]): ConversationViewModel[] {
    return list.sort((a, b) => {
      if (!!a.unreadMessagesCount !== !!b.unreadMessagesCount) {
        // Сортируем по unreadMessagesCount (true впереди)
        return (b?.unreadMessagesCount ?? 0) - (a?.unreadMessagesCount ?? 0)
      }
      // Если "unreadMessagesCount" одинаковы, сравниваем по полю "created" (от нового к старому)
      return (b.lastMessage?.created ?? 0) - (a.lastMessage?.created ?? 0)
    })
  }
}
