import { DataLayerPushEvent } from '@alliance/shared/utils'

export const DOWNLOAD_ANDROID_APP_EVENT: DataLayerPushEvent = {
  eventCategory: 'EMP_Registration',
  event: 'EMP_Registration',
  eventAction: 'Landing_publish',
  eventLabel: 'download_android'
}

export const DOWNLOAD_IOS_APP_EVENT: DataLayerPushEvent = {
  eventCategory: 'EMP_Registration',
  event: 'EMP_Registration',
  eventAction: 'Landing_publish',
  eventLabel: 'download_iOS'
}

export const LANDING_SEARCH_EVENT: DataLayerPushEvent = {
  eventCategory: 'EMP_Registration',
  event: 'EMP_Registration',
  eventAction: 'Landing_search'
}

export const LANDING_CVDB_EVENT: DataLayerPushEvent = {
  eventCategory: 'EMP_Registration',
  event: 'EMP_Registration',
  eventAction: 'Landing_cvdb'
}

export const LANDING_AI_EVENT: DataLayerPushEvent = {
  eventCategory: 'EMP_Registration',
  event: 'EMP_Registration',
  eventAction: 'Landing_Ai_work'
}

export const LANDING_SUPPORT_EVENT: DataLayerPushEvent = {
  eventCategory: 'EMP_Registration',
  event: 'EMP_Registration',
  eventAction: 'Landing_support'
}

export const LANDING_FREEMIUM_EVENT: DataLayerPushEvent = {
  eventCategory: 'EMP_Registration',
  event: 'EMP_Registration',
  eventAction: 'Landing_freemium'
}
