import { ElementRef, inject, Injectable } from '@angular/core'
import { Observable, share } from 'rxjs'

import { IntersectionObserverDirective } from '../directives/intersection-observer.directive'

@Injectable()
export class IntersectionObserveeService extends Observable<IntersectionObserverEntry[]> {
  public constructor() {
    const nativeElement: Element = inject<ElementRef<HTMLElement>>(ElementRef).nativeElement
    const observer = inject(IntersectionObserverDirective)

    super(subscriber => {
      observer.observe(nativeElement, entries => {
        subscriber.next(entries)
      })

      return (): void => {
        observer.unobserve(nativeElement)
      }
    })

    return this.pipe(share())
  }
}
