import { AuthService as AuthRequestService, LoginModel } from '@alliance/shared/auth/data-access'
import { Environment } from '@alliance/shared/environment'
import { log } from '@alliance/shared/logger'
import { CookieStorage } from '@alliance/shared/storage'
import { BROADCASR_CHANNEL_TOKEN, AbstractBroadcastChannel } from '@alliance/shared/broadcast-channel'
import { decodeToken, DetectPlatformService, GetTokenFromCookiesService, MulticastObservable } from '@alliance/shared/utils'

import { Inject, Injectable, Optional } from '@angular/core'
import { HttpErrorResponse } from '@angular/common/http'
import { DOCUMENT } from '@angular/common'
import { BehaviorSubject, MonoTypeOperatorFunction, Observable, of, pipe, Subject, throwError } from 'rxjs'
import { catchError, distinctUntilChanged, filter, map, take } from 'rxjs/operators'

import { COOKIE_TOKEN_KEY } from './constants'
import { UserData } from '../models/user-data.interface'

@Injectable({ providedIn: 'root' })
export class AuthService {
  public token$: Observable<string | null>

  public navigateAfterLogout$ = new Subject<'login' | 'home'>()

  /**
   * @deprecated use token$ instead
   */
  public tokenAppeared$: Observable<boolean>

  /**
   * @deprecated check token instead
   */
  public isLoggedIn$: Observable<boolean>

  private _token$ = new BehaviorSubject<string | null>(this.token)
  /**
   * @deprecated use token$ instead
   */
  private tokenChanged$: Observable<boolean>

  private multicast = new MulticastObservable()

  public constructor(
    private authRequestService: AuthRequestService,
    private env: Environment,
    private cookieStorage: CookieStorage,
    private platform: DetectPlatformService,
    private getTokenFromCookiesService: GetTokenFromCookiesService,
    @Inject(DOCUMENT) private readonly document: Document,
    @Optional() @Inject(BROADCASR_CHANNEL_TOKEN) private readonly broadcastChannel: AbstractBroadcastChannel | null
  ) {
    this.token$ = this._token$.asObservable().pipe(
      distinctUntilChanged((tokenPrevious, tokenCurrent) => {
        const tokenIsSame = tokenPrevious === tokenCurrent
        const emailIsSame = decodeToken<UserData>(tokenPrevious)?.email === decodeToken<UserData>(tokenCurrent)?.email
        return tokenIsSame || emailIsSame
      })
    )

    this.tokenChanged$ = this._token$.asObservable().pipe(
      distinctUntilChanged((tokenPrevious, tokenCurrent) => {
        const tokenIsSame = tokenPrevious === tokenCurrent
        const emailIsSame = decodeToken<UserData>(tokenPrevious)?.email === decodeToken<UserData>(tokenCurrent)?.email
        return tokenIsSame || emailIsSame
      }),
      map(token => !!token)
    )

    this.tokenAppeared$ = this.tokenChanged$.pipe(filter(token => !!token))
    this.isLoggedIn$ = this._token$.pipe(map(token => !!token))
  }

  public get token(): string | null {
    /* temporarily condition, later we enable it for SSR */
    if (this.platform.isServer) {
      return null
    }

    try {
      /* temporary for admin-crm, need change the backend */
      if (this.env.app === 'admin-crm') {
        const windowObj = this.document?.defaultView as Window & typeof globalThis & { CRM: { Global: { CrmApiToken: string } } }
        return windowObj?.CRM?.Global?.CrmApiToken ?? null
      }

      return this.getTokenFromCookiesService.tokenFromCookie(COOKIE_TOKEN_KEY)
    } catch (e) {
      log.log({ where: 'auth-api: AuthService', category: 'try_catch', message: 'get token failed', error: e })
      return null
    }
  }

  public get user(): UserData | null {
    return decodeToken<UserData>(this.token)
  }

  public get isEmployer(): boolean {
    const { IsEmployer = 0 } = this.user || {}
    return +IsEmployer === 1
  }

  public get isSeeker(): boolean {
    const { IsEmployer = 0 } = this.user || {}
    return +IsEmployer === 0
  }

  public isCurrentUser(userId: string): boolean {
    return userId === this.user?.MultiUserId?.toString()
  }

  public getUserFromTokenChangesByCustomComparator(comparator: (previous: UserData | null, current: UserData | null) => boolean): Observable<UserData | null> {
    return this._token$.asObservable().pipe(
      map(token => decodeToken(token)),
      distinctUntilChanged(comparator)
    )
  }

  public syncToken(): void {
    this._token$.next(this.token)
  }

  public login(model: LoginModel): Observable<string> {
    const credentials: LoginModel = {
      username: model.username,
      password: model.password,
      remember: model.remember
    } as const

    return this.authRequestService.authLogin({ model: credentials }).pipe(
      take(1),
      map(value => {
        this.syncToken()
        return value
      })
    )
  }

  public logout(redirectPage: 'login' | 'home' = 'login'): Observable<boolean> {
    return this.authRequestService.authLogout().pipe(
      take(1),
      map(() => {
        this.navigateAfterLogout$.next(redirectPage)
        this.syncToken()
        this.cookieStorage.clearAllRemovableKeysAfterLogout()
        this.broadcastChannel?.publish({ type: 'logout' })
        return true
      })
    )
  }

  public logoutOnAuthError<T>(): MonoTypeOperatorFunction<T> {
    return pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          this.logout()
            .pipe(take(1))
            .subscribe(() => {
              log.log({ where: 'AuthService', message: '401 -> logout' })
            })
        }
        return throwError(error)
      })
    )
  }

  public autoLogin(key: string): Observable<string | boolean> {
    return this.authRequestService.authAutoLogin({ request: { key } }).pipe(
      take(1),
      map(value => {
        this.syncToken()
        return value
      }),
      catchError(e => {
        log.warn({ where: 'auth-api: AuthService', category: 'api_call_failed', message: 'authAutoLogin failed', error: e })
        return of(true)
      })
    )
  }

  public refresh(): Observable<string | null> {
    return this.multicast.multicast(
      this.authRequestService.authRefresh().pipe(
        take(1),
        this.logoutOnAuthError(),
        catchError(e => {
          log.warn({ where: 'auth-api: AuthService', category: 'api_call_failed', message: 'authRefresh failed', error: e })
          return of(null)
        }),
        map(value => {
          this.syncToken()
          return value
        })
      ),
      'refresh'
    )
  }
}
