export enum AccountLinksEnum {
  home = 'home',
  candidates = 'candidates',
  vacancies = 'vacancies',
  favoriteVacancies = 'favoriteVacancies',
  dislikedVacancies = 'dislikedVacancies',
  seekerProfile = 'seekerProfile',
  login = 'login',
  users = 'users',
  help = 'help',
  blog = 'blog',
  ats = 'ats',
  aboutBlog = 'aboutBlog',
  seeker = 'seeker',
  addVacancy = 'addVacancy',
  viewVacancy = 'viewVacancy',
  register = 'register',
  newRegistrationEmployer = 'newRegistrationEmployer',
  newRegistrationJobseeker = 'newRegistrationJobseeker',
  prozora = 'prozora',
  termsOfUseEmployer = 'termsOfUseEmployer',
  termsOfUseSeeker = 'termsOfUseSeeker',
  allApplies = 'allApplies',
  turbota = 'turbota',
  jobseekerHome = 'jobseekerHome',
  jobseekerNewRecomUrl = 'jobseekerNewRecomUrl',
  servicesManagement = 'servicesManagement',
  chat = 'chat',
  services = 'services',
  support = 'support',
  thepoint = 'thepoint',
  media = 'media',
  employerDashboard = 'employerDashboard',
  resume = 'resume',
  resumeViewers = 'resumeViewers',
  applicationHistory = 'applicationHistory',
  seekerMailingLists = 'seekerMailingLists',
  myServices = 'myServices',
  about = 'about',
  seekerCvCreate = 'seekerCvCreate',
  vacanciesByRubrics = 'vacanciesByRubrics',
  publicOffer = 'publicOffer',
  privacyOffer = 'privacyOffer',
  centerTurboty = 'centerTurboty',
  eventsHistory = 'eventsHistory',
  employerSettings = 'employerSettings',
  seekerSubscriptions = 'seekerSubscriptions',
  myResumes = 'myResumes',
  budni = 'budni',
  cvWelcome = 'cvWelcome',
  howCreateResume = 'howCreateResume',
  newLogin = 'newLogin',
  atsExtension = 'atsExtension',
  wallets = 'wallets',
  profile = 'profile'
}
