import { CvDbSearchRequest, CvSearchRequest } from '@alliance/employer/data-access'
import { SearchTypeEnum } from '../../models/SearchTypeEnum'

export const getResumesSearchFilterDefault = (isUkrainian: boolean, isOnlyVeteransFeatureEnabled: boolean): CvDbSearchRequest => ({
  page: 1,
  period: CvDbSearchRequest.PeriodEnum.ThreeMonths,
  sort: CvSearchRequest.SortEnum.UpdateDate,
  searchType: SearchTypeEnum.WithSynonyms,
  ukrainian: isUkrainian,
  onlyDisliked: false,
  onlyFavorite: false,
  onlyWithCurrentNotebookNotes: false,
  showCvWithoutSalary: true,
  sex: CvDbSearchRequest.SexEnum.Any,
  cityId: 0,
  inside: false,
  onlyNew: false,
  age: undefined,
  salary: undefined,
  moveability: true,
  onlyMoveability: false,
  rubrics: [],
  languages: [],
  scheduleIds: [],
  educationIds: [],
  branchIds: [],
  experienceIds: [],
  keyWords: '',
  hasPhoto: false,
  onlyViewed: false,
  onlyWithOpenedContacts: false,
  resumeFillingTypeIds: [],
  districtIds: [],
  onlyStudents: false,
  onlyDisabled: false,
  ...(isOnlyVeteransFeatureEnabled && { onlyVeterans: false })
})
