import { EMPTY, Observable } from 'rxjs'
import { AbstractBroadcastChannel } from '../models/abstact-broadcast-channel'
import { BroadcastMessage } from '../models/broadcast-message.interface'

export class BroadcastChannelFallback implements AbstractBroadcastChannel {
  public publish<MessageType, PayloadType>(_message: BroadcastMessage<MessageType, PayloadType>): void {
    //
  }

  public messagesOfType<MessageType, PayloadType>(_type: MessageType): Observable<BroadcastMessage<MessageType, PayloadType>> {
    return EMPTY
  }
}
