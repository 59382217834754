import { Injectable } from '@angular/core'

import { Params, Router } from '@angular/router'
import { SCROLL_TO_VACANCY_PARAM_KEY } from '../constants'

@Injectable()
export class BackToVacancyService {
  public constructor(private readonly router: Router) {}

  public prepareQueryParams(vacancyId: string | null): Params {
    return vacancyId ? { [SCROLL_TO_VACANCY_PARAM_KEY]: vacancyId } : {}
  }

  public navigateBackToVacancy(vacancyId: string | null): void {
    const queryParams = this.prepareQueryParams(vacancyId)

    this.router.navigate(['/my/vacancies'], { queryParams })
  }
}
