import { prettyNumberTransform } from '@alliance/shared/pipes/pretty-number'
import { TranslationService } from '@alliance/shared/translation'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { HelpersService } from '../helpers.service'
import { Translations } from '../localization/translations'
import { EmployerPageSeoParams, PartialSeoParamsResponse, PlatformHosts } from '../models'

@Injectable({
  providedIn: 'root'
})
export class EmployerHomePageDictionaryService {
  public constructor(private translations: Translations, private translationService: TranslationService, private helpersService: HelpersService) {}

  public getParams({ params }: EmployerPageSeoParams): Observable<PartialSeoParamsResponse> {
    const path = '/employer'
    const url = this.helpersService.createURL(PlatformHosts.desktop, path)
    const total = Math.round(params.resumesCounts.total / 1e5) / 10
    const perMonth = prettyNumberTransform(params.resumesCounts.perMonth)

    return of({
      title: this.translationService.translate(this.translations.employer.title),
      description: this.translationService.translate(this.translations.employer.description, { total, perMonth }),
      canonicalUrl: url,
      alternateUrl: url,
      hrefLang: this.helpersService.createHrefLangURLs(path, path),
      noIndexNoFollow: false
    })
  }
}
