import { AuthService } from '@alliance/shared/auth/api'
import { SignalrMessageService, StreamTypeMap } from '@alliance/socket/api'
import { Injectable } from '@angular/core'
import { Observable, of, switchMap } from 'rxjs'
import { filter } from 'rxjs/operators'
import { NotificationStreamMessage, NotificationStreamMessageDetailTypeEnum } from './notification-stream-message-type'

@Injectable({ providedIn: 'root' })
export class NotificationStreamService {
  public constructor(private readonly authService: AuthService, private readonly signalrMessageService: SignalrMessageService) {}

  public getNotificationStream$(types = Object.values(NotificationStreamMessageDetailTypeEnum)): Observable<NotificationStreamMessage> {
    return this.authService.token$.pipe(switchMap(token => (token ? this.signalrMessageService.getStreamByType<NotificationStreamMessage>(StreamTypeMap.employerNotification) : of(null)))).pipe(
      filter(Boolean),
      filter(notification => types.some(type => type === notification.details?.detailType))
    )
  }
}
