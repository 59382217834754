import { inject, ModuleWithProviders, NgModule } from '@angular/core'

import { NgZoneHelperService } from '@alliance/shared/utils'

import { BROADCASR_CHANNEL_TOKEN } from './tokens/broadcast-channel.token'
import { BroadcastChannelService } from './broadcasr-channel/broadcast-channel-service'
import { BroadcastChannelFallback } from './broadcasr-channel/broadcast-channel-fallback'

@NgModule()
export class SharedBroadcastChannelModule {
  public static forBrowserRoot(): ModuleWithProviders<SharedBroadcastChannelModule> {
    return {
      ngModule: SharedBroadcastChannelModule,
      providers: [{ provide: BROADCASR_CHANNEL_TOKEN, useFactory: () => new BroadcastChannelService('browser-broadcast-channel', inject(NgZoneHelperService)) }]
    }
  }

  public static forServerRoot(): ModuleWithProviders<SharedBroadcastChannelModule> {
    return {
      ngModule: SharedBroadcastChannelModule,
      providers: [{ provide: BROADCASR_CHANNEL_TOKEN, useFactory: () => new BroadcastChannelFallback() }]
    }
  }
}
