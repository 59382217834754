import { Params, Router } from '@angular/router'
import { Environment, EnvironmentApp } from '@alliance/shared/environment'
import { RxStateService } from '@alliance/shared/models'
import { Inject, Injectable } from '@angular/core'
import { LOCATION } from '@ng-web-apis/common'
import { AuthWithOtpService } from '@alliance/shared/auth-with-otp/domain'

interface GoToLoginParams {
  cancelRedirectAfterLogin: boolean
  redirectLink: string
  queryParams: Params
}

const DEFAULT_GO_TO_LOGIN_PARAMS: GoToLoginParams = {
  cancelRedirectAfterLogin: false,
  redirectLink: '',
  queryParams: {}
}

@Injectable({ providedIn: 'root' })
export class LoginService extends RxStateService<object> {
  private readonly isDesktopSPA = this.environment.app === EnvironmentApp.desktop

  public constructor(@Inject(LOCATION) private location: Location, private environment: Environment, private router: Router, private authWithOtpService: AuthWithOtpService) {
    super()
  }

  public goToLogin(paramsInput: Partial<GoToLoginParams> = {}): void {
    const { cancelRedirectAfterLogin, redirectLink, queryParams } = { ...DEFAULT_GO_TO_LOGIN_PARAMS, ...paramsInput }

    if (this.isDesktopSPA) {
      this.authWithOtpService.goToLogin(cancelRedirectAfterLogin, redirectLink, queryParams)
      return
    }

    this.goToLoginWithRefresh()
  }

  public goToLoginWithRefresh(): void {
    this.router.navigate(['/auth/login']).then(() => this.location.reload())
  }
}
