/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http'
import { Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { StrictHttpResponse } from '../../strict-http-response'
import { RequestBuilder } from '../../request-builder'

import { ConversationViewModel } from '../../models/conversation-view-model'

export interface GetAll$Json$Params {}

export function getAll$Json(http: HttpClient, rootUrl: string, params?: GetAll$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ConversationViewModel>>> {
  const rb = new RequestBuilder(rootUrl, getAll$Json.PATH, 'get')
  if (params) {
  }

  return http.request(rb.build({ responseType: 'json', accept: 'text/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<ConversationViewModel>>
    })
  )
}

getAll$Json.PATH = '/v1/conversations'
