import { isDevMode, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { Apollo } from 'apollo-angular'
import { HttpLink } from 'apollo-angular/http'
import { Environment } from '@alliance/shared/environment'
import { GqlLanguageMiddlewareService } from '@alliance/shared/translation'
import { GetApolloClientMetricsService } from '@alliance/shared/gql/utils'
import { ApolloLink, InMemoryCache } from '@apollo/client/core'
import { APOLLO_CLIENT_NAME } from './constants/constants'
import { HttpHeaders } from '@angular/common/http'

@NgModule({
  imports: [CommonModule],
  providers: [GqlLanguageMiddlewareService]
})
export class SharedDictionaryModule {
  public constructor(
    private apollo: Apollo,
    private httpLink: HttpLink,
    private environment: Environment,
    private langMiddleware: GqlLanguageMiddlewareService,
    private getApolloClientMetricsService: GetApolloClientMetricsService
  ) {
    if (!this.apollo.use(APOLLO_CLIENT_NAME)) {
      this.createClient()
    }
  }

  private createClient(): void {
    const cache = new InMemoryCache()

    const postOnlyQueries = ['SearchKeywords'] // список кверей, которые должны идти через POST

    const dynamicMethodLink = new ApolloLink((operation, forward) => {
      const context = operation.getContext()
      const operationHeaders: unknown = operation.getContext()?.['headers']
      const headers = operationHeaders instanceof HttpHeaders ? operationHeaders : new HttpHeaders()

      const method = postOnlyQueries.includes(operation.operationName) ? 'POST' : 'GET'

      operation.setContext({
        ...context,
        method,
        headers: {
          ...headers,
          'apollo-require-preflight': 'true',
          'x-cache-control-max-age': '3600'
        }
      })
      return forward(operation) as ApolloLink
    })

    const httpLink = this.httpLink.create({
      uri: `${this.environment.graphqlApi}/graphql`,
      withCredentials: false
    })

    const link = ApolloLink.from([this.langMiddleware.getMiddleware(), dynamicMethodLink, httpLink])

    this.apollo.createNamed(APOLLO_CLIENT_NAME, {
      link,
      cache,
      ...this.getApolloClientMetricsService.apolloClientMetrics,
      connectToDevTools: isDevMode()
    })
  }
}
