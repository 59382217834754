/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum MessageUpdateOperation {
  Delivered = 'Delivered',
  Read = 'Read',
  Unread = 'Unread',
  Delete = 'Delete',
  Update = 'Update'
}
