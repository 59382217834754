import { Inject, Injectable, Optional } from '@angular/core'

import { ApolloLink, NextLink } from '@apollo/client/core'
import { onError } from '@apollo/client/link/error'

import { DetectPlatformService } from '@alliance/shared/utils'

import { isContextWithClientName, isValidGraphqlErrorExtensions } from '../constants/graphql-errors'
import { GQL_AUTH_ERROR_HANDLER_TOKEN, GqlAuthErrorHandler } from '../tokens'

@Injectable({
  providedIn: 'root'
})
export class GqlErrorHandlerMiddleware {
  private readonly allowedRetryOperations = ['getCurrentEmployerState', 'GetApplies', 'GetResume']

  public constructor(@Optional() @Inject(GQL_AUTH_ERROR_HANDLER_TOKEN) private readonly gqlAuthErrorHandler: GqlAuthErrorHandler | null, private readonly platform: DetectPlatformService) {}

  public onGqlError(): ApolloLink {
    return onError(({ operation, graphQLErrors, forward }): NextLink | null => {
      if (graphQLErrors && this.allowedRetryOperations.includes(operation.operationName)) {
        return forward(operation) as NextLink
      }
      return null
    })
  }

  public onUserAuthenticationError(): ApolloLink {
    return onError(({ graphQLErrors, operation }): NextLink | null => {
      if (!this.gqlAuthErrorHandler) {
        return null
      }

      if (this.platform.isServer || !graphQLErrors?.length) {
        return null
      }

      const { clientName, errorsCodes } = this.gqlAuthErrorHandler

      const operationContext = operation.getContext()

      const clientNameInContext = isContextWithClientName(operationContext) ? operationContext.clientName : null

      if (clientName !== clientNameInContext) {
        return null
      }

      const userIsNotAuthenticatedError = graphQLErrors.find(error => isValidGraphqlErrorExtensions(error?.extensions) && error.extensions.codes.some(code => errorsCodes.includes(code)))

      if (userIsNotAuthenticatedError) {
        this.gqlAuthErrorHandler.onErrorCallback()
      }

      return null
    })
  }
}
