import { NotificationDetailsType, NotificationDetailsUnion } from '../models/notification-details-type'
import { NotificationItem, NotificationType } from '../models/notification-item-type'
import { NotificationItemFragment } from '../services/notification-list/notification-list.generated'

export const NOTIFICATION_TYPE_MAP: Record<NotificationDetailsType, NotificationType> = {
  CompanyStateChangedDetails: NotificationType.companyStateChanged,
  LimitsChangeRequestedDetails: NotificationType.limitsChangeRequested,
  OrderActivatedByTurbotaDetails: NotificationType.orderActivatedByTurbota,
  UnpaidOrderDetails: NotificationType.unpaidOrder,
  VacancyAwaitingApprovalDetails: NotificationType.vacancyAwaitingApproval,
  VacancyBannedByModeratorDetails: NotificationType.vacancyBannedByModerator,
  VacancyEndingCountDetails: NotificationType.vacancyEndingCount,
  VacancyEndingLeftDaysDetails: NotificationType.vacancyEndingLeftDays,
  VacancyPublicationApprovedDetails: NotificationType.vacancyPublicationApproved,
  VacancyPublicationEndedDetails: NotificationType.vacancyPublicationEnded,
  VacancyPublicationRejectedDetails: NotificationType.vacancyPublicationRejected,
  CustomNotificationDetails: NotificationType.custom,
  VacancyOwnerChangedDetails: NotificationType.vacancyOwnerChanged
}

export const isNotificationDetails = (details: NotificationItemFragment['details']): details is NotificationDetailsUnion =>
  !!details && Object.keys(NOTIFICATION_TYPE_MAP).some(type => type === details.__typename)

export const mapToNotificationItem = (notification: NotificationItemFragment | null): NotificationItem | null => {
  if (!notification) {
    return null
  }

  const { details } = notification

  if (!details || !details.__typename || !isNotificationDetails(details)) {
    return null
  }

  return { ...notification, details, type: NOTIFICATION_TYPE_MAP[details.__typename] }
}
