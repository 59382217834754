/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { BaseService } from '../base-service'
import { TagsSuggesterDataAccessConfiguration } from '../tags-suggester-data-access-configuration'
import { StrictHttpResponse } from '../strict-http-response'

import { employerSuggestEndpointEmployerSuggestGet } from '../fn/employer-suggest/employer-suggest-endpoint-employer-suggest-get'
import { employerSuggestEndpointEmployerSuggestGet_1 } from '../fn/employer-suggest/employer-suggest-endpoint-employer-suggest-get-1'
import { EmployerSuggestEndpointEmployerSuggestGet_1$Params } from '../fn/employer-suggest/employer-suggest-endpoint-employer-suggest-get-1'
import { EmployerSuggestEndpointEmployerSuggestGet$Params } from '../fn/employer-suggest/employer-suggest-endpoint-employer-suggest-get'
import { EmployerSuggestResponse } from '../models/employer-suggest-response'

@Injectable({ providedIn: 'root' })
export class TagsSuggesterEmployerSuggestService extends BaseService {
  constructor(config: TagsSuggesterDataAccessConfiguration, http: HttpClient) {
    super(config, http)
  }

  /** Path part for operation `employerSuggestEndpointEmployerSuggestGet()` */
  static readonly EmployerSuggestEndpointEmployerSuggestGetPath = '/employer_suggest/'

  /**
   * Find the closest (by some distance) clusters to a given tags.
   *
   * Find the closest (by some distance) clusters to a given tags.<br><br>
   *
   * `tags (str)` - tag ids, separated with comma
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `employerSuggestEndpointEmployerSuggestGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  employerSuggestEndpointEmployerSuggestGet$Response(params: EmployerSuggestEndpointEmployerSuggestGet$Params, context?: HttpContext): Observable<StrictHttpResponse<EmployerSuggestResponse>> {
    return employerSuggestEndpointEmployerSuggestGet(this.http, this.rootUrl, params, context)
  }

  /**
   * Find the closest (by some distance) clusters to a given tags.
   *
   * Find the closest (by some distance) clusters to a given tags.<br><br>
   *
   * `tags (str)` - tag ids, separated with comma
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `employerSuggestEndpointEmployerSuggestGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  employerSuggestEndpointEmployerSuggestGet(params: EmployerSuggestEndpointEmployerSuggestGet$Params, context?: HttpContext): Observable<EmployerSuggestResponse> {
    return this.employerSuggestEndpointEmployerSuggestGet$Response(params, context).pipe(map((r: StrictHttpResponse<EmployerSuggestResponse>): EmployerSuggestResponse => r.body))
  }

  /** Path part for operation `employerSuggestEndpointEmployerSuggestGet_1()` */
  static readonly EmployerSuggestEndpointEmployerSuggestGet_1Path = '/employer_suggest'

  /**
   * Find the closest (by some distance) clusters to a given tags.
   *
   * Find the closest (by some distance) clusters to a given tags.<br><br>
   *
   * `tags (str)` - tag ids, separated with comma
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `employerSuggestEndpointEmployerSuggestGet_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  employerSuggestEndpointEmployerSuggestGet_1$Response(params: EmployerSuggestEndpointEmployerSuggestGet_1$Params, context?: HttpContext): Observable<StrictHttpResponse<EmployerSuggestResponse>> {
    return employerSuggestEndpointEmployerSuggestGet_1(this.http, this.rootUrl, params, context)
  }

  /**
   * Find the closest (by some distance) clusters to a given tags.
   *
   * Find the closest (by some distance) clusters to a given tags.<br><br>
   *
   * `tags (str)` - tag ids, separated with comma
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `employerSuggestEndpointEmployerSuggestGet_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  employerSuggestEndpointEmployerSuggestGet_1(params: EmployerSuggestEndpointEmployerSuggestGet_1$Params, context?: HttpContext): Observable<EmployerSuggestResponse> {
    return this.employerSuggestEndpointEmployerSuggestGet_1$Response(params, context).pipe(map((r: StrictHttpResponse<EmployerSuggestResponse>): EmployerSuggestResponse => r.body))
  }
}
