/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http'
import { Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { StrictHttpResponse } from '../../strict-http-response'
import { RequestBuilder } from '../../request-builder'

import { EmployerSuggestResponse } from '../../models/employer-suggest-response'

export interface EmployerSuggestEndpointEmployerSuggestGet$Params {
  tags: string
  'accept-language'?: string
}

export function employerSuggestEndpointEmployerSuggestGet(
  http: HttpClient,
  rootUrl: string,
  params: EmployerSuggestEndpointEmployerSuggestGet$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<EmployerSuggestResponse>> {
  const rb = new RequestBuilder(rootUrl, employerSuggestEndpointEmployerSuggestGet.PATH, 'get')
  if (params) {
    rb.query('tags', params.tags, {})
    rb.header('accept-language', params['accept-language'], {})
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<EmployerSuggestResponse>
    })
  )
}

employerSuggestEndpointEmployerSuggestGet.PATH = '/employer_suggest/'
