import { AllowedIndexFiltersEnum, SEOObject } from '../../openapi/model/vacancy-list.model'
import { RUA_DOMAIN } from '../constants'
import { PublishedVacanciesGenderEnum } from '@alliance/shared/domain-gql'

const genderTemplate = `{ gender, select, ${PublishedVacanciesGenderEnum.Male}{мужчин} ${PublishedVacanciesGenderEnum.Female}{женщин} other{} }`
const genderBreadcrumbTemplate = `{ gender, select, ${PublishedVacanciesGenderEnum.Male}{мужской} ${PublishedVacanciesGenderEnum.Female}{женский} other{} }`

export const RU_SEO_MAPPING: Record<string, SEOObject> = {
  cityId: {
    h1: '{keywords, select, null{Робота} other{Робота {keywords}}} в {cityId}',
    title: `▷ Работа в {cityId} | Поиск вакансий в {cityId} - найди свою работу на ${RUA_DOMAIN}`,
    description: `Ищете работу в {cityId}? ✔ Работа в {cityId} - {totalItems} актуальных вакансий в {cityId} для вас! ✌ Найти свою работу в {cityId} на ${RUA_DOMAIN}`
  },
  'cityId+keywords': {
    h1: '{keywords, select, null{Робота} other{Робота {keywords}}} в {cityId}',
    title: `{keywords, select, null{Работа} other{Работа {keywords}}} в {cityId}, поиск {keywords, select, null{вакансий} other{вакансий {keywords}}} в {cityId} | ${RUA_DOMAIN}`,
    description: `${RUA_DOMAIN} ✪ поиск {keywords, select, null{работы} other{работы {keywords}}} в {cityId}. ➤ {totalItems} актуальных {keywords, select, null{вакансий} other{вакансий {keywords}}} в {cityId}. ☛ Лучшие вакансии`
  },
  'cityId+districtIds': {
    h1: `Работа {districtIds} район в {cityId}`,
    title: `▷ Работа {districtIds} район в {cityId} | Поиск вакансий {districtIds} район в {cityId} - найди свою работу на ${RUA_DOMAIN}`,
    description: `Ищете работу {districtIds} район в {cityId}? ✔ {totalItems} актуальных вакансий для вас! ✌ Найти свою работу на ${RUA_DOMAIN}`
  },
  'cityId+microDistrictIds': {
    h1: `Работа микрорайон {microDistrictIds} в {cityId}`,
    title: `▷ Работа микрорайон {microDistrictIds} в {cityId} | Поиск вакансий микрорайон {microDistrictIds} в {cityId} - найди свою работу на ${RUA_DOMAIN}`,
    description: `Ищете работу микрорайон {microDistrictIds} в {cityId}? ✔ {totalItems} актуальных вакансий для вас! ✌ Найти свою работу на ${RUA_DOMAIN}`
  },
  'cityId+clusterKeywords+keywords': {
    h1: `Работа {keywords} {clusterKeywords} в {cityId}`,
    title: `▷ Работа {keywords} {clusterKeywords} в {cityId} | Поиск вакансий {keywords} {clusterKeywords} - найди свою работу на ${RUA_DOMAIN}`,
    description: `Ищете работу {keywords} {clusterKeywords} в {cityId}? ✔ {totalItems} актуальных вакансий для вас! ✌ Найти свою работу на ${RUA_DOMAIN}`
  },
  'cityId+gender': {
    h1: `Работа популярная среди ${genderTemplate} в {cityId}`,
    title: `▷ Работа популярная среди ${genderTemplate} в {cityId} | Поиск вакансий популярных среди ${genderTemplate} - найди свою работу на ${RUA_DOMAIN}`,
    description: `Ищете работу популярную среди ${genderTemplate} в {cityId}? ✔ {totalItems} актуальных вакансий для вас! ✌ Найти свою работу на ${RUA_DOMAIN}`
  },
  'cityId+gender+keywords': {
    h1: `Работа {keywords} популярная среди ${genderTemplate} в {cityId}`,
    title: `▷ Работа {keywords} популярная среди ${genderTemplate} в {cityId} | Поиск вакансий {keywords} популярных среди ${genderTemplate} - найди свою работу на ${RUA_DOMAIN}`,
    description: `Ищете работу {keywords} популярную среди ${genderTemplate} в {cityId} ✔ {totalItems} актуальных вакансий для вас! ✌ Найти свою работу на ${RUA_DOMAIN}`
  },
  'cityId+districtIds+gender': {
    h1: `Работа популярная среди ${genderTemplate} {districtIds} район в {cityId}`,
    title: `▷ Работа популярная среди ${genderTemplate} {districtIds} район в {cityId} | Поиск вакансий популярных среди ${genderTemplate} - найди свою работу на ${RUA_DOMAIN}`,
    description: `Ищете работу популярную среди ${genderTemplate} {districtIds} район в {cityId}? ✔ {totalItems} актуальных вакансий для вас! ✌ Найти свою работу на ${RUA_DOMAIN}`
  },
  'cityId+gender+microDistrictIds': {
    h1: `Работа популярная среди ${genderTemplate} микрорайон {microDistrictIds} в {cityId}`,
    title: `▷ Работа популярная среди ${genderTemplate} микрорайон {microDistrictIds} в {cityId} | Поиск вакансий популярных среди ${genderTemplate} - найди свою работу на ${RUA_DOMAIN}`,
    description: `Ищете работу популярную среди ${genderTemplate} микрорайон {microDistrictIds} в {cityId}? ✔ {totalItems} актуальных вакансий для вас! ✌ Найти свою работу на ${RUA_DOMAIN}`
  },
  'cityId+metroBranches': {
    h1: `Работа {metroBranches} в {cityId}`,
    title: `▷ Работа {metroBranches} в {cityId} | Поиск вакансий {metroBranches} в {cityId} - найди свою работу на ${RUA_DOMAIN}`,
    description: `Ищете работу {metroBranches} в {cityId}? ✔ {totalItems} актуальных вакансий для вас! ✌ Найти свою работу на ${RUA_DOMAIN}`
  },
  'cityId+gender+metroBranches': {
    h1: `Работа популярная среди ${genderTemplate} {metroBranches} в {cityId}`,
    title: `▷ Работа популярная среди ${genderTemplate} {metroBranches} в {cityId} | Поиск вакансий популярных среди ${genderTemplate} - найди свою работу на ${RUA_DOMAIN}`,
    description: `Ищете работу популярную среди ${genderTemplate} {metroBranches} в {cityId}? ✔ {totalItems} актуальных вакансий для вас! ✌ Найти свою работу на ${RUA_DOMAIN}`
  },
  'age+cityId': {
    h1: `Работа популярная среди соискателей возрастом {age} в {cityId}`,
    title: `▷ Работа популярная среди соискателей возрастом {age} в {cityId} | Поиск вакансий популярных среди соискателей возрастом {age} - найди свою работу на ${RUA_DOMAIN}`,
    description: `Ищете работу популярную среди соискателей возрастом {age} в {cityId}? ✔ {totalItems} актуальных вакансий для вас! ✌ Найти свою работу на ${RUA_DOMAIN}`
  },
  'age+cityId+keywords': {
    h1: `Работа {keywords} для соискателей возрастом {age} в {cityId}`,
    title: `▷ Работа {keywords} для соискателей возрастом {age} в {cityId} | Поиск вакансий популярных среди соискателей возрастом {age} - найди свою работу на ${RUA_DOMAIN}`,
    description: `Ищете работу {keywords} для соискателей возрастом {age} в {cityId}? ✔ {totalItems} актуальных вакансий для вас! ✌ Найти свою работу на ${RUA_DOMAIN}`
  },
  'age+cityId+gender': {
    h1: `Работа популярная среди ${genderTemplate} возрастом {age} в {cityId}`,
    title: `▷ Работа популярная среди ${genderTemplate} возрастом {age} в {cityId} | Поиск вакансий популярных среди ${genderTemplate} - найди свою работу на ${RUA_DOMAIN}`,
    description: `Ищете работу популярную среди ${genderTemplate} возрастом {age} в {cityId}? ✔ {totalItems} актуальных вакансий для вас! ✌ Найти свою работу на ${RUA_DOMAIN}`
  },
  'cityId+salary': {
    h1: `Работа с зарплатой {salary} в {cityId}`,
    title: `▷ Работа с зарплатой {salary} в {cityId} | Поиск вакансий с зарплатой {salary} - найди свою работу на ${RUA_DOMAIN}`,
    description: `Ищете работу с зарплатой {salary} в {cityId}? ✔ {totalItems} актуальных вакансий для вас! ✌ Найти свою работу на ${RUA_DOMAIN}`
  },
  'additional+cityId': {
    h1: `Работа {additional} в {cityId}`,
    title: `▷ Работа {additional} в {cityId} | Поиск вакансий {additional} - найди свою работу на ${RUA_DOMAIN}`,
    description: `Ищете работу {additional} в {cityId}? ✔ {totalItems} актуальных вакансий для вас! ✌ Найти свою работу на ${RUA_DOMAIN}`
  },
  'additional+cityId+keywords': {
    h1: `Работа {keywords} {additional} в {cityId}`,
    title: `▷ Работа {keywords} {additional} в {cityId} | Поиск вакансий {keywords} {additional} - найди свою работу на ${RUA_DOMAIN}`,
    description: `Ищете работу {keywords} {additional} в {cityId}? ✔ {totalItems} актуальных вакансий для вас! ✌ Найти свою работу на ${RUA_DOMAIN}`
  },
  'cityId+employment': {
    h1: `Работа {employment} занятость в {cityId}`,
    title: `▷ Работа {employment} занятость в {cityId} | Поиск вакансий {employment} занятость - найди свою работу на ${RUA_DOMAIN}`,
    description: `Ищете работу {employment} занятость в {cityId}? ✔ {totalItems} актуальных вакансий для вас! ✌ Найти свою работу на ${RUA_DOMAIN}`
  },
  'cityId+season': {
    h1: `Работа на {season} в {cityId}`,
    title: `▷ Работа на {season} в {cityId} | Поиск вакансий на {season} - найди свою работу на ${RUA_DOMAIN}`,
    description: `Ищете работу на {season} в {cityId}? ✔ {totalItems} актуальных вакансий для вас! ✌ Найти свою работу на ${RUA_DOMAIN}`
  },
  'cityId+keywords+season': {
    h1: `Работа {keywords} на {season} в {cityId}`,
    title: `▷ Работа {keywords} на {season} в {cityId} | Поиск вакансий на {season} - найди свою работу на ${RUA_DOMAIN}`,
    description: `Ищете работу {keywords} на {season} в {cityId}? ✔ {totalItems} актуальных вакансий для вас! ✌ Найти свою работу на ${RUA_DOMAIN}`
  },
  'cityId+time': {
    h1: `Работа {time} в {cityId}`,
    title: `▷ Работа {time} в {cityId} | Поиск вакансий {time} - найди свою работу на ${RUA_DOMAIN}`,
    description: `Ищете работу {time} в {cityId}? ✔ {totalItems} актуальных вакансий для вас! ✌ Найти свою работу на ${RUA_DOMAIN}`
  },
  'cityId+keywords+time': {
    h1: `Работа {keywords} {time} в {cityId}`,
    title: `▷ Работа {keywords} {time} в {cityId} | Поиск вакансий {keywords} {time} - найди свою работу на ${RUA_DOMAIN}`,
    description: `Ищете работу {keywords} {time} в {cityId}? ✔ {totalItems} актуальных вакансий для вас! ✌ Найти свою работу на ${RUA_DOMAIN}`
  },
  'cityId+employmentType': {
    h1: `Работа {employmentType} в {cityId}`,
    title: `▷ Работа {employmentType} в {cityId} | Поиск вакансий {employmentType} - найди свою работу на ${RUA_DOMAIN}`,
    description: `Ищете работу {employmentType} в {cityId}? ✔ {totalItems} актуальных вакансий для вас! ✌ Найти свою работу на ${RUA_DOMAIN}`
  },
  'cityId+employmentType+keywords': {
    h1: `Работа {keywords} {employmentType} в {cityId}`,
    title: `▷ Работа {keywords} {employmentType} в {cityId} | Поиск вакансий {keywords} {employmentType} - найди свою работу на ${RUA_DOMAIN}`,
    description: `Ищете работу {keywords} {employmentType} в {cityId}? ✔ {totalItems} актуальных вакансий для вас! ✌ Найти свою работу на ${RUA_DOMAIN}`
  },
  'cityId+intermediary': {
    h1: `Работа {intermediary} в {cityId}`,
    title: `▷ Работа {intermediary} в {cityId} | Поиск вакансий {intermediary} - найди свою работу на ${RUA_DOMAIN}`,
    description: `Ищете работу {intermediary} в {cityId}? ✔ {totalItems} актуальных вакансий для вас! ✌ Найти свою работу на ${RUA_DOMAIN}`
  },
  'cityId+intermediary+keywords': {
    h1: `Работа {keywords} {intermediary} в {cityId}`,
    title: `▷ Работа {keywords} {intermediary} в {cityId} | Поиск вакансий {keywords} {intermediary} - найди свою работу на ${RUA_DOMAIN}`,
    description: `Ищете работу {keywords} {intermediary} в {cityId}? ✔ {totalItems} актуальных вакансий для вас! ✌ Найти свою работу на ${RUA_DOMAIN}`
  },
  'cityId+scheduleType': {
    h1: `Работа с графиком {scheduleType} в {cityId}`,
    title: `▷ Работа с графиком {scheduleType} в {cityId} | Поиск вакансий с графиком {scheduleType} - найди свою работу на ${RUA_DOMAIN}`,
    description: `Ищете работу с графиком {scheduleType} в {cityId}? ✔ {totalItems} актуальных вакансий для вас! ✌ Найти свою работу на ${RUA_DOMAIN}`
  },
  'cityId+keywords+scheduleType': {
    h1: `Работа {keywords} с графиком {scheduleType} в {cityId}`,
    title: `▷ Работа {keywords} с графиком {scheduleType} в {cityId} | Поиск вакансий {keywords} с графиком {scheduleType} - найди свою работу на ${RUA_DOMAIN}`,
    description: `Ищете работу {keywords} с графиком {scheduleType} в {cityId}? ✔ {totalItems} актуальных вакансий для вас! ✌ Найти свою работу на ${RUA_DOMAIN}`
  },
  'cityId+gender+scheduleType': {
    h1: `Работа популярная среди ${genderTemplate} с графиком {scheduleType} в {cityId}`,
    title: `▷ Работа популярная среди ${genderTemplate} с графиком {scheduleType} в {cityId} | Поиск вакансий популярных среди ${genderTemplate} с графиком {scheduleType} - найди свою работу на ${RUA_DOMAIN}`,
    description: `Ищете работу популярную среди ${genderTemplate} с графиком {scheduleType} в {cityId}? ✔ {totalItems} актуальных вакансий для вас! ✌ Найти свою работу на ${RUA_DOMAIN}`
  }
}

export const RU_BREADCRUMBS_FILTER: Partial<Record<AllowedIndexFiltersEnum, string>> = {
  [AllowedIndexFiltersEnum.districtIds]: 'Район: {districtIds}',
  [AllowedIndexFiltersEnum.microDistrictIds]: 'Микрорайон: {microDistrictIds}',
  [AllowedIndexFiltersEnum.gender]: `Пол: ${genderBreadcrumbTemplate}`,
  [AllowedIndexFiltersEnum.clusterKeywords]: 'Квалификация: {clusterKeywords}',
  [AllowedIndexFiltersEnum.metroBranches]: 'Метро: {metroBranches}',
  [AllowedIndexFiltersEnum.age]: 'Возраст: {age}',
  [AllowedIndexFiltersEnum.additional]: 'Дополнительно: {additional}',
  [AllowedIndexFiltersEnum.season]: 'Сезон: {season}',
  [AllowedIndexFiltersEnum.employment]: 'Занятость: {employment}',
  [AllowedIndexFiltersEnum.time]: 'Время: {time}',
  [AllowedIndexFiltersEnum.employmentType]: 'Тип занятости: {employmentType}',
  [AllowedIndexFiltersEnum.intermediary]: 'Посредник: {intermediary}',
  [AllowedIndexFiltersEnum.salary]: 'Зарплата: {salary}',
  [AllowedIndexFiltersEnum.scheduleType]: 'График работы: {scheduleType}'
}
