export const offsetCursor = (cursor: string, offset: number): string | null => {
  if (!cursor) {
    return null
  }

  try {
    const parsedCursor = Number(atob(cursor))

    return !isNaN(parsedCursor) ? btoa(`${parsedCursor + offset}`) : null
  } catch {
    return null
  }
}
