import { log } from '@alliance/shared/logger'
import { UrlMatchResult, UrlSegment } from '@angular/router'

const getCompanyId = (segments: UrlSegment[]): UrlSegment => {
  try {
    const companyId = segments[0].toString().split('company')[1]
    return new UrlSegment(companyId, {})
  } catch (e) {
    log.warn({ where: 'desktop', category: 'try_catch', message: 'vac-page-matcher: getCompanyId failed', error: e })
    return new UrlSegment('', {})
  }
}

const getVacancyId = (segments: UrlSegment[]): UrlSegment => {
  try {
    const vacancyId = segments[1].toString().split('vacancy')[1]
    return new UrlSegment(vacancyId, {})
  } catch (e) {
    log.warn({ where: 'desktop', category: 'try_catch', message: 'vac-page-matcher: getVacancyId failed', error: e })
    return new UrlSegment('', {})
  }
}

export const VacPageRouteMatcher = (segments: UrlSegment[]): UrlMatchResult | null => {
  // Проверяем, что путь содержит хотя бы 2 сегмента (companyXXX/vacancyYYY)
  if (segments.length < 2) {
    return null
  }

  const [companySegment, vacancySegment, thirdSegment, fourthSegment] = segments

  const isCompany = /^company\d+$/.test(companySegment.path)
  const isVacancy = /^vacancy\d+$/.test(vacancySegment.path)

  // Проверяем, что первые два сегмента соответствуют шаблону "companyXXX/vacancyYYY"
  if (!isCompany || !isVacancy) {
    return null
  }

  // Формируем объект с параметрами (общий для всех случаев)
  const params = {
    companySegmentsId: getCompanyId(segments),
    vacancySegmentsId: getVacancyId(segments)
  }

  // Если только два сегмента, это страница вакансии (companyXXX/vacancyYYY)
  // company825/vacancy8431322
  if (segments.length === 2) {
    return { consumed: segments, posParams: params }
  }

  // Определяем дополнительные пути (третьи сегменты)
  const validPaths = ['open-contacts', 'apply', 'thanks-for-apply']

  // company825/vacancy8431322/apply || company825/vacancy8431322/thanks-for-apply  || company825/vacancy8431322/open-contacts
  if (segments.length === 3 && validPaths.includes(thirdSegment.path)) {
    return { consumed: [companySegment, vacancySegment], posParams: params }
  }

  // company825/vacancy8431322/apply/form
  if (segments.length === 4 && thirdSegment.path === 'apply' && fourthSegment.path === 'form') {
    return { consumed: [companySegment, vacancySegment], posParams: params }
  }

  return null
}
