import { DataLayerPushEvent } from '@alliance/shared/utils'
import { ResumeSource } from '@alliance/chat/data-access'

export const dropdownAttachEvent: DataLayerPushEvent = {
  eventCategory: 'attach_cv_added',
  event: 'Seeker_metrics',
  eventAction: 'attach_cv_added',
  eventLabel: 'chat'
}

export const applyBlockAttachEvent: DataLayerPushEvent = {
  eventCategory: 'attach_cv_added',
  event: 'Seeker_metrics',
  eventAction: 'attach_cv_added',
  eventLabel: 'start_chat'
}

export const chatApplyEvent = (applyType: ResumeSource, id?: number): DataLayerPushEvent => ({
  eventCategory: 'purchase',
  event: 'Seeker_metrics',
  eventAction: applyType === ResumeSource.AttachedFile ? 'CV_A' : 'CV',
  eventLabel: 'chat',
  transactionId: id
})
