import { CityDataLocalized, CityRegionDataLocalized, DataLocalized, LanguageDataLocalized, ResourceLocalized, RubricLocalized } from '@alliance/jobseeker/data-access'
import { Store } from '@alliance/shared/models'
import { Injectable } from '@angular/core'
import { DictionaryModel, DictionaryProfessionItem, DistrictsLocalizedMap } from './dictionary.model'

@Injectable({
  providedIn: 'root'
})
export class DictionaryStore extends Store<DictionaryModel> {
  public constructor() {
    super(new DictionaryModel())
  }

  public set rubricList(rubricList: RubricLocalized[]) {
    this.setStore({ rubricList })
  }

  public set cityList(cityList: CityDataLocalized[]) {
    this.setStore({ cityList })
  }

  public set cityWithCountries(cityWithCountry: CityDataLocalized[]) {
    this.setStore({ cityWithCountry })
  }

  public set cityAndRegions(citiesAndRegions: CityRegionDataLocalized[]) {
    this.setStore({ citiesAndRegions })
  }

  public set languageList(languageList: LanguageDataLocalized[]) {
    this.setStore({ languageList })
  }

  public set languageSkillList(languageSkillList: DataLocalized[]) {
    this.setStore({ languageSkillList })
  }

  public set scheduleList(scheduleList: DataLocalized[]) {
    this.setStore({ scheduleList })
  }

  public set branchList(branchList: DataLocalized[]) {
    this.setStore({ branchList })
  }

  public set experienceList(experienceList: DataLocalized[]) {
    this.setStore({ experienceList })
  }
  public set cvdbExperienceList(cvdbExperienceList: DataLocalized[]) {
    this.setStore({ cvdbExperienceList })
  }
  public set educationList(educationList: DataLocalized[]) {
    this.setStore({ educationList })
  }

  public set fillingTypesList(fillingTypesList: DataLocalized[]) {
    this.setStore({ fillingTypesList })
  }

  public set subRubricList(subRubricList: RubricLocalized[]) {
    this.setStore({ subRubricList })
  }

  public set professionsList(professionsList: DictionaryProfessionItem[]) {
    this.setStore({ professionsList })
  }

  public set additionalList(additionalList: ResourceLocalized[]) {
    this.setStore({ additionalList })
  }

  public set sphereList(sphereList: DataLocalized[]) {
    this.setStore({ sphereList })
  }

  public set districtsList(districtsList: DistrictsLocalizedMap) {
    this.setStore({ districtsList })
  }

  public set keywordsList(keywordsList: string[]) {
    this.setStore({ keywordsList })
  }

  public set popularKeywordsList(popularKeywordsList: DataLocalized[]) {
    this.setStore({ popularKeywordsList })
  }

  public set popularStatusApplicationExperience(statusApplicationExperience: DataLocalized[]) {
    this.setStore({ statusApplicationExperience })
  }

  public set popularStatusApplicationSalary(statusApplicationSalary: DataLocalized[]) {
    this.setStore({ statusApplicationSalary })
  }

  public isEmpty<Key extends keyof DictionaryModel>(key: Key): boolean {
    const value = this.getValue(key)
    return value === null || value === undefined || (Array.isArray(value) && !value.length)
  }

  public isDistrictsEmpty(cityId: number): boolean {
    const districts = this.getValue('districtsList')

    return !(cityId in districts)
  }
}
