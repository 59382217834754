import { Inject, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AppPromoBannersService, NEW_APP_BANNER_STORAGE_KEY } from '@alliance/jobseeker/app-promo-banner'
import { ActivationEnd, Event, Router } from '@angular/router'
import { filter, first, switchMap } from 'rxjs/operators'
import { CookieStorage } from '@alliance/shared/storage'
import { detectRobot, MediaService } from '@alliance/shared/utils'
import { NAVIGATOR } from '@ng-web-apis/common'

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [AppPromoBannersService]
})
export class SeekerAppPromoBannerModule {
  public constructor(
    private appPromoBannersService: AppPromoBannersService,
    private router: Router,
    private readonly cookieStorage: CookieStorage,
    private mediaService: MediaService,
    @Inject(NAVIGATOR) public readonly navigator: Navigator
  ) {
    const alreadyShowBanner = this.cookieStorage.getItem(NEW_APP_BANNER_STORAGE_KEY)
    if (!alreadyShowBanner && !detectRobot(navigator.userAgent)) {
      this.mediaService
        .select('isMobileScreen')
        .pipe(
          filter(Boolean),
          switchMap(() => this.router.events.pipe(first((event: Event): boolean => event instanceof ActivationEnd && !!event?.snapshot?.data?.['userSide'])))
        )
        .subscribe(routeEvent => {
          if (routeEvent && routeEvent instanceof ActivationEnd && routeEvent.snapshot.data?.['userSide'] === 'jobseeker') {
            this.appPromoBannersService.openBannerModal()
          }
        })
    }
  }
}
