import { Injectable } from '@angular/core'

import { RxStateService } from '@alliance/shared/models'
import { AuthService } from '@alliance/shared/auth/api'

import { catchError, map, Observable, of, retry, switchMap } from 'rxjs'
import { retryWhenStrategy } from '@alliance/shared/utils'
import { CompanyDataFragment, GetCompanyDataGQL } from './company-data.generated'
import { CompanyData } from '../../models'

@Injectable({ providedIn: 'root' })
export class CompanyDataService extends RxStateService<{
  companyData: CompanyDataFragment | null
}> {
  public constructor(private readonly authService: AuthService, private readonly getCompanyDataGQL: GetCompanyDataGQL) {
    super()

    this.initState({
      companyData: this.authService.token$.pipe(switchMap(token => (!!token && this.authService.isEmployer ? this.getCompanyData$() : of(null))))
    })
  }

  public get companyData$(): Observable<CompanyData | null> {
    return this.select('companyData')
  }

  public refetchCompanyData(): void {
    this.hold(this.getCompanyData$(), companyData => this.set({ companyData }))
  }

  private getCompanyData$(): Observable<CompanyDataFragment | null> {
    const id = this.authService.user?.NotebookId?.toString() ?? ''
    return this.getCompanyDataGQL.fetch({ id }, { fetchPolicy: 'no-cache' }).pipe(
      map(({ data }) => data?.company ?? null),
      retry({ delay: retryWhenStrategy() }),
      catchError(() => of(null))
    )
  }
}
