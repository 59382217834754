import { AuthService } from '@alliance/shared/auth/api'
import { PhoneConfirmationStatusEnum, SendSmsStatusEnum } from '@alliance/shared/domain-gql'
import { Environment } from '@alliance/shared/environment'
import { log } from '@alliance/shared/logger'
import { RxStateService } from '@alliance/shared/models'
import { LoggingToBigQueryService } from '@alliance/shared/utils'
import { Inject, Injectable } from '@angular/core'
import { DOCUMENT, Location } from '@angular/common'
import { HttpErrorResponse } from '@angular/common/http'
import { Platform } from '@angular/cdk/platform'
import { Params, Router } from '@angular/router'
import { catchError, Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'
import { ConfirmOtpCodeGQL, SendOtpCodeGQL, UserAccountsFragment } from './otp-code.generated'

@Injectable({
  providedIn: 'root'
})
export class AuthWithOtpService extends RxStateService<{
  userFullName: string
  userPhone: string
  userAccounts: readonly UserAccountsFragment[]
  confirmedPhone: string | null
  redirectLink: string | null
  cancelRedirectAfterLogin: boolean
  isOptLoginPhoneNotFound: boolean
}> {
  public facebook = `${this.env.usersApi ?? 'https://user-api.robota.ua'}/sso/facebook`
  public google = `${this.env.usersApi ?? 'https://user-api.robota.ua'}/sso/google`

  public constructor(
    private sendOtpCodeGQL: SendOtpCodeGQL,
    private confirmOtpCodeGQL: ConfirmOtpCodeGQL,
    private router: Router,
    private authService: AuthService,
    private env: Environment,
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    private location: Location,
    private readonly loggingToBigQueryService: LoggingToBigQueryService
  ) {
    super()
    this.initState({
      userPhone: '',
      userFullName: '',
      userAccounts: [],
      confirmedPhone: null,
      redirectLink: null,
      cancelRedirectAfterLogin: false,
      isOptLoginPhoneNotFound: false
    })
  }

  public sendOtpCode(phone: string): Observable<SendSmsStatusEnum> {
    this.set({ userPhone: phone })
    return this.sendOtpCodeGQL.mutate({ phone }).pipe(
      map(res => res.data?.users.login.otpLogin.sendConfirmation.status || SendSmsStatusEnum.Failed),
      catchError((error: HttpErrorResponse) => {
        log.log({ where: 'auth-with-otp: AuthWithOtpService', category: 'api_call_failed', message: 'sendOtpCodeGQL mutation failed', error })
        return of(SendSmsStatusEnum.Failed)
      })
    )
  }

  public navigateToOAuthService(e: Event, authService: 'facebook' | 'google'): void {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }

    const _openLink = (link: string): void => {
      if (this.document.defaultView) {
        this.document.defaultView.open(link, '_blank')
      }
    }

    if (this.platform.isBrowser) {
      if (authService === 'facebook') {
        _openLink(this.facebook)
      } else if (authService === 'google') {
        _openLink(this.google)
      }
    }
  }

  public confirmOtpCode(code: string): Observable<{ status: PhoneConfirmationStatusEnum; accounts: readonly UserAccountsFragment[]; confirmedPhone: string | null }> {
    const phone = this.get('userPhone')
    return this.confirmOtpCodeGQL.mutate({ code, phone }).pipe(
      map(res => {
        const userAccounts = res.data?.users.login.otpLogin.confirmCode.userAccounts || []
        this.set({ userAccounts })
        return {
          status: res.data?.users.login.otpLogin.confirmCode.phoneConfirmationResult.status || PhoneConfirmationStatusEnum.Failed,
          accounts: userAccounts,
          confirmedPhone: phone
        }
      }),
      catchError((error: HttpErrorResponse) => {
        log.log({ where: 'auth-with-otp: AuthWithOtpService', category: 'api_call_failed', message: 'confirmOtpCodeGQL mutation failed', error })
        return of({ status: PhoneConfirmationStatusEnum.Failed, accounts: [], confirmedPhone: null })
      })
    )
  }

  public loginUserWithToken(token: string, login: string): void {
    const redirectLink = this.get('redirectLink')
    const cancelRedirectAfterLogin = this.get('cancelRedirectAfterLogin')
    this.hold(this.authService.autoLogin(token), res => {
      this.loggingToBigQueryService.pushToDataLayer({
        event: 'autoEvent',
        eventCategory: 'Login',
        eventAction: 'LoginComplete',
        eventLabel: login
      })
      if (res) {
        if (redirectLink || cancelRedirectAfterLogin) {
          this.handleCustomRedirects(login)
          return
        }

        this.authService.isEmployer
          ? this.router.navigate(['/my'])
          : this.router.navigate(['/my/recommendations'], {
              queryParams: {
                afterLogin: true
              }
            })
      }
    })
  }

  public handleCustomRedirects(login: string): void {
    const redirectLink = this.get('redirectLink')
    const cancelRedirectAfterLogin = this.get('cancelRedirectAfterLogin')

    if (redirectLink) {
      this.applyLoginAnalytics(redirectLink, login)
      this.router.navigate([redirectLink])
      return
    }

    if (cancelRedirectAfterLogin) {
      this.location.back()
    }
  }

  public goToLogin(cancelRedirectAfterLogin: boolean = false, redirectLink = '', queryParams: Params = {}): void {
    this.set({ redirectLink, cancelRedirectAfterLogin })
    this.router.navigate(['/auth/login'], { queryParams })
  }

  private applyLoginAnalytics(redirectLink: string, login: string): void {
    if (redirectLink.includes('apply')) {
      this.loggingToBigQueryService.pushToDataLayer({
        event: 'autoEvent',
        eventCategory: 'Login',
        eventAction: 'Login_apply',
        eventLabel: login
      })
    }
  }
}
