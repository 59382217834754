/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http'
import { Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { StrictHttpResponse } from '../../strict-http-response'
import { RequestBuilder } from '../../request-builder'

import { GetConversationByEmailResponse } from '../../models/get-conversation-by-email-response'

export interface ConversationGetConversationByOppositeUser$Json$Params {
  userId?: string
}

export function conversationGetConversationByOppositeUser$Json(
  http: HttpClient,
  rootUrl: string,
  params?: ConversationGetConversationByOppositeUser$Json$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<GetConversationByEmailResponse>> {
  const rb = new RequestBuilder(rootUrl, conversationGetConversationByOppositeUser$Json.PATH, 'get')
  if (params) {
    rb.query('userId', params.userId, {})
  }

  return http.request(rb.build({ responseType: 'json', accept: 'text/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<GetConversationByEmailResponse>
    })
  )
}

conversationGetConversationByOppositeUser$Json.PATH = '/v2/conversations/by-opposite-user'
