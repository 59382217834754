export const ignoreErrors: string[] = [
  'NS_ERROR_FILE_CORRUPTED',
  'top.GLOBALS',
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  `Can't find variable: ZiteReader`,
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',
  'fb_xd_fragment',
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  'conduitPage',
  'fbq is not defined',
  `'fbq' is not defined`,
  `"fbq" is not defined`,
  `'fbq' is undefined`,
  `"fbq" is undefined`,
  `Can't find variable: fbq`,
  'QuotaExceededError',
  "Failed to execute 'atob' on 'Window': The string to be decoded is not correctly encoded.",
  '0 Unknown Error',
  'Method not found'
]

export const denyUrls: Array<RegExp | string> = [
  /static\.criteo\.net/,
  /connect\.facebook\.net/,
  /vk\.com/,
  /facebook\.net/,
  /ulogin\.ru/,
  /anyplacetrivial\.com/,
  /sociomantic\.com/,
  /mookie1\.com/,
  /crashesdisband\.com/,
  /davebestdeals\.com/,
  /awaybird\.ru/,
  /hwcdn\.net/,
  /returnfastnow\.com/,
  /mecash\.ru/,
  /myclk\.net/,
  /advpartners\.org/,
  /creativecdn\.com/,
  /akamaihd\.net/,
  /fwdcdn\.com/,
  /mixpanel\.com/,
  /googlesyndication\.com/,
  /adhigh\.net/,
  /yandex\.ru/,
  /doubleclick\.net/,
  /graph\.facebook\.com/i,
  /connect\.facebook\.net\/en_US\/all\.js/i,
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  /extensions\//i,
  /^chrome:\/\//i,
  /127\.0\.0\.1:4001\/isrunning/i,
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  /gemius\.pl/,
  /gde-default\.hit\.gemius\.pl/,
  /googletagmanager\.com/
]
