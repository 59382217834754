import { VacancyStatus } from '@alliance/shared/domain-gql'
import { TailwindCustomConfig } from '@alliance/santa/tailwind'

export enum StatusesFilterEnum {
  published = 'published',
  waiting = 'waiting',
  reject = 'reject',
  suspended = 'suspended',
  closed = 'closed',
  draft = 'draft'
}

export const StatusesFilterMap: Record<StatusesFilterEnum, VacancyStatus[]> = {
  published: [VacancyStatus.Published],
  waiting: [VacancyStatus.WaitingForModeratorApproval, VacancyStatus.WaitingForAdminApproval],
  reject: [VacancyStatus.Banned, VacancyStatus.RejectedByModerator, VacancyStatus.RejectedByAdmin],
  suspended: [VacancyStatus.Suspended],
  closed: [VacancyStatus.Closed],
  draft: [VacancyStatus.Draft]
}

export const StatusesFilterColorsMap: Record<StatusesFilterEnum, string> = {
  published: TailwindCustomConfig.THEME.colors.green['600'],
  waiting: TailwindCustomConfig.THEME.colors.yellow['600'],
  reject: TailwindCustomConfig.THEME.colors.red['600'],
  suspended: TailwindCustomConfig.THEME.colors.black['500'],
  closed: TailwindCustomConfig.THEME.colors.black['500'],
  draft: TailwindCustomConfig.THEME.colors.black['500']
}
