import { log } from '@alliance/shared/logger'

export const dataUrlToBlob = (dataUrl: string): Blob | null => {
  try {
    const byteString = atob(dataUrl.split(',')[1])
    const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0]

    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }
    return new Blob([ab], { type: mimeString })
  } catch (e) {
    log.error({ where: 'shared-utils: dataUrlToBlob', category: 'try_catch', message: 'dataUrlToBlob failed', error: e })
    return null
  }
}
