import { AccountService, SiteUser } from '@alliance/employer/data-access'
import { AuthService } from '@alliance/shared/auth/api'
import { log } from '@alliance/shared/logger'
import { RuavarsService } from '@alliance/shared/utils'
import { HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { catchError, distinctUntilChanged, filter, map, retry } from 'rxjs/operators'
import GetUserInfoParams = AccountService.GetUserInfoParams

/**
 * @deprecated use {@link CompanyApiService} instead
 */
@Injectable({
  providedIn: 'root'
})
export class UserInfoService {
  private userInfo$ = new BehaviorSubject<SiteUser | null>(null)

  public constructor(private accountService: AccountService, private authenticationService: AuthService, private ruavarsService: RuavarsService) {
    this.authenticationService.token$
      .pipe(
        filter(token => !!token),
        distinctUntilChanged()
      )
      .subscribe(() => this.fetchUserInfo())
  }

  public getUserInfo$(): Observable<SiteUser | null> {
    return this.userInfo$.asObservable().pipe(filter(userInfo => !!userInfo))
  }

  public updateUserInfo(): void {
    this.fetchUserInfo()
  }

  public getCompanyPhoneIsConfirmed$(): Observable<boolean> {
    return this.getUserInfo$().pipe(map(user => !!user?.company?.isPaid || !!user?.company?.isEnableTestService))
  }

  private fetchUserInfo(): void {
    const params: GetUserInfoParams = {
      email: this.authenticationService.user?.email || '',
      includeCompanyInfo: true
    }
    this.accountService
      .getUserInfo(params)
      .pipe(
        retry(1),
        catchError((error: HttpErrorResponse) => {
          log.warn({ where: 'employer-api: UserInfoService', category: 'api_call_failed', message: 'getUserInfo failed', error })
          return of(null)
        })
      )
      .subscribe(
        (userInfo: SiteUser | null) => {
          this.userInfo$.next(userInfo)

          this.ruavarsService.setInfo({
            notebookId: userInfo?.notebookId,
            isEmployer: userInfo?.isEmployer,
            isJobsearcher: !userInfo?.isEmployer,
            cvdbAccess: userInfo?.company?.cvdbAccess,
            isConfirmed: userInfo?.company?.isConfirmed,
            isEnableActivateService: userInfo?.company?.isEnableActivateService,
            multiUserNeedApproval: userInfo?.multiUserNeedApproval,
            isPaid: userInfo?.company?.isPaid,
            isEverPublishVacancy: userInfo?.company?.isEverPublishVacancy,
            addDate: userInfo?.company?.addDate,
            tryCvDb: userInfo?.company?.tryCvDb,
            isExistTickets: userInfo?.company?.isExistTickets,
            userEMail: userInfo?.userEMail,
            hasFreeBusinessService: userInfo?.company?.hasFreeBusinessService,
            canHaveFreeBusinessService: userInfo?.company?.canHaveFreeBusinessService,
            multiUserRoleId: userInfo?.multiUserRoleId,
            multiUserId: userInfo?.multiUserId,
            emailId: userInfo?.emailId
          })

          if (userInfo && !userInfo.company) {
            log.warn({ where: 'employer-api: UserInfoService', category: 'api_call_failed', message: 'getUserInfo: company null' })
          }
        },
        e => {
          log.warn({ where: 'employer-api: UserInfoService', category: 'api_call_failed', message: 'getUserInfo failed', error: e })
        }
      )
  }
}
