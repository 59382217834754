import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { StickyObserverComponent } from './sticky-observer.component'
import { SharedWebApisIntersectionObserverModule } from '../intersection-observer/shared-web-apis-intersection-observer.module'

@NgModule({
  imports: [CommonModule, SharedWebApisIntersectionObserverModule],
  declarations: [StickyObserverComponent],
  exports: [StickyObserverComponent]
})
export class StickyObserverComponentModule {}
