export interface VacancyListModel {
  filters: { [key: string]: string }
  totalItems: number
  url: string
}

export enum AllowedIndexFiltersEnum {
  cityId = 'cityId',
  keywords = 'keywords',
  page = 'page',
  districtIds = 'districtIds',
  microDistrictIds = 'microDistrictIds',
  gender = 'gender',
  clusterKeywords = 'clusterKeywords',
  metroBranches = 'metroBranches',
  age = 'age',
  additional = 'additional',
  season = 'season',
  employment = 'employment',
  time = 'time',
  employmentType = 'employmentType',
  intermediary = 'intermediary',
  salary = 'salary',
  scheduleType = 'scheduleType'
}

// Тип для комбинации фильтров
export type FilterCombination<T extends AllowedIndexFiltersEnum[]> = [...T]

export type AllowedFilters = Partial<Record<AllowedIndexFiltersEnum, string>>

export const AllowedFilterCombinations: Array<FilterCombination<AllowedIndexFiltersEnum[]>> = [
  [AllowedIndexFiltersEnum.cityId],
  [AllowedIndexFiltersEnum.cityId, AllowedIndexFiltersEnum.keywords],
  [AllowedIndexFiltersEnum.cityId, AllowedIndexFiltersEnum.metroBranches],
  [AllowedIndexFiltersEnum.cityId, AllowedIndexFiltersEnum.gender, AllowedIndexFiltersEnum.metroBranches],
  [AllowedIndexFiltersEnum.cityId, AllowedIndexFiltersEnum.districtIds],
  [AllowedIndexFiltersEnum.cityId, AllowedIndexFiltersEnum.microDistrictIds],
  [AllowedIndexFiltersEnum.cityId, AllowedIndexFiltersEnum.clusterKeywords, AllowedIndexFiltersEnum.keywords],
  [AllowedIndexFiltersEnum.cityId, AllowedIndexFiltersEnum.gender],
  [AllowedIndexFiltersEnum.cityId, AllowedIndexFiltersEnum.districtIds, AllowedIndexFiltersEnum.gender],
  [AllowedIndexFiltersEnum.cityId, AllowedIndexFiltersEnum.gender, AllowedIndexFiltersEnum.keywords],
  [AllowedIndexFiltersEnum.cityId, AllowedIndexFiltersEnum.gender, AllowedIndexFiltersEnum.microDistrictIds],
  [AllowedIndexFiltersEnum.age, AllowedIndexFiltersEnum.cityId],
  [AllowedIndexFiltersEnum.age, AllowedIndexFiltersEnum.cityId, AllowedIndexFiltersEnum.keywords],
  [AllowedIndexFiltersEnum.age, AllowedIndexFiltersEnum.cityId, AllowedIndexFiltersEnum.gender],
  [AllowedIndexFiltersEnum.cityId, AllowedIndexFiltersEnum.salary],
  [AllowedIndexFiltersEnum.additional, AllowedIndexFiltersEnum.cityId],
  [AllowedIndexFiltersEnum.additional, AllowedIndexFiltersEnum.cityId, AllowedIndexFiltersEnum.keywords],
  [AllowedIndexFiltersEnum.cityId, AllowedIndexFiltersEnum.employment],
  [AllowedIndexFiltersEnum.cityId, AllowedIndexFiltersEnum.season],
  [AllowedIndexFiltersEnum.cityId, AllowedIndexFiltersEnum.season, AllowedIndexFiltersEnum.keywords],
  [AllowedIndexFiltersEnum.cityId, AllowedIndexFiltersEnum.time],
  [AllowedIndexFiltersEnum.cityId, AllowedIndexFiltersEnum.keywords, AllowedIndexFiltersEnum.time],
  [AllowedIndexFiltersEnum.cityId, AllowedIndexFiltersEnum.employmentType],
  [AllowedIndexFiltersEnum.cityId, AllowedIndexFiltersEnum.employmentType, AllowedIndexFiltersEnum.keywords],
  [AllowedIndexFiltersEnum.cityId, AllowedIndexFiltersEnum.intermediary],
  [AllowedIndexFiltersEnum.cityId, AllowedIndexFiltersEnum.intermediary, AllowedIndexFiltersEnum.keywords],
  [AllowedIndexFiltersEnum.cityId, AllowedIndexFiltersEnum.scheduleType],
  [AllowedIndexFiltersEnum.cityId, AllowedIndexFiltersEnum.scheduleType, AllowedIndexFiltersEnum.keywords],
  [AllowedIndexFiltersEnum.cityId, AllowedIndexFiltersEnum.gender, AllowedIndexFiltersEnum.scheduleType]
]

export type SEOObject = {
  h1: string
  title: string
  description: string
}

export interface VacListSeoParams {
  totalItems: number
  combinationKey: string
  filters: AllowedFilters | null
}
