import { AuthService } from '@alliance/shared/auth/api'
import { RxStateService } from '@alliance/shared/models'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { catchError, map, switchMap, take, tap } from 'rxjs/operators'
import { CENTER_NOTIFICATION_STREAM_MESSAGE_TYPE_LIST } from '../../constants/center-notification-stream-message-type-list'
import { NotificationStreamService } from '../notification-stream/notification-stream.service'
import { GetUnreadNotificationsCountGQL, ReadNotificationsGQL } from './notifications-count.generated'

@Injectable({ providedIn: 'root' })
export class NotificationsCountService extends RxStateService<{
  unreadCount: number | null
}> {
  public constructor(
    private readonly authService: AuthService,
    private readonly notificationStreamService: NotificationStreamService,
    private readonly getUnreadNotificationsCountGQL: GetUnreadNotificationsCountGQL,
    private readonly readNotificationsGQL: ReadNotificationsGQL
  ) {
    super()

    this.initState({
      unreadCount: this.authService.token$.pipe(switchMap(token => (!!token && this.authService.isEmployer ? this.fetchUnreadNotificationsCount$() : of(null))))
    })

    this.hold(this.notificationStreamService.getNotificationStream$(CENTER_NOTIFICATION_STREAM_MESSAGE_TYPE_LIST), () => this.increaseCount())
  }

  public readNotifications$(): Observable<boolean> {
    return this.readNotificationsGQL.mutate().pipe(
      take(1),
      map(({ data }) => !data?.acquaintWithNotifications?.errors),
      tap(isSuccessful => isSuccessful && this.resetCount()),
      catchError(() => of(false))
    )
  }

  private fetchUnreadNotificationsCount$(): Observable<number | null> {
    return this.getUnreadNotificationsCountGQL.fetch({}, { fetchPolicy: 'no-cache' }).pipe(
      map(({ data }) => data?.notifications?.totalCount || null),
      catchError(() => of(null))
    )
  }

  private increaseCount(): void {
    this.set(({ unreadCount }) => ({ unreadCount: (unreadCount || 0) + 1 }))
  }

  private resetCount(): void {
    this.set({ unreadCount: 0 })
  }
}
