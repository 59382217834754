import { CvDbService, ResumeService as EmployerResumeService } from '@alliance/employer/data-access'
import { Environment } from '@alliance/shared/environment'
import { TransferInterceptorUrlHandler } from '@alliance/shared/ssr/transfer-state'
import { Injectable } from '@angular/core'

@Injectable()
export class EmployerTransferInterceptorUrlHandlerService implements TransferInterceptorUrlHandler {
  private readonly ignoreUrls = [
    new URL(CvDbService.searchPath, this.environment.employerApi || '').toString(),
    new URL(EmployerResumeService.getPath.split('/{')[0], this.environment.employerApi || '').toString()
  ]

  public constructor(private readonly environment: Environment) {}

  public check(url: string): boolean {
    return !this.ignoreUrls.some(ignoredUrl => url.includes(ignoredUrl))
  }
}
