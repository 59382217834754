import { NotificationStreamMessageDetailTypeEnum } from '../services/notification-stream/notification-stream-message-type'

export const CENTER_NOTIFICATION_STREAM_MESSAGE_TYPE_LIST: NotificationStreamMessageDetailTypeEnum[] = [
  NotificationStreamMessageDetailTypeEnum.CompanyStateChanged,
  NotificationStreamMessageDetailTypeEnum.Custom,
  NotificationStreamMessageDetailTypeEnum.LimitsChangeRequested,
  NotificationStreamMessageDetailTypeEnum.OrderActivatedByTurbota,
  NotificationStreamMessageDetailTypeEnum.PublicationEnded,
  NotificationStreamMessageDetailTypeEnum.UnpaidOrderReceived,
  NotificationStreamMessageDetailTypeEnum.VacancyAwaitingApproval,
  NotificationStreamMessageDetailTypeEnum.VacancyBannedByModerator,
  NotificationStreamMessageDetailTypeEnum.VacancyEndingLeftCount,
  NotificationStreamMessageDetailTypeEnum.VacancyEndingLeftDays,
  NotificationStreamMessageDetailTypeEnum.VacancyPublicationApproved,
  NotificationStreamMessageDetailTypeEnum.VacancyPublicationRejected
]
