import { MobileBarData } from '@alliance/shared/mobile-navigation-bar/utils'
import { RxStateComponent } from '@alliance/shared/models'
import { MediaService, UserSideEnum } from '@alliance/shared/utils'
import { AfterViewInit, ChangeDetectionStrategy, Component, Injector, Input, ViewContainerRef, ɵcreateInjector as createInjector } from '@angular/core'
import { from, of } from 'rxjs'
import { catchError, filter, retry, switchMap, take } from 'rxjs/operators'

@Component({
  selector: 'app-mobile-navigation-bar',
  template: '',
  styleUrls: ['./shell.component.tw.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShellMobileNavigationBarComponent
  extends RxStateComponent<{
    userSide: UserSideEnum | null
    mobileBarData: MobileBarData | null
  }>
  implements AfterViewInit
{
  public constructor(private _viewContainerRef: ViewContainerRef, private _injector: Injector, private mediaService: MediaService) {
    super()
  }

  @Input()
  public set userSide(userSide: UserSideEnum | null) {
    this.set({ userSide })
  }

  @Input()
  public set mobileBarData(mobileBarData: MobileBarData | null) {
    this.set({ mobileBarData })
  }

  public ngAfterViewInit(): void {
    this.hold(
      this.mediaService.select('isMobileScreen').pipe(
        filter(Boolean),
        take(1),
        switchMap(() => from(import('../lazy-mobile-navigation-bar/lazy-mobile-navigation-bar.module'))),
        retry(2),
        catchError(() => of(null)),
        filter(Boolean)
      ),
      ({ LazyMobileNavigationBarModule }) => {
        const injector = createInjector(LazyMobileNavigationBarModule, this._injector)
        const module = injector.get(LazyMobileNavigationBarModule)
        const component = module.render(this._viewContainerRef)

        component.instance.connect('userSide', this.select('userSide'))
        component.instance.connect('mobileBarData', this.select('mobileBarData'))
      }
    )
  }
}
