/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { BaseService } from '../base-service'
import { NotificationDataAccessConfiguration } from '../notification-data-access-configuration'
import { StrictHttpResponse } from '../strict-http-response'

import { conversationAddToBlacklist$Json } from '../fn/conversation/conversation-add-to-blacklist-json'
import { ConversationAddToBlacklist$Json$Params } from '../fn/conversation/conversation-add-to-blacklist-json'
import { conversationAddToBlacklist$Plain } from '../fn/conversation/conversation-add-to-blacklist-plain'
import { ConversationAddToBlacklist$Plain$Params } from '../fn/conversation/conversation-add-to-blacklist-plain'
import { conversationArchivateConversation$Json } from '../fn/conversation/conversation-archivate-conversation-json'
import { ConversationArchivateConversation$Json$Params } from '../fn/conversation/conversation-archivate-conversation-json'
import { conversationArchivateConversation$Plain } from '../fn/conversation/conversation-archivate-conversation-plain'
import { ConversationArchivateConversation$Plain$Params } from '../fn/conversation/conversation-archivate-conversation-plain'
import { ConversationCountersDto } from '../models/conversation-counters-dto'
import { conversationDeleteConversation$Json } from '../fn/conversation/conversation-delete-conversation-json'
import { ConversationDeleteConversation$Json$Params } from '../fn/conversation/conversation-delete-conversation-json'
import { conversationDeleteConversation$Plain } from '../fn/conversation/conversation-delete-conversation-plain'
import { ConversationDeleteConversation$Plain$Params } from '../fn/conversation/conversation-delete-conversation-plain'
import { conversationGetConversation$Json } from '../fn/conversation/conversation-get-conversation-json'
import { ConversationGetConversation$Json$Params } from '../fn/conversation/conversation-get-conversation-json'
import { conversationGetConversation$Plain } from '../fn/conversation/conversation-get-conversation-plain'
import { ConversationGetConversation$Plain$Params } from '../fn/conversation/conversation-get-conversation-plain'
import { conversationGetConversationByEmail$Json } from '../fn/conversation/conversation-get-conversation-by-email-json'
import { ConversationGetConversationByEmail$Json$Params } from '../fn/conversation/conversation-get-conversation-by-email-json'
import { conversationGetConversationByEmail$Plain } from '../fn/conversation/conversation-get-conversation-by-email-plain'
import { ConversationGetConversationByEmail$Plain$Params } from '../fn/conversation/conversation-get-conversation-by-email-plain'
import { conversationGetConversationByOppositeUser$Json } from '../fn/conversation/conversation-get-conversation-by-opposite-user-json'
import { ConversationGetConversationByOppositeUser$Json$Params } from '../fn/conversation/conversation-get-conversation-by-opposite-user-json'
import { conversationGetConversationByOppositeUser$Plain } from '../fn/conversation/conversation-get-conversation-by-opposite-user-plain'
import { ConversationGetConversationByOppositeUser$Plain$Params } from '../fn/conversation/conversation-get-conversation-by-opposite-user-plain'
import { conversationGetConversationCounters$Json } from '../fn/conversation/conversation-get-conversation-counters-json'
import { ConversationGetConversationCounters$Json$Params } from '../fn/conversation/conversation-get-conversation-counters-json'
import { conversationGetConversationCounters$Plain } from '../fn/conversation/conversation-get-conversation-counters-plain'
import { ConversationGetConversationCounters$Plain$Params } from '../fn/conversation/conversation-get-conversation-counters-plain'
import { conversationGetConversations$Json } from '../fn/conversation/conversation-get-conversations-json'
import { ConversationGetConversations$Json$Params } from '../fn/conversation/conversation-get-conversations-json'
import { conversationGetConversations$Plain } from '../fn/conversation/conversation-get-conversations-plain'
import { ConversationGetConversations$Plain$Params } from '../fn/conversation/conversation-get-conversations-plain'
import { conversationRemoveFromArchivated$Json } from '../fn/conversation/conversation-remove-from-archivated-json'
import { ConversationRemoveFromArchivated$Json$Params } from '../fn/conversation/conversation-remove-from-archivated-json'
import { conversationRemoveFromArchivated$Plain } from '../fn/conversation/conversation-remove-from-archivated-plain'
import { ConversationRemoveFromArchivated$Plain$Params } from '../fn/conversation/conversation-remove-from-archivated-plain'
import { conversationRemoveFromBlacklist$Json } from '../fn/conversation/conversation-remove-from-blacklist-json'
import { ConversationRemoveFromBlacklist$Json$Params } from '../fn/conversation/conversation-remove-from-blacklist-json'
import { conversationRemoveFromBlacklist$Plain } from '../fn/conversation/conversation-remove-from-blacklist-plain'
import { ConversationRemoveFromBlacklist$Plain$Params } from '../fn/conversation/conversation-remove-from-blacklist-plain'
import { ConversationViewModel } from '../models/conversation-view-model'
import { ConversationViewModelPagedResponse } from '../models/conversation-view-model-paged-response'
import { GetConversationByEmailResponse } from '../models/get-conversation-by-email-response'

@Injectable({ providedIn: 'root' })
export class ConversationService extends BaseService {
  constructor(config: NotificationDataAccessConfiguration, http: HttpClient) {
    super(config, http)
  }

  /** Path part for operation `conversationDeleteConversation()` */
  static readonly ConversationDeleteConversationPath = '/v2/conversations/delete-archived'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversationDeleteConversation$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  conversationDeleteConversation$Plain$Response(params?: ConversationDeleteConversation$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ConversationViewModel>>> {
    return conversationDeleteConversation$Plain(this.http, this.rootUrl, params, context)
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversationDeleteConversation$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  conversationDeleteConversation$Plain(params?: ConversationDeleteConversation$Plain$Params, context?: HttpContext): Observable<Array<ConversationViewModel>> {
    return this.conversationDeleteConversation$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<Array<ConversationViewModel>>): Array<ConversationViewModel> => r.body))
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversationDeleteConversation$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  conversationDeleteConversation$Json$Response(params?: ConversationDeleteConversation$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ConversationViewModel>>> {
    return conversationDeleteConversation$Json(this.http, this.rootUrl, params, context)
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversationDeleteConversation$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  conversationDeleteConversation$Json(params?: ConversationDeleteConversation$Json$Params, context?: HttpContext): Observable<Array<ConversationViewModel>> {
    return this.conversationDeleteConversation$Json$Response(params, context).pipe(map((r: StrictHttpResponse<Array<ConversationViewModel>>): Array<ConversationViewModel> => r.body))
  }

  /** Path part for operation `conversationArchivateConversation()` */
  static readonly ConversationArchivateConversationPath = '/v2/conversations/archive'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversationArchivateConversation$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  conversationArchivateConversation$Plain$Response(params?: ConversationArchivateConversation$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ConversationViewModel>>> {
    return conversationArchivateConversation$Plain(this.http, this.rootUrl, params, context)
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversationArchivateConversation$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  conversationArchivateConversation$Plain(params?: ConversationArchivateConversation$Plain$Params, context?: HttpContext): Observable<Array<ConversationViewModel>> {
    return this.conversationArchivateConversation$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<Array<ConversationViewModel>>): Array<ConversationViewModel> => r.body))
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversationArchivateConversation$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  conversationArchivateConversation$Json$Response(params?: ConversationArchivateConversation$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ConversationViewModel>>> {
    return conversationArchivateConversation$Json(this.http, this.rootUrl, params, context)
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversationArchivateConversation$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  conversationArchivateConversation$Json(params?: ConversationArchivateConversation$Json$Params, context?: HttpContext): Observable<Array<ConversationViewModel>> {
    return this.conversationArchivateConversation$Json$Response(params, context).pipe(map((r: StrictHttpResponse<Array<ConversationViewModel>>): Array<ConversationViewModel> => r.body))
  }

  /** Path part for operation `conversationRemoveFromArchivated()` */
  static readonly ConversationRemoveFromArchivatedPath = '/v2/conversations/un-archive'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversationRemoveFromArchivated$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  conversationRemoveFromArchivated$Plain$Response(params?: ConversationRemoveFromArchivated$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ConversationViewModel>>> {
    return conversationRemoveFromArchivated$Plain(this.http, this.rootUrl, params, context)
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversationRemoveFromArchivated$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  conversationRemoveFromArchivated$Plain(params?: ConversationRemoveFromArchivated$Plain$Params, context?: HttpContext): Observable<Array<ConversationViewModel>> {
    return this.conversationRemoveFromArchivated$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<Array<ConversationViewModel>>): Array<ConversationViewModel> => r.body))
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversationRemoveFromArchivated$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  conversationRemoveFromArchivated$Json$Response(params?: ConversationRemoveFromArchivated$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ConversationViewModel>>> {
    return conversationRemoveFromArchivated$Json(this.http, this.rootUrl, params, context)
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversationRemoveFromArchivated$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  conversationRemoveFromArchivated$Json(params?: ConversationRemoveFromArchivated$Json$Params, context?: HttpContext): Observable<Array<ConversationViewModel>> {
    return this.conversationRemoveFromArchivated$Json$Response(params, context).pipe(map((r: StrictHttpResponse<Array<ConversationViewModel>>): Array<ConversationViewModel> => r.body))
  }

  /** Path part for operation `conversationAddToBlacklist()` */
  static readonly ConversationAddToBlacklistPath = '/v2/conversations/add-to-blacklist'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversationAddToBlacklist$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  conversationAddToBlacklist$Plain$Response(params?: ConversationAddToBlacklist$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ConversationViewModel>>> {
    return conversationAddToBlacklist$Plain(this.http, this.rootUrl, params, context)
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversationAddToBlacklist$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  conversationAddToBlacklist$Plain(params?: ConversationAddToBlacklist$Plain$Params, context?: HttpContext): Observable<Array<ConversationViewModel>> {
    return this.conversationAddToBlacklist$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<Array<ConversationViewModel>>): Array<ConversationViewModel> => r.body))
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversationAddToBlacklist$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  conversationAddToBlacklist$Json$Response(params?: ConversationAddToBlacklist$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ConversationViewModel>>> {
    return conversationAddToBlacklist$Json(this.http, this.rootUrl, params, context)
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversationAddToBlacklist$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  conversationAddToBlacklist$Json(params?: ConversationAddToBlacklist$Json$Params, context?: HttpContext): Observable<Array<ConversationViewModel>> {
    return this.conversationAddToBlacklist$Json$Response(params, context).pipe(map((r: StrictHttpResponse<Array<ConversationViewModel>>): Array<ConversationViewModel> => r.body))
  }

  /** Path part for operation `conversationRemoveFromBlacklist()` */
  static readonly ConversationRemoveFromBlacklistPath = '/v2/conversations/remove-from-blacklist'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversationRemoveFromBlacklist$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  conversationRemoveFromBlacklist$Plain$Response(params?: ConversationRemoveFromBlacklist$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ConversationViewModel>>> {
    return conversationRemoveFromBlacklist$Plain(this.http, this.rootUrl, params, context)
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversationRemoveFromBlacklist$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  conversationRemoveFromBlacklist$Plain(params?: ConversationRemoveFromBlacklist$Plain$Params, context?: HttpContext): Observable<Array<ConversationViewModel>> {
    return this.conversationRemoveFromBlacklist$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<Array<ConversationViewModel>>): Array<ConversationViewModel> => r.body))
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversationRemoveFromBlacklist$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  conversationRemoveFromBlacklist$Json$Response(params?: ConversationRemoveFromBlacklist$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ConversationViewModel>>> {
    return conversationRemoveFromBlacklist$Json(this.http, this.rootUrl, params, context)
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversationRemoveFromBlacklist$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  conversationRemoveFromBlacklist$Json(params?: ConversationRemoveFromBlacklist$Json$Params, context?: HttpContext): Observable<Array<ConversationViewModel>> {
    return this.conversationRemoveFromBlacklist$Json$Response(params, context).pipe(map((r: StrictHttpResponse<Array<ConversationViewModel>>): Array<ConversationViewModel> => r.body))
  }

  /** Path part for operation `conversationGetConversations()` */
  static readonly ConversationGetConversationsPath = '/v2/conversations/all'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversationGetConversations$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversationGetConversations$Plain$Response(params?: ConversationGetConversations$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ConversationViewModelPagedResponse>> {
    return conversationGetConversations$Plain(this.http, this.rootUrl, params, context)
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversationGetConversations$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversationGetConversations$Plain(params?: ConversationGetConversations$Plain$Params, context?: HttpContext): Observable<ConversationViewModelPagedResponse> {
    return this.conversationGetConversations$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<ConversationViewModelPagedResponse>): ConversationViewModelPagedResponse => r.body))
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversationGetConversations$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversationGetConversations$Json$Response(params?: ConversationGetConversations$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ConversationViewModelPagedResponse>> {
    return conversationGetConversations$Json(this.http, this.rootUrl, params, context)
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversationGetConversations$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversationGetConversations$Json(params?: ConversationGetConversations$Json$Params, context?: HttpContext): Observable<ConversationViewModelPagedResponse> {
    return this.conversationGetConversations$Json$Response(params, context).pipe(map((r: StrictHttpResponse<ConversationViewModelPagedResponse>): ConversationViewModelPagedResponse => r.body))
  }

  /** Path part for operation `conversationGetConversationByEmail()` */
  static readonly ConversationGetConversationByEmailPath = '/v2/conversations/by-email'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversationGetConversationByEmail$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversationGetConversationByEmail$Plain$Response(params?: ConversationGetConversationByEmail$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetConversationByEmailResponse>> {
    return conversationGetConversationByEmail$Plain(this.http, this.rootUrl, params, context)
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversationGetConversationByEmail$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversationGetConversationByEmail$Plain(params?: ConversationGetConversationByEmail$Plain$Params, context?: HttpContext): Observable<GetConversationByEmailResponse> {
    return this.conversationGetConversationByEmail$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<GetConversationByEmailResponse>): GetConversationByEmailResponse => r.body))
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversationGetConversationByEmail$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversationGetConversationByEmail$Json$Response(params?: ConversationGetConversationByEmail$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<GetConversationByEmailResponse>> {
    return conversationGetConversationByEmail$Json(this.http, this.rootUrl, params, context)
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversationGetConversationByEmail$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversationGetConversationByEmail$Json(params?: ConversationGetConversationByEmail$Json$Params, context?: HttpContext): Observable<GetConversationByEmailResponse> {
    return this.conversationGetConversationByEmail$Json$Response(params, context).pipe(map((r: StrictHttpResponse<GetConversationByEmailResponse>): GetConversationByEmailResponse => r.body))
  }

  /** Path part for operation `conversationGetConversationByOppositeUser()` */
  static readonly ConversationGetConversationByOppositeUserPath = '/v2/conversations/by-opposite-user'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversationGetConversationByOppositeUser$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversationGetConversationByOppositeUser$Plain$Response(
    params?: ConversationGetConversationByOppositeUser$Plain$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<GetConversationByEmailResponse>> {
    return conversationGetConversationByOppositeUser$Plain(this.http, this.rootUrl, params, context)
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversationGetConversationByOppositeUser$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversationGetConversationByOppositeUser$Plain(params?: ConversationGetConversationByOppositeUser$Plain$Params, context?: HttpContext): Observable<GetConversationByEmailResponse> {
    return this.conversationGetConversationByOppositeUser$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<GetConversationByEmailResponse>): GetConversationByEmailResponse => r.body))
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversationGetConversationByOppositeUser$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversationGetConversationByOppositeUser$Json$Response(
    params?: ConversationGetConversationByOppositeUser$Json$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<GetConversationByEmailResponse>> {
    return conversationGetConversationByOppositeUser$Json(this.http, this.rootUrl, params, context)
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversationGetConversationByOppositeUser$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversationGetConversationByOppositeUser$Json(params?: ConversationGetConversationByOppositeUser$Json$Params, context?: HttpContext): Observable<GetConversationByEmailResponse> {
    return this.conversationGetConversationByOppositeUser$Json$Response(params, context).pipe(map((r: StrictHttpResponse<GetConversationByEmailResponse>): GetConversationByEmailResponse => r.body))
  }

  /** Path part for operation `conversationGetConversation()` */
  static readonly ConversationGetConversationPath = '/v2/conversations'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversationGetConversation$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversationGetConversation$Plain$Response(params?: ConversationGetConversation$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ConversationViewModel>> {
    return conversationGetConversation$Plain(this.http, this.rootUrl, params, context)
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversationGetConversation$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversationGetConversation$Plain(params?: ConversationGetConversation$Plain$Params, context?: HttpContext): Observable<ConversationViewModel> {
    return this.conversationGetConversation$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<ConversationViewModel>): ConversationViewModel => r.body))
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversationGetConversation$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversationGetConversation$Json$Response(params?: ConversationGetConversation$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ConversationViewModel>> {
    return conversationGetConversation$Json(this.http, this.rootUrl, params, context)
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversationGetConversation$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversationGetConversation$Json(params?: ConversationGetConversation$Json$Params, context?: HttpContext): Observable<ConversationViewModel> {
    return this.conversationGetConversation$Json$Response(params, context).pipe(map((r: StrictHttpResponse<ConversationViewModel>): ConversationViewModel => r.body))
  }

  /** Path part for operation `conversationGetConversationCounters()` */
  static readonly ConversationGetConversationCountersPath = '/v2/conversations/counters'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversationGetConversationCounters$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversationGetConversationCounters$Plain$Response(params?: ConversationGetConversationCounters$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ConversationCountersDto>> {
    return conversationGetConversationCounters$Plain(this.http, this.rootUrl, params, context)
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversationGetConversationCounters$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversationGetConversationCounters$Plain(params?: ConversationGetConversationCounters$Plain$Params, context?: HttpContext): Observable<ConversationCountersDto> {
    return this.conversationGetConversationCounters$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<ConversationCountersDto>): ConversationCountersDto => r.body))
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversationGetConversationCounters$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversationGetConversationCounters$Json$Response(params?: ConversationGetConversationCounters$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ConversationCountersDto>> {
    return conversationGetConversationCounters$Json(this.http, this.rootUrl, params, context)
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversationGetConversationCounters$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversationGetConversationCounters$Json(params?: ConversationGetConversationCounters$Json$Params, context?: HttpContext): Observable<ConversationCountersDto> {
    return this.conversationGetConversationCounters$Json$Response(params, context).pipe(map((r: StrictHttpResponse<ConversationCountersDto>): ConversationCountersDto => r.body))
  }
}
