/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http'
import { Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { StrictHttpResponse } from '../../strict-http-response'
import { RequestBuilder } from '../../request-builder'

import { ConversationsFetchType } from '../../models/conversations-fetch-type'
import { ConversationViewModel } from '../../models/conversation-view-model'

export interface ConversationGetConversation$Plain$Params {
  fetchType?: ConversationsFetchType
  conversationId?: string
}

export function conversationGetConversation$Plain(
  http: HttpClient,
  rootUrl: string,
  params?: ConversationGetConversation$Plain$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<ConversationViewModel>> {
  const rb = new RequestBuilder(rootUrl, conversationGetConversation$Plain.PATH, 'get')
  if (params) {
    rb.query('fetchType', params.fetchType, {})
    rb.query('conversationId', params.conversationId, {})
  }

  return http.request(rb.build({ responseType: 'text', accept: 'text/plain', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ConversationViewModel>
    })
  )
}

conversationGetConversation$Plain.PATH = '/v2/conversations'
