import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http'
import { BaseService as __BaseService } from '../base-service'
import { ServiceApiDataAccessConfiguration as __Configuration } from '../service-api-data-access-configuration'
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response'
import { Observable as __Observable } from 'rxjs'
import { filter as __filter, map as __map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
class FeatureAccessService extends __BaseService {
  public static readonly getFeatureAccessPath = '/feature-access'
  public static readonly patchFeatureAccessPath = '/feature-access'
  public static readonly patchFeatureAccessLimitsAccessPath = '/feature-access/limits-access'

  public constructor(config: __Configuration, http: HttpClient) {
    super(config, http)
  }

  /**
   * @param params The `FeatureAccessService.GetFeatureAccessParams` containing the following parameters:
   *
   * - `context`:
   *
   * - `companyId`:
   * @returns Success
   */
  getFeatureAccessResponse(params: FeatureAccessService.GetFeatureAccessParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams()
    const __headers = new HttpHeaders()
    const __body: any = null
    if (params.context != null) {
      __params = __params.set('context', params.context.toString())
    }
    if (params.companyId != null) {
      __params = __params.set('companyId', params.companyId.toString())
    }
    const req = new HttpRequest<any>('GET', this.rootUrl + `/feature-access`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(
        _r =>
          (_r as HttpResponse<any>).clone({
            body: (_r as HttpResponse<any>).body === true
          }) as __StrictHttpResponse<boolean>
      )
    )
  }

  /**
   * @param params The `FeatureAccessService.GetFeatureAccessParams` containing the following parameters:
   *
   * - `context`:
   *
   * - `companyId`:
   * @returns Success
   */
  getFeatureAccess(params: FeatureAccessService.GetFeatureAccessParams): __Observable<boolean> {
    return this.getFeatureAccessResponse(params).pipe(__map(_r => _r.body as boolean))
  }

  /**
   * @param params The `FeatureAccessService.PatchFeatureAccessParams` containing the following parameters:
   *
   * - `isEnable`:
   *
   * - `context`:
   *
   * - `companyId`:
   */
  patchFeatureAccessResponse(params: FeatureAccessService.PatchFeatureAccessParams): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    const __headers = new HttpHeaders()
    const __body: any = null
    if (params.isEnable != null) {
      __params = __params.set('isEnable', params.isEnable.toString())
    }
    if (params.context != null) {
      __params = __params.set('context', params.context.toString())
    }
    if (params.companyId != null) {
      __params = __params.set('companyId', params.companyId.toString())
    }
    const req = new HttpRequest<any>('PATCH', this.rootUrl + `/feature-access`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => _r as __StrictHttpResponse<any>)
    )
  }

  /**
   * @param params The `FeatureAccessService.PatchFeatureAccessParams` containing the following parameters:
   *
   * - `isEnable`:
   *
   * - `context`:
   *
   * - `companyId`:
   */
  patchFeatureAccess(params: FeatureAccessService.PatchFeatureAccessParams): __Observable<any> {
    return this.patchFeatureAccessResponse(params).pipe(__map(_r => _r.body))
  }

  /**
   * @param params The `FeatureAccessService.PatchFeatureAccessLimitsAccessParams` containing the following parameters:
   *
   * - `isEnable`:
   *
   * - `companyId`:
   */
  patchFeatureAccessLimitsAccessResponse(params: FeatureAccessService.PatchFeatureAccessLimitsAccessParams): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    const __headers = new HttpHeaders()
    const __body: any = null
    if (params.isEnable != null) {
      __params = __params.set('isEnable', params.isEnable.toString())
    }
    if (params.companyId != null) {
      __params = __params.set('companyId', params.companyId.toString())
    }
    const req = new HttpRequest<any>('PATCH', this.rootUrl + `/feature-access/limits-access`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => _r as __StrictHttpResponse<any>)
    )
  }

  /**
   * @param params The `FeatureAccessService.PatchFeatureAccessLimitsAccessParams` containing the following parameters:
   *
   * - `isEnable`:
   *
   * - `companyId`:
   */
  patchFeatureAccessLimitsAccess(params: FeatureAccessService.PatchFeatureAccessLimitsAccessParams): __Observable<any> {
    return this.patchFeatureAccessLimitsAccessResponse(params).pipe(__map(_r => _r.body))
  }
}

namespace FeatureAccessService {
  /**
   * Parameters for getFeatureAccess
   */
  export interface GetFeatureAccessParams {
    context?:
      | 'Limits'
      | 'CatalogService'
      | 'Orders'
      | 'Turbota'
      | 'CandidatesScreening'
      | 'FacebookVacancyPublication'
      | 'UsersManaging'
      | 'PublicationInAllCities'
      | 'RisingUp'
      | 'LoyaltyProgram'
      | 'NewVacancyStatistics'
      | 'QesCompanyVerification'
    companyId?: number
  }

  /**
   * Parameters for patchFeatureAccess
   */
  export interface PatchFeatureAccessParams {
    isEnable?: boolean
    context?: 'Limits' | 'CatalogService' | 'Orders' | 'Turbota' | 'CandidatesScreening' | 'FacebookVacancyPublication' | 'UsersManaging' | 'PublicationInAllCities' | 'RisingUp'
    companyId?: number
  }

  /**
   * Parameters for patchFeatureAccessLimitsAccess
   */
  export interface PatchFeatureAccessLimitsAccessParams {
    isEnable?: boolean
    companyId?: number
  }
}

export { FeatureAccessService }
