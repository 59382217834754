import { Injectable } from '@angular/core'
import { GetVacancyPositionAutocompleteGQL } from './vacancy-positions-autocomplete.generated'
import { catchError, map } from 'rxjs/operators'
import { Observable, of } from 'rxjs'

@Injectable()
export class VacancyPositionsAutocompleteService {
  public constructor(private gql: GetVacancyPositionAutocompleteGQL) {}

  public getPositions(keyword: string): Observable<string[]> {
    return this.gql.fetch({ keyword }).pipe(
      map(res => (res.data ? [...res.data.positions] : [])),
      catchError(() => of([]))
    )
  }
}
