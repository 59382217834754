/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { Injectable } from '@angular/core'

/**
 * Global configuration
 */
@Injectable({
  providedIn: 'root'
})
export class NotificationDataAccessConfiguration {
  rootUrl: string = ''
}

/**
 * Parameters for `ChatDataAccessModule.forRoot()`
 */
export interface NotificationDataAccessConfigurationParams {
  rootUrl?: string
}
