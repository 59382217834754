import { FeedbackReporterTypeEnum } from '@alliance/shared/domain-gql'
import { UserService, UserSideEnum } from '@alliance/shared/utils'
import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router'

@Injectable()
export class FeedbackDataFromUrlGuard implements CanActivate {
  public userRole = FeedbackReporterTypeEnum.Seeker
  public cameFromUrl = ''

  private readonly RoleByUserSideMap: Record<UserSideEnum, FeedbackReporterTypeEnum> = {
    [UserSideEnum.employer]: FeedbackReporterTypeEnum.Employer,
    [UserSideEnum.jobseeker]: FeedbackReporterTypeEnum.Seeker,
    [UserSideEnum.all]: FeedbackReporterTypeEnum.Seeker
  }

  public constructor(private userService: UserService) {}

  public canActivate(route: ActivatedRouteSnapshot): boolean {
    this.userRole = this.RoleByUserSideMap[this.userService.userSide ?? UserSideEnum.jobseeker]
    this.cameFromUrl = decodeURIComponent(route.queryParamMap.get('cameFromUrl') ?? '')

    return true
  }
}
