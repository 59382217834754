import { Query } from '@alliance/shared/models'
import { Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { CityDataLocalized, CityRegionDataLocalized, DataLocalized, RubricLocalized } from '@alliance/jobseeker/data-access'
import { TranslationService } from '@alliance/shared/translation'
import { DictionaryStore } from './dictionary.store'
import { DictionaryCityAndRegionsItem, DictionaryCityItemWithRegion, DictionaryModel, DictionaryRubricList, LangCode } from './dictionary.model'
import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class DictionaryMapHelper extends Query<DictionaryModel> {
  public constructor(protected override store: DictionaryStore, private translationService: TranslationService) {
    super(store)
  }

  public arrCityAndRegionsList$<Key extends keyof DictionaryModel>(key: Key, langCode: LangCode | null = null): Observable<DictionaryCityAndRegionsItem[]> {
    return this.select$(key).pipe(
      map(list =>
        (list as CityRegionDataLocalized[]).map(item => ({
          id: item?.id ?? 0,
          centerId: item.centerId ?? 0,
          value: item[(langCode ?? this.translationService.getCurrentLangFix_DEPRECATED()) as keyof DataLocalized] as string,
          ru: item?.ru ?? '',
          ua: item?.ua ?? '',
          en: item?.en ?? '',
          isRegion: item?.isRegion ?? false
        }))
      ),
      filter(arr => arr.length > 0)
    )
  }

  public arrRubricList$<Key extends keyof DictionaryModel>(key: Key, langCode: LangCode | null = null): Observable<DictionaryRubricList> {
    return this.select$(key).pipe(
      map(list =>
        (list as RubricLocalized[]).map(item => ({
          id: item?.id ?? 0,
          value: item[(langCode ?? this.translationService.getCurrentLangFix_DEPRECATED()) as keyof RubricLocalized] as string,
          experienceId: item?.experienceId ?? 0,
          parentId: item?.parentId ?? 0,
          vacCount: item?.vacCount ?? 0,
          ru: item?.ru ?? '',
          ua: item?.ua ?? ''
        }))
      ),
      filter(arr => arr.length > 0)
    )
  }

  public mapCity(item: CityDataLocalized, value: string, inside: boolean): DictionaryCityItemWithRegion {
    return {
      id: item?.id ?? 0,
      value,
      ru: item?.ru ?? '',
      ua: item?.ua ?? '',
      vacCount: item?.vacancyCount ?? 0,
      centerId: item?.centerId ?? 0,
      centerRu: item?.regionName?.ru ?? '',
      centerUa: item?.regionName?.ua ?? '',
      inside,
      locativeRu: item?.locativeName?.ru ?? '',
      locativeUa: item?.locativeName?.ua ?? '',
      countryId: item.countryId
    }
  }
}
