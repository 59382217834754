import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { UsersOpenApiSsoService } from '../../infrastructure'

@Injectable({
  providedIn: 'root'
})
export class UserSsoService {
  public constructor(private readonly usersOpenApiSsoService: UsersOpenApiSsoService) {}

  public getSsoGoogleToken$(tokenId: string): Observable<void> {
    return this.usersOpenApiSsoService.ssoGoogleTokenGet({ id_token: tokenId })
  }
}
