export enum NotificationStreamMessageDetailTypeEnum {
  CompanyStateChanged = 'COMPANY_STATE_CHANGED',
  Custom = 'CUSTOM_NOTIFICATION',
  LimitsChangeRequested = 'LIMITS_CHANGE_REQUESTED',
  OrderActivatedByTurbota = 'ORDER_ACTIVATED_BY_TURBOTA',
  NewApply = 'NEW_APPLY',
  PublicationEnded = 'PUBLICATION_ENDED',
  UnpaidOrderReceived = 'UNPAID_ORDER_RECEIVED',
  VacancyAwaitingApproval = 'VACANCY_AWAITING_APPROVAL',
  VacancyBannedByModerator = 'VACANCY_BANNED_BY_MODERATOR',
  VacancyEndingLeftCount = 'VACANCY_ENDING_LEFT_COUNT',
  VacancyEndingLeftDays = 'VACANCY_ENDING_LEFT_DAYS',
  VacancyPublicationApproved = 'VACANCY_PUBLICATION_APPROVED',
  VacancyPublicationRejected = 'VACANCY_REJECTED'
}

export enum NotificationStreamMessageCompanyStateEnum {
  BlackList = 'BLACK_LIST',
  ExpectationOfActivation = 'EXPECTATION_OF_ACTIVATION',
  Mega = 'MEGA',
  New = 'NEW',
  NotActivated = 'NOT_ACTIVATED',
  Undefined = 'UNDEFINED',
  Validated = 'VALIDATED'
}

interface NotificationStreamMessageVacancyPublishPeriod {
  daysUntilEnd: number | null
  end: Date | string
}

interface NotificationStreamMessageEmployee {
  id: string
  fullName: string
}

interface NotificationStreamMessageVacancy {
  id: string
  title: string
  publishPeriod: NotificationStreamMessageVacancyPublishPeriod | null
}

interface NotificationStreamMessageApply {
  id: string
}

interface NotificationStreamMessageOrder {
  id: string
  orderedAt: Date | string
  totalPrice: number
  turbotaEndsAt?: Date | string | null
}

interface MessageDetailsWithVacancy {
  vacancy: NotificationStreamMessageVacancy | null
}

interface MessageDetailsWithOrder {
  order: NotificationStreamMessageOrder | null
}

interface BaseMessageDetails {
  detailType: NotificationStreamMessageDetailTypeEnum
}

export interface CompanyStateChangedMessageDetails extends BaseMessageDetails {
  detailType: NotificationStreamMessageDetailTypeEnum.CompanyStateChanged
  newState: NotificationStreamMessageCompanyStateEnum
}

export interface CustomMessageDetails extends BaseMessageDetails {
  detailType: NotificationStreamMessageDetailTypeEnum.Custom
}

export interface LimitsChangeRequestedMessageDetails extends BaseMessageDetails {
  detailType: NotificationStreamMessageDetailTypeEnum.LimitsChangeRequested
  employee: NotificationStreamMessageEmployee
}

export interface NewApplyMessageDetails extends BaseMessageDetails, MessageDetailsWithVacancy {
  detailType: NotificationStreamMessageDetailTypeEnum.NewApply
  apply: NotificationStreamMessageApply | null
}

export interface OrderActivatedByTurbotaMessageDetails extends BaseMessageDetails, MessageDetailsWithOrder {
  detailType: NotificationStreamMessageDetailTypeEnum.OrderActivatedByTurbota
}

export interface UnpaidOrderMessageDetails extends BaseMessageDetails, MessageDetailsWithOrder {
  detailType: NotificationStreamMessageDetailTypeEnum.UnpaidOrderReceived
}

export interface VacancyBannedByModeratorMessageDetails extends BaseMessageDetails, MessageDetailsWithVacancy {
  detailType: NotificationStreamMessageDetailTypeEnum.VacancyBannedByModerator
}

export interface VacancyEndingCountMessageDetails extends BaseMessageDetails {
  detailType: NotificationStreamMessageDetailTypeEnum.VacancyEndingLeftCount
  count: number
  daysUntilEnd: number
}

export interface VacancyEndingLeftDaysMessageDetails extends BaseMessageDetails, MessageDetailsWithVacancy {
  detailType: NotificationStreamMessageDetailTypeEnum.VacancyEndingLeftDays
}

export interface VacancyPublicationEndedMessageDetails extends BaseMessageDetails, MessageDetailsWithVacancy {
  detailType: NotificationStreamMessageDetailTypeEnum.PublicationEnded
}

export interface VacancyAwaitingApprovalMessageDetails extends BaseMessageDetails, MessageDetailsWithVacancy {
  detailType: NotificationStreamMessageDetailTypeEnum.VacancyAwaitingApproval
}

export interface VacancyPublicationApprovedMessageDetails extends BaseMessageDetails, MessageDetailsWithVacancy {
  detailType: NotificationStreamMessageDetailTypeEnum.VacancyPublicationApproved
}

export interface VacancyPublicationRejectedMessageDetails extends BaseMessageDetails, MessageDetailsWithVacancy {
  detailType: NotificationStreamMessageDetailTypeEnum.VacancyPublicationRejected
}

export type NotificationStreamMessageDetailsUnion =
  | CompanyStateChangedMessageDetails
  | CustomMessageDetails
  | LimitsChangeRequestedMessageDetails
  | NewApplyMessageDetails
  | OrderActivatedByTurbotaMessageDetails
  | UnpaidOrderMessageDetails
  | VacancyAwaitingApprovalMessageDetails
  | VacancyBannedByModeratorMessageDetails
  | VacancyEndingCountMessageDetails
  | VacancyEndingLeftDaysMessageDetails
  | VacancyPublicationApprovedMessageDetails
  | VacancyPublicationEndedMessageDetails
  | VacancyPublicationRejectedMessageDetails

export interface NotificationStreamMessage {
  id: string
  details: NotificationStreamMessageDetailsUnion | null
}
