import { Environment } from '@alliance/shared/environment'

// take from https://raw.githubusercontent.com/cyclosproject/ng-openapi-gen/master/templates/module.handlebars
// and comment code from constructor and below

import { NgModule, ModuleWithProviders } from '@angular/core'

import { UsersOpenApiModuleConfig, UsersOpenApiModuleConfigParams } from './users-open-api-module-config'

import { UsersOpenApiDiiaService } from './services/users-open-api-diia.service'
import { UsersOpenApiMessengerService } from './services/users-open-api-messenger.service'
import { UsersOpenApiRegistrationConfirmEndpointService } from './services/users-open-api-registration-confirm-endpoint.service'
import { UsersOpenApiSatisfactionSurveysService } from './services/users-open-api-satisfaction-surveys.service'
import { UsersOpenApiSsoService } from './services/users-open-api-sso.service'
import { UsersOpenApiUserCountryService } from './services/users-open-api-user-country.service'

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    UsersOpenApiDiiaService,
    UsersOpenApiMessengerService,
    UsersOpenApiRegistrationConfirmEndpointService,
    UsersOpenApiSatisfactionSurveysService,
    UsersOpenApiSsoService,
    UsersOpenApiUserCountryService,
    {
      provide: UsersOpenApiModuleConfig,
      useFactory: (environment: Environment): UsersOpenApiModuleConfigParams => ({
        rootUrl: environment.usersApi
      }),
      deps: [Environment]
    }
  ]
})
export class UsersOpenApiModule {
  public static forRoot(params: UsersOpenApiModuleConfigParams): ModuleWithProviders<UsersOpenApiModule> {
    return {
      ngModule: UsersOpenApiModule,
      providers: [
        {
          provide: UsersOpenApiModuleConfig,
          useValue: params
        }
      ]
    }
  }

  /*
    constructor(
      @Optional() @SkipSelf() parentModule: UsersOpenApiModule,
      @Optional() http: HttpClient
    ) {

      if (parentModule) {
        throw new Error('UsersOpenApiModule is already loaded. Import in your base AppModule only.');
      }
      if (!http) {
        throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
          'See also https://github.com/angular/angular/issues/20575');
      }

    } */
}
