import { DictionaryService, LangCode } from '@alliance/jobseeker/api'
import { ALL_UKRAINE_ID, OTHER_COUNTRIES_ID } from '@alliance/shared/constants'
import { LanguageCodesEnum, TranslationService } from '@alliance/shared/translation'
import { GetPrepositionByWordFirstLetters } from '@alliance/shared/utils'
import { Injectable } from '@angular/core'
import { combineLatest, Observable, of } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import { BreadcrumbList } from 'schema-dts'
import { SeoCandidatesModel } from '../../openapi/model/candidates.model'
import { HelpersService } from '../helpers.service'
import { Translations } from '../localization/translations'
import { CandidatesSeoParams, PartialSeoParamsResponse, PlatformHosts } from '../models'

const DICTIONARY_LANG_CODE_MAP: Record<LanguageCodesEnum, LangCode> = {
  [LanguageCodesEnum.UK]: 'ua',
  [LanguageCodesEnum.RU]: 'ru'
}

type SeoParams = SeoCandidatesModel & { isAllowedForIndexing: boolean }

@Injectable({
  providedIn: 'root'
})
export class CandidatesDictionaryService {
  private readonly dictionaryLangCode = DICTIONARY_LANG_CODE_MAP[this.translationService.getCurrentLang()]
  private readonly isUkrainian = this.translationService.currentLangIsUkrainian()

  public constructor(private translations: Translations, private translationService: TranslationService, private dictionaryService: DictionaryService, private helpersService: HelpersService) {}

  public getParams(params: CandidatesSeoParams): Observable<PartialSeoParamsResponse> {
    return this.getSeoParams$(params).pipe(
      switchMap(seoParams =>
        combineLatest([this.getCommonSeoParams$(seoParams), this.getIndexingSeoParams$(seoParams), this.getJsonLd$(seoParams)]).pipe(
          map(([commonSeoParams, indexingSeoParams, jsonLd]) => ({
            ...commonSeoParams,
            ...indexingSeoParams,
            ...(commonSeoParams.title && { title: this.prependPageNumberText(seoParams.page, commonSeoParams.title) }),
            ...(commonSeoParams.description && { description: this.prependPageNumberText(seoParams.page, commonSeoParams.description) }),
            jsonLd: { desktop: jsonLd, mobile: jsonLd }
          }))
        )
      )
    )
  }

  private getSeoParams$({ params }: CandidatesSeoParams): Observable<SeoParams> {
    const { total, isNarrowedSearch, keyWords } = params
    const seoParams: SeoParams = { ...params, isAllowedForIndexing: !!total && !isNarrowedSearch }

    if (!keyWords) {
      return of(seoParams)
    }

    return this.dictionaryService.getProfessionsList$(this.dictionaryLangCode).pipe(
      map(keywords => {
        const foundedKeyword = keywords.find(keyword => keyword?.name?.toLowerCase() === params.keyWords?.trim()?.toLowerCase())?.name
        return { ...seoParams, ...(foundedKeyword ? { keyWords: foundedKeyword } : { isAllowedForIndexing: false }) }
      })
    )
  }

  private getCommonSeoParams$(seoParams: SeoParams): Observable<PartialSeoParamsResponse> {
    const { cityId } = seoParams

    if (cityId === ALL_UKRAINE_ID) {
      return this.getUkraineCommonSeoParams$(seoParams)
    }

    if (cityId === OTHER_COUNTRIES_ID) {
      return this.getOtherCountriesCommonSeoParams$(seoParams)
    }

    return this.getCityAndKeywordCommonSeoParams$(seoParams)
  }

  private getUkraineCommonSeoParams$({ keyWords: keyword }: SeoParams): Observable<PartialSeoParamsResponse> {
    if (keyword) {
      return of({
        title: this.translationService.translate(this.translations.candidates.ukraineAndKeyword.title, { keyword }),
        description: this.translationService.translate(this.translations.candidates.ukraineAndKeyword.description)
      })
    }

    return of({
      title: this.translationService.translate(this.translations.candidates.ukraine.title),
      description: this.translationService.translate(this.translations.candidates.ukraine.description)
    })
  }

  private getOtherCountriesCommonSeoParams$({ keyWords: keyword }: SeoParams): Observable<PartialSeoParamsResponse> {
    if (keyword) {
      return of({
        title: this.translationService.translate(this.translations.candidates.otherCountriesAndKeyword.title, { keyword }),
        description: this.translationService.translate(this.translations.candidates.otherCountriesAndKeyword.description)
      })
    }

    return of({
      title: this.translationService.translate(this.translations.candidates.otherCountries.title),
      description: this.translationService.translate(this.translations.candidates.otherCountries.description)
    })
  }

  private getCityAndKeywordCommonSeoParams$({ cityId, keyWords: keyword }: SeoParams): Observable<PartialSeoParamsResponse> {
    return this.dictionaryService.getCityName$(cityId).pipe(
      switchMap(cityName => {
        if (keyword) {
          return this.dictionaryService.getCityName$(cityId, null, true).pipe(
            map(locativeCityName => `${GetPrepositionByWordFirstLetters(locativeCityName, this.isUkrainian)} ${locativeCityName}`),
            map(locativeCityName => ({
              title: this.translationService.translate(this.translations.candidates.cityAndKeyword.title, { cityName: locativeCityName, keyword }),
              description: this.translationService.translate(this.translations.candidates.cityAndKeyword.description, { cityName })
            }))
          )
        }

        return of({
          title: this.translationService.translate(this.translations.candidates.city.title, { cityName }),
          description: this.translationService.translate(this.translations.candidates.city.description, { cityName })
        })
      })
    )
  }

  private getIndexingSeoParams$({ cityId, keyWords, page, isAllowedForIndexing }: SeoParams): Observable<PartialSeoParamsResponse> {
    if (!isAllowedForIndexing) {
      return of({ noIndexNoFollow: true })
    }

    return combineLatest([this.helpersService.createDesktopCandidatesListUrl$(cityId, keyWords, page), this.helpersService.createCandidatesListPathName$(cityId, keyWords, page)]).pipe(
      map(([url, pathName]) => ({
        canonicalUrl: url,
        alternateUrl: url,
        hrefLang: this.helpersService.createHrefLangURLs(pathName, pathName)
      }))
    )
  }

  private getJsonLd$({ cityId, keyWords }: SeoParams): Observable<string> {
    const platform = PlatformHosts.desktop

    const cityName$ = cityId === ALL_UKRAINE_ID ? of(this.translationService.translate(this.translations.jsonLd.breadcrumbs.allUkraine)) : this.dictionaryService.getCityName$(cityId)
    const url$ = this.helpersService.createDesktopCandidatesListUrl$(cityId, keyWords)

    return combineLatest([cityName$, url$]).pipe(
      map(([cityName, url]) =>
        this.helpersService.createJsonLd<BreadcrumbList>(platform, {
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: [
            this.helpersService.getHomePageBreadcrumb(platform),
            this.helpersService.getCandidatesBreadcrumb(platform),
            {
              '@type': 'ListItem',
              position: 3,
              name: cityName,
              item: url
            }
          ]
        })
      )
    )
  }

  private prependPageNumberText(page: number, text: string): string {
    return page > 1 ? `${text} ${this.translationService.translate(this.translations.candidates.pageLeadingText, { page })}` : text
  }
}
