/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core'
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http'
import { BaseService } from '../base-service'
import { UsersOpenApiModuleConfig } from '../users-open-api-module-config'
import { StrictHttpResponse } from '../strict-http-response'
import { RequestBuilder } from '../request-builder'
import { Observable } from 'rxjs'
import { map, filter } from 'rxjs/operators'

import { SatisfactionSurveyModel } from '../models/satisfaction-survey-model'

@Injectable({
  providedIn: 'root'
})
export class UsersOpenApiSatisfactionSurveysService extends BaseService {
  constructor(config: UsersOpenApiModuleConfig, http: HttpClient) {
    super(config, http)
  }

  /**
   * Path part for operation satisfactionSurveyGet
   */
  static readonly SatisfactionSurveyGetPath = '/satisfaction-survey'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `satisfactionSurveyGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  satisfactionSurveyGet$Response(params?: { context?: HttpContext }): Observable<StrictHttpResponse<SatisfactionSurveyModel>> {
    const rb = new RequestBuilder(this.rootUrl, UsersOpenApiSatisfactionSurveysService.SatisfactionSurveyGetPath, 'get')
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
          context: params?.context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<SatisfactionSurveyModel>
        })
      )
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `satisfactionSurveyGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  satisfactionSurveyGet(params?: { context?: HttpContext }): Observable<SatisfactionSurveyModel> {
    return this.satisfactionSurveyGet$Response(params).pipe(map((r: StrictHttpResponse<SatisfactionSurveyModel>) => r.body as SatisfactionSurveyModel))
  }

  /**
   * Path part for operation satisfactionSurveyPost
   */
  static readonly SatisfactionSurveyPostPath = '/satisfaction-survey'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `satisfactionSurveyPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  satisfactionSurveyPost$Response(params?: { context?: HttpContext; body?: SatisfactionSurveyModel }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, UsersOpenApiSatisfactionSurveysService.SatisfactionSurveyPostPath, 'post')
    if (params) {
      rb.body(params.body, 'application/json')
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>
        })
      )
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `satisfactionSurveyPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  satisfactionSurveyPost(params?: { context?: HttpContext; body?: SatisfactionSurveyModel }): Observable<void> {
    return this.satisfactionSurveyPost$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void))
  }
}
