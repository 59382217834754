import { Injectable } from '@angular/core'

import { LocalStorage } from '@alliance/shared/storage'

const CVDB_FILTERS_ONLY_VETERANS_FEATURE = 'cvdbFiltersOnlyVeteransFeature'

@Injectable({
  providedIn: 'root'
})
export class CvdbFiltersFeatureService {
  public constructor(private readonly storageService: LocalStorage) {}

  public get isOnlyVeteransFeatureEnabled(): boolean {
    return !!this.storageService.getItem(CVDB_FILTERS_ONLY_VETERANS_FEATURE)
  }
}
