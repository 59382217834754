/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum ResumeSource {
  None = 'None',
  AttachedFile = 'AttachedFile',
  Notepad = 'Notepad',
  Selected = 'Selected',
  NoCvApply = 'NoCvApply'
}
