import { DataLocalized } from '@alliance/jobseeker/data-access'
import {
  DictionaryCityAndRegionsItem,
  DictionaryCityListWithCountry,
  DictionaryCityListWithRegions,
  DictionaryItem,
  DictionaryList,
  DictionaryMap,
  DictionaryPopularTag,
  DictionaryProfessionList,
  DictionaryQuery,
  DictionaryRubricList,
  DictionaryService as DictionaryDomainService,
  LangCode
} from '@alliance/jobseeker/domain'
import { Injectable } from '@angular/core'
import { isValidKey } from '@alliance/shared/utils'
import { Observable } from 'rxjs'
import { first, map } from 'rxjs/operators'
import { DistrictDictionaryApiService } from '@alliance/shared/dictionary'
import { transformDictionaryList } from '../mappers/transform-dictionary-list'
import { transformLangValue } from '../mappers/transform-active-lang'

@Injectable({
  providedIn: 'root'
})
export class DictionaryService {
  public constructor(private queryService: DictionaryQuery, private dictionaryService: DictionaryDomainService, private districtDictionaryApiService: DistrictDictionaryApiService) {}

  public getRubricMap$(langCode: LangCode | null = null): Observable<DictionaryMap> {
    this.dictionaryService.syncRubricList()
    return this.queryService.selectRubricMap$(langCode).pipe(first())
  }

  public getProfessionsList$(langCode: LangCode = 'ru'): Observable<DictionaryProfessionList> {
    this.dictionaryService.syncProfessionsList(langCode)
    return this.queryService.selectProfessionsList$().pipe(first())
  }

  public getRubricList$(langCode: LangCode | null = null): Observable<DictionaryRubricList> {
    this.dictionaryService.syncRubricList()
    return this.queryService.selectRubricList$(langCode).pipe(first())
  }

  public getRubricName$(id: number, langCode: LangCode | null = null): Observable<string> {
    return this.getRubricMap$(langCode).pipe(map(data => (data[id] ? data[id] : '')))
  }

  public getSubRubricMap$(langCode: LangCode | null = null): Observable<DictionaryMap> {
    this.dictionaryService.syncSubRubricList()
    return this.queryService.selectSubRubricMap$(langCode).pipe(first())
  }

  public getSubRubricList$(langCode: LangCode | null = null): Observable<DictionaryRubricList> {
    this.dictionaryService.syncSubRubricList()
    return this.queryService.selectSubRubricList$(langCode).pipe(first())
  }

  public getCityMap$(skipAll = false, langCode: LangCode | null = null, locativeFormat = false): Observable<{ [p: number]: string }> {
    this.dictionaryService.syncCityList()
    return (locativeFormat ? this.queryService.selectCityLocativeMap$(langCode) : this.queryService.selectCityMap$(langCode)).pipe(
      first(),
      map(data => {
        const result = { ...data }
        if (skipAll) {
          delete result[0]
        }
        return result
      })
    )
  }

  public getCityName$(id: number, langCode: LangCode | null = null, locativeFormat = false): Observable<string> {
    return this.getCityMap$(false, langCode, locativeFormat).pipe(map(data => (data[id] ? data[id] : '')))
  }

  public getCitiesAndRegionsMap$(skipAll = false, langCode: LangCode | null = null, locativeFormat = false): Observable<{ [p: number]: string }> {
    this.dictionaryService.syncCityListAndRegions()
    return (locativeFormat ? this.queryService.selectCitiesAndRegionsLocativeMap$(langCode) : this.queryService.selectCityAndRegionMap$(langCode)).pipe(
      first(),
      map(data => {
        const result = { ...data }
        if (skipAll) {
          delete result[0]
        }
        return result
      })
    )
  }

  public getCitiesAndRegionsName$(id: number, langCode: LangCode | null = null, locativeFormat = false): Observable<string> {
    return this.getCitiesAndRegionsMap$(false, langCode, locativeFormat).pipe(map(data => (data[id] ? data[id] : '')))
  }

  public getCityList$(skipAll = false, langCode: LangCode | null = null): Observable<DictionaryList> {
    this.dictionaryService.syncCityList()
    return this.queryService.selectCityList$(langCode).pipe(
      first(),
      map(data => {
        const result = [...data]
        if (skipAll) {
          result.shift()
        }
        return result
      })
    )
  }

  public getCityListWithRegions$(langCode: LangCode | null = null): Observable<DictionaryCityListWithRegions> {
    this.dictionaryService.syncCityList()
    return this.queryService.selectCityListWithRegions$(langCode).pipe(first())
  }

  public getCityListAndRegions$(): Observable<DictionaryCityAndRegionsItem[]> {
    this.dictionaryService.syncCityListAndRegions()
    return this.queryService.selectCitiesAndRegions$()
  }

  public getCityListWithCountry$(): Observable<DictionaryCityListWithCountry[]> {
    this.dictionaryService.syncCityListWithCountry()
    return this.queryService.selectCityListWithCountry$().pipe(first())
  }

  public getLanguageMap$(langCode: LangCode | null = null): Observable<DictionaryMap> {
    this.dictionaryService.syncLanguageList()
    return this.queryService.selectLanguageMap$(langCode).pipe(first())
  }

  public getLanguageName$(id: number): Observable<string> {
    return this.getLanguageMap$().pipe(map(data => (data[id] ? data[id] : '')))
  }

  public getLanguageList$(langCode: LangCode | null = null): Observable<DictionaryList> {
    this.dictionaryService.syncLanguageList()
    return this.queryService.selectLanguageList$(langCode).pipe(first())
  }

  public getLanguageSkillMap$(langCode: LangCode | null = null): Observable<DictionaryMap> {
    this.dictionaryService.syncLanguageSkillList()
    return this.queryService.selectLanguageSkillMap$(langCode).pipe(first())
  }

  public getLanguageSkillName$(id: number, langCode: LangCode | null = null): Observable<string> {
    return this.getLanguageSkillMap$(langCode).pipe(map(data => (data[id] ? data[id] : '')))
  }

  public getLanguageSkillList$(langCode: LangCode | null = null): Observable<DictionaryList> {
    this.dictionaryService.syncLanguageSkillList()
    return this.queryService.selectLanguageSkillList$(langCode).pipe(first())
  }

  public getScheduleMap$(langCode: LangCode | null = null): Observable<DictionaryMap> {
    this.dictionaryService.syncScheduleList()
    return this.queryService.selectScheduleMap$(langCode).pipe(first())
  }

  public getScheduleList$(langCode: LangCode | null = null): Observable<DictionaryList> {
    this.dictionaryService.syncScheduleList()
    return this.queryService.selectScheduleList$(langCode).pipe(first())
  }

  public getScheduleName$(id: number, langCode: LangCode | null = null): Observable<string> {
    return this.getScheduleMap$(langCode).pipe(map(data => (data[id] ? data[id] : '')))
  }

  public getBranchMap$(langCode: LangCode | null = null): Observable<DictionaryMap> {
    this.dictionaryService.syncBranchList()
    return this.queryService.selectBranchMap$(langCode).pipe(first())
  }

  public getBranchName$(id: number, langCode: LangCode | null = null): Observable<string> {
    return this.getBranchMap$(langCode).pipe(map(data => (data[id] ? data[id] : '')))
  }

  public getBranchList$(langCode: LangCode | null = null): Observable<DictionaryItem[]> {
    this.dictionaryService.syncBranchList()
    return this.queryService.selectBranchList$(langCode).pipe(first())
  }

  public getExperienceMap$(langCode: LangCode | null = null): Observable<DictionaryMap> {
    this.dictionaryService.syncExperienceList()
    return this.queryService.selectExperienceMap$(langCode).pipe(first())
  }

  public getExperienceName$(id: number, langCode: LangCode | null = null): Observable<string> {
    return this.getExperienceMap$(langCode).pipe(map(data => (data[id] ? data[id] : '')))
  }

  public getExperienceList$(langCode: LangCode | null = null): Observable<DictionaryItem[]> {
    this.dictionaryService.syncExperienceList()
    return this.queryService.selectExperienceList$(langCode).pipe(first())
  }

  public getCvdbExperienceMap$(langCode: LangCode | null = null): Observable<DictionaryMap> {
    this.dictionaryService.syncCvdbExperienceList()
    return this.queryService.selectCvdbExperienceMap$(langCode).pipe(first())
  }

  public getCvdbExperienceName$(id: number, langCode: LangCode | null = null): Observable<string> {
    return this.getCvdbExperienceMap$(langCode).pipe(map(data => (data[id] ? data[id] : '')))
  }

  public getCvdbExperienceList$(langCode: LangCode | null = null): Observable<DictionaryItem[]> {
    this.dictionaryService.syncCvdbExperienceList()
    return this.queryService.selectCvdbExperienceList$(langCode).pipe(first())
  }

  public getEducationList$(langCode: LangCode | null = null): Observable<DictionaryItem[]> {
    this.dictionaryService.syncEducationList()
    return this.queryService.selectEducationList$(langCode).pipe(first())
  }

  public getEducationMap$(langCode: LangCode | null = null): Observable<DictionaryMap> {
    this.dictionaryService.syncEducationList()
    return this.queryService.selectEducationMap$(langCode).pipe(first())
  }

  public getFillingTypesList$(langCode: LangCode | null = null): Observable<DictionaryItem[]> {
    this.dictionaryService.syncFillingTypesList()
    return this.queryService.selectFillingTypesList$(langCode).pipe(first())
  }

  public getFillingTypesMap$(langCode: LangCode | null = null): Observable<DictionaryMap> {
    this.dictionaryService.syncFillingTypesList()
    return this.queryService.selectFillingTypesMap$(langCode).pipe(first())
  }

  public getFillingTypesName$(id: string, langCode: LangCode | null = null): Observable<string> {
    return this.getFillingTypesMap$(langCode).pipe(map(data => (isValidKey(id, data) ? data[id] : '')))
  }

  public getAdditionalList$(langCode: LangCode | null = null): Observable<DictionaryItem[]> {
    this.dictionaryService.syncAdditionalList()
    return this.queryService.selectAdditionalList$(langCode).pipe(first())
  }

  public getSphereList$(_ = false, langCode: LangCode | null = null): Observable<DictionaryItem[]> {
    this.dictionaryService.syncSphereList()
    return this.queryService.selectSphereList$(langCode).pipe(
      first(),
      map(list => {
        const result = [...list]
        result.shift()
        return result
      })
    )
  }

  public getDistrictsList$(cityId: number, langCode: LangCode | null = null): Observable<DictionaryItem[]> {
    return this.districtDictionaryApiService.getDistrictListDictionary$(`${cityId}`, transformLangValue(langCode)).pipe(
      map(list => transformDictionaryList(list)),
      first()
    )
  }

  public getDistrictsMap$(cityId: number, langCode: LangCode | null = null): Observable<DictionaryMap> {
    return this.getDistrictsList$(cityId, langCode).pipe(map(list => this.queryService.selectDistrictMap(list)))
  }

  public getKeywordsList$(): Observable<string[]> {
    this.dictionaryService.syncKeywordsList()
    return this.queryService.selectKeywordsList$().pipe(first())
  }

  public getPopularKeywordsList$(): Observable<DictionaryPopularTag[]> {
    this.dictionaryService.syncPopularKeywordsList()
    return this.queryService.selectPopularKeywordsList$().pipe(first())
  }

  public getStatusApplicationExperience(): Observable<DataLocalized[]> {
    this.dictionaryService.syncStatusApplicationExperience()
    return this.queryService.selectStatusApplicationExperience().pipe(first())
  }

  public getSalaryStatus(): Observable<DataLocalized[]> {
    this.dictionaryService.syncStatusApplicationSalary()
    return this.queryService.selectStatusApplicationSalary()
  }
}
