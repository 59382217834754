/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http'
import { Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { StrictHttpResponse } from '../../strict-http-response'
import { RequestBuilder } from '../../request-builder'

import { JobInput } from '../../models/job-input'
import { JobOutput } from '../../models/job-output'

export interface GenerateDescriptionGenerateDescriptionPost_1$Params {
  body: JobInput
}

export function generateDescriptionGenerateDescriptionPost_1(
  http: HttpClient,
  rootUrl: string,
  params: GenerateDescriptionGenerateDescriptionPost_1$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<JobOutput>> {
  const rb = new RequestBuilder(rootUrl, generateDescriptionGenerateDescriptionPost_1.PATH, 'post')
  if (params) {
    rb.body(params.body, 'application/json')
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<JobOutput>
    })
  )
}

generateDescriptionGenerateDescriptionPost_1.PATH = '/generate-description'
