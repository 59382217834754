import { VacancyAddressesSuggestInput, VacancyCreateInput } from '@alliance/shared/domain-gql'
import { LocationService } from '@alliance/jobseeker/api'
import { UserInfoService } from '@alliance/employer/domain'
import { RxStateService } from '@alliance/shared/models'
import { getNonNullableItems } from '@alliance/shared/utils'
import { Injectable } from '@angular/core'
import { combineLatest, iif, Observable, of } from 'rxjs'
import { catchError, filter, map, shareReplay, switchMap, take } from 'rxjs/operators'
import { VacancyAddressesSuggestionsGQL, VacancySuggestionsFragment, VacancySuggestionsGQL } from './vacancy-suggestions.generated'
import { VacancyCommonSuggestionType } from '../../models/vacancy-common-suggestion-type'

@Injectable()
export class VacancySuggestionsService extends RxStateService<{
  suggestions: VacancySuggestionsFragment | null
  suggestionAddress: string | null
}> {
  public constructor(
    private vacancyAddressesSuggestionsGQL: VacancyAddressesSuggestionsGQL,
    private vacancySuggestionsGQL: VacancySuggestionsGQL,
    private locationService: LocationService,
    private userStateService: UserInfoService
  ) {
    super()

    this.initState({
      suggestions: this.getVacancySuggestion().pipe(shareReplay(1)),
      suggestionAddress: this.select('suggestions').pipe(
        switchMap(suggestions =>
          suggestions?.cityId
            ? this.getVacancyAddressesSuggestions({ cityId: suggestions.cityId, count: 10 }).pipe(
                take(1),
                map(address => address[0] ?? null)
              )
            : of(null)
        )
      )
    })
  }

  public getPreviousSuggestByKey(key: keyof VacancyCommonSuggestionType): Observable<string> {
    return this.select('suggestions').pipe(
      filter((suggestions): suggestions is VacancySuggestionsFragment => !!suggestions),
      map(v => v[key]),
      map(suggestion => suggestion?.previous ?? '')
    )
  }

  public getListSuggestByKey(key: keyof VacancyCommonSuggestionType): Observable<string[]> {
    return this.select('suggestions').pipe(
      filter((suggestions): suggestions is VacancySuggestionsFragment => !!suggestions),
      map(v => v[key]),
      map(suggestion => getNonNullableItems<string>(suggestion?.list ?? []))
    )
  }

  public getTitleSuggestions(): Observable<string[]> {
    return this.select('suggestions').pipe(
      filter((suggestions): suggestions is VacancySuggestionsFragment => !!suggestions),
      map(v => v.title),
      map(list => getNonNullableItems<string>(list ?? []))
    )
  }

  public getPreviousCityId(): Observable<string | null> {
    return this.select('suggestions').pipe(
      filter((suggestions): suggestions is VacancySuggestionsFragment => !!suggestions),
      map(v => v.cityId),
      switchMap(cityId =>
        iif(
          () => typeof cityId === 'undefined' || cityId === null,
          this.locationService.locationGetCityIdByIp().pipe(
            map(id => (id ? id.toString() : null)),
            catchError(() => of(null))
          ),
          of(cityId ?? null)
        )
      )
    )
  }

  public getPrefilledFieldsForCreate(): Observable<Partial<VacancyCreateInput>> {
    return this.userStateService.getUserInfo$().pipe(
      map(user => !!user?.isAgency),
      take(1),
      switchMap(isAgency =>
        combineLatest([this.select('suggestions'), this.select('suggestionAddress')]).pipe(
          map(([suggestions, suggestionAddress]) => ({
            contacts: {
              name: suggestions?.contactName?.previous || null,
              phones: suggestions?.contactPhones?.previous ? [suggestions.contactPhones.previous] : null,
              socials: suggestions?.contactSocials?.previous ? [suggestions?.contactSocials.previous] : null,
              photo: suggestions?.contactPhoto ?? null
            },
            ...(!isAgency && {
              address: { name: suggestionAddress },
              benefits: suggestions?.benefits?.length ? getNonNullableItems<string>(suggestions.benefits) : null
            })
          }))
        )
      )
    )
  }

  public getVacancyAddressesSuggestions(input: VacancyAddressesSuggestInput): Observable<string[]> {
    return this.vacancyAddressesSuggestionsGQL.fetch({ input }, { fetchPolicy: 'network-only' }).pipe(
      map(data => [...(data.data?.vacancyAddressesSuggest?.addresses ?? [])]),
      catchError(() => of([]))
    )
  }

  private getVacancySuggestion(): Observable<VacancySuggestionsFragment | null> {
    const defaultValue = this.get()?.suggestions || null
    return this.vacancySuggestionsGQL.watch().valueChanges.pipe(
      map(({ data }) => data?.vacancyCreateSuggest ?? defaultValue),
      catchError(() => of(defaultValue))
    )
  }
}
