/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http'
import { Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { StrictHttpResponse } from '../../strict-http-response'
import { RequestBuilder } from '../../request-builder'

import { SuggestResponse } from '../../models/suggest-response'

export interface SuggestEndpointSuggestGet_1$Params {
  profession_tag: number
  skill_tags?: string
  'accept-language'?: string
}

export function suggestEndpointSuggestGet_1(http: HttpClient, rootUrl: string, params: SuggestEndpointSuggestGet_1$Params, context?: HttpContext): Observable<StrictHttpResponse<SuggestResponse>> {
  const rb = new RequestBuilder(rootUrl, suggestEndpointSuggestGet_1.PATH, 'get')
  if (params) {
    rb.query('profession_tag', params.profession_tag, {})
    rb.query('skill_tags', params.skill_tags, {})
    rb.header('accept-language', params['accept-language'], {})
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<SuggestResponse>
    })
  )
}

suggestEndpointSuggestGet_1.PATH = '/suggest'
