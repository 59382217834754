import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> }
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** Scalar representing a set of fields. */
  FieldSet: string
  /** The _Any scalar is used to pass representations of entities from external services into the root _entities field for execution. Validation of the _Any scalar is done by matching the __typename and @external fields defined in the schema. */
  _Any: unknown
}

/** Defines when a policy shall be executed. */
export enum ApplyPolicy {
  /** After the resolver was executed. */
  AfterResolver = 'AFTER_RESOLVER',
  /** Before the resolver was executed. */
  BeforeResolver = 'BEFORE_RESOLVER',
  /** The policy is applied in the validation step before the execution. */
  Validation = 'VALIDATION'
}

export interface Branch {
  readonly __typename?: 'Branch'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
}

/** A connection to a list of items. */
export interface CitiesConnection {
  readonly __typename?: 'CitiesConnection'
  /** A list of edges. */
  readonly edges: Maybe<ReadonlyArray<CityEdge>>
  /** A flattened list of the nodes. */
  readonly nodes: Maybe<ReadonlyArray<City>>
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo
  /** Identifies the total count of items in the connection. */
  readonly totalCount: Scalars['Int']
}

export interface CitiesConnectionInput {
  readonly keyword: InputMaybe<Scalars['String']>
}

export interface City {
  readonly __typename?: 'City'
  readonly districts: ReadonlyArray<CityDistrict>
  readonly en: Maybe<Scalars['String']>
  readonly id: Scalars['ID']
  readonly location: Maybe<Location>
  readonly metroLines: ReadonlyArray<MetroLine>
  readonly microDistricts: ReadonlyArray<MicroDistrict>
  readonly name: Scalars['String']
  readonly regionName: Maybe<Scalars['String']>
  readonly ru: Maybe<Scalars['String']>
  readonly ua: Maybe<Scalars['String']>
}

export interface CityDistrict {
  readonly __typename?: 'CityDistrict'
  readonly city: City
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
  readonly slug: Scalars['String']
  readonly translations: ReadonlyArray<Translation>
}

export interface CityDistrictTranslationsArgs {
  languages: InputMaybe<ReadonlyArray<TranslationLanguageEnum>>
}

/** An edge in a connection. */
export interface CityEdge {
  readonly __typename?: 'CityEdge'
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']
  /** The item at the end of the edge. */
  readonly node: City
}

export interface CompanyStaffSize {
  readonly __typename?: 'CompanyStaffSize'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
}

export interface EducationLevel {
  readonly __typename?: 'EducationLevel'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
}

export interface Experience {
  readonly __typename?: 'Experience'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
}

export type ExternalCourse = PrometheusExternalCourse

export interface Lang {
  readonly __typename?: 'Lang'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
}

export interface LanguageSkillLevel {
  readonly __typename?: 'LanguageSkillLevel'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
}

export interface LocalizedItem {
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
  readonly slug: Scalars['String']
  readonly translations: ReadonlyArray<Translation>
}

export interface LocalizedItemTranslationsArgs {
  languages: InputMaybe<ReadonlyArray<TranslationLanguageEnum>>
}

export interface Location {
  readonly __typename?: 'Location'
  readonly latitude: Scalars['Float']
  readonly longitude: Scalars['Float']
}

export interface Metro {
  readonly __typename?: 'Metro'
  readonly city: City
  readonly id: Scalars['ID']
  readonly line: MetroLine
  readonly name: Scalars['String']
  readonly slug: Scalars['String']
  readonly translations: ReadonlyArray<Translation>
}

export interface MetroTranslationsArgs {
  languages: InputMaybe<ReadonlyArray<TranslationLanguageEnum>>
}

export interface MetroLine {
  readonly __typename?: 'MetroLine'
  readonly city: City
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
  readonly slug: Scalars['String']
  readonly stations: ReadonlyArray<Metro>
  readonly translations: ReadonlyArray<Translation>
}

export interface MetroLineTranslationsArgs {
  languages: InputMaybe<ReadonlyArray<TranslationLanguageEnum>>
}

export interface MicroDistrict extends LocalizedItem {
  readonly __typename?: 'MicroDistrict'
  readonly city: City
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
  readonly slug: Scalars['String']
  readonly synonyms: ReadonlyArray<Scalars['String']>
  readonly translations: ReadonlyArray<Translation>
}

export interface MicroDistrictTranslationsArgs {
  languages: InputMaybe<ReadonlyArray<TranslationLanguageEnum>>
}

/** Information about pagination in a connection. */
export interface PageInfo {
  readonly __typename?: 'PageInfo'
  /** When paginating forwards, the cursor to continue. */
  readonly endCursor: Maybe<Scalars['String']>
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  readonly hasNextPage: Scalars['Boolean']
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  readonly hasPreviousPage: Scalars['Boolean']
  /** When paginating backwards, the cursor to continue. */
  readonly startCursor: Maybe<Scalars['String']>
}

export interface PrometheusExternalCourse {
  readonly __typename?: 'PrometheusExternalCourse'
  readonly id: Scalars['ID']
  readonly url: Scalars['String']
}

export interface Query {
  readonly __typename?: 'Query'
  readonly _entities: ReadonlyArray<Maybe<_Entity>>
  readonly _service: _Service
  readonly branch: Maybe<Branch>
  readonly branches: ReadonlyArray<Branch>
  readonly cities: ReadonlyArray<City>
  readonly citiesConnection: Maybe<CitiesConnection>
  readonly city: Maybe<City>
  readonly cityDistrict: CityDistrict
  readonly cityDistricts: ReadonlyArray<CityDistrict>
  readonly cityMetro: ReadonlyArray<Metro>
  readonly companyStaffSize: CompanyStaffSize
  readonly companyStaffSizes: ReadonlyArray<CompanyStaffSize>
  readonly educationLevel: Maybe<EducationLevel>
  readonly educationLevels: ReadonlyArray<EducationLevel>
  readonly experience: Maybe<Experience>
  readonly experiences: ReadonlyArray<Experience>
  readonly language: Maybe<Lang>
  readonly languageSkillLevel: Maybe<LanguageSkillLevel>
  readonly languageSkillLevels: ReadonlyArray<LanguageSkillLevel>
  readonly languages: ReadonlyArray<Lang>
  readonly metro: Metro
  readonly microDistrict: Maybe<MicroDistrict>
  readonly positions: ReadonlyArray<Scalars['String']>
  readonly resumeFillingType: ResumeFillingType
  readonly resumeFillingTypes: ReadonlyArray<ResumeFillingType>
  readonly rubric: Maybe<Rubric>
  readonly rubrics: ReadonlyArray<Rubric>
  readonly schedule: Maybe<Schedule>
  readonly schedules: ReadonlyArray<Schedule>
  readonly searchKeywords: Maybe<SearchKeywordConnection>
  readonly stopWords: ReadonlyArray<Scalars['String']>
  readonly subrubric: Maybe<Subrubric>
  readonly subrubrics: ReadonlyArray<Subrubric>
  readonly vacancyBenefit: VacancyDictionaryBenefit
  readonly vacancyBenefits: ReadonlyArray<VacancyDictionaryBenefit>
}

export interface Query_EntitiesArgs {
  representations: ReadonlyArray<Scalars['_Any']>
}

export interface QueryBranchArgs {
  id: Scalars['ID']
}

export interface QueryCitiesConnectionArgs {
  after: InputMaybe<Scalars['String']>
  first: InputMaybe<Scalars['Int']>
  input: InputMaybe<CitiesConnectionInput>
}

export interface QueryCityArgs {
  id: Scalars['ID']
}

export interface QueryCityDistrictArgs {
  id: Scalars['ID']
}

export interface QueryCityDistrictsArgs {
  cityId: Scalars['ID']
}

export interface QueryCityMetroArgs {
  cityId: Scalars['ID']
}

export interface QueryCompanyStaffSizeArgs {
  id: Scalars['ID']
}

export interface QueryEducationLevelArgs {
  id: Scalars['ID']
}

export interface QueryExperienceArgs {
  id: Scalars['ID']
}

export interface QueryLanguageArgs {
  id: Scalars['ID']
}

export interface QueryLanguageSkillLevelArgs {
  id: Scalars['ID']
}

export interface QueryMetroArgs {
  id: Scalars['ID']
}

export interface QueryMicroDistrictArgs {
  id: Scalars['ID']
}

export interface QueryPositionsArgs {
  term: Scalars['String']
}

export interface QueryResumeFillingTypeArgs {
  id: Scalars['ID']
}

export interface QueryRubricArgs {
  id: Scalars['ID']
}

export interface QueryScheduleArgs {
  id: Scalars['ID']
}

export interface QuerySearchKeywordsArgs {
  after: InputMaybe<Scalars['String']>
  before: InputMaybe<Scalars['String']>
  first: InputMaybe<Scalars['Int']>
  last: InputMaybe<Scalars['Int']>
  where: InputMaybe<SearchKeywordWhereInput>
}

export interface QuerySubrubricArgs {
  id: Scalars['ID']
}

export interface QueryVacancyBenefitArgs {
  id: Scalars['ID']
}

export interface ResumeFillingType {
  readonly __typename?: 'ResumeFillingType'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
}

export interface Rubric {
  readonly __typename?: 'Rubric'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
  readonly subrubrics: ReadonlyArray<Subrubric>
}

export interface Schedule {
  readonly __typename?: 'Schedule'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
}

export interface SearchKeyword {
  readonly __typename?: 'SearchKeyword'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
  readonly slug: Scalars['String']
  readonly translations: ReadonlyArray<Translation>
}

export interface SearchKeywordTranslationsArgs {
  languages: InputMaybe<ReadonlyArray<TranslationLanguageEnum>>
}

/** A connection to a list of items. */
export interface SearchKeywordConnection {
  readonly __typename?: 'SearchKeywordConnection'
  /** A list of edges. */
  readonly edges: Maybe<ReadonlyArray<SearchKeywordEdge>>
  /** A flattened list of the nodes. */
  readonly nodes: Maybe<ReadonlyArray<SearchKeyword>>
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo
  /** Identifies the total count of items in the connection. */
  readonly totalCount: Scalars['Int']
}

/** An edge in a connection. */
export interface SearchKeywordEdge {
  readonly __typename?: 'SearchKeywordEdge'
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']
  /** The item at the end of the edge. */
  readonly node: SearchKeyword
}

export interface SearchKeywordWhereIdInput {
  readonly eq: InputMaybe<Scalars['ID']>
  readonly in: InputMaybe<ReadonlyArray<Scalars['ID']>>
}

export interface SearchKeywordWhereInput {
  readonly id: InputMaybe<SearchKeywordWhereIdInput>
  readonly name: InputMaybe<SearchKeywordWhereNameInput>
  readonly slug: InputMaybe<SearchKeywordWhereSlugInput>
}

export interface SearchKeywordWhereNameInput {
  readonly eq: Scalars['String']
}

export interface SearchKeywordWhereSlugInput {
  readonly eq: Scalars['String']
}

export interface Subrubric {
  readonly __typename?: 'Subrubric'
  readonly externalCourses: ReadonlyArray<ExternalCourse>
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
  readonly rubric: Rubric
}

export interface Synonym {
  readonly __typename?: 'Synonym'
  readonly id: Scalars['ID']
  readonly vacancySearchUsageCount: Scalars['Int']
}

export interface Translation {
  readonly __typename?: 'Translation'
  readonly language: TranslationLanguageEnum
  readonly name: Scalars['String']
}

export enum TranslationLanguageEnum {
  English = 'ENGLISH',
  Russian = 'RUSSIAN',
  Ukrainian = 'UKRAINIAN'
}

export interface VacancyDictionaryBenefit {
  readonly __typename?: 'VacancyDictionaryBenefit'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
}

/** Union of all types that key directive applied. This information is needed by the Apollo federation gateway. */
export type _Entity =
  | Branch
  | City
  | CityDistrict
  | CompanyStaffSize
  | EducationLevel
  | Experience
  | Lang
  | LanguageSkillLevel
  | Metro
  | MetroLine
  | MicroDistrict
  | ResumeFillingType
  | Rubric
  | Schedule
  | Subrubric
  | Synonym
  | VacancyDictionaryBenefit

/** This type provides a field named sdl: String! which exposes the SDL of the service's schema. This SDL (schema definition language) is a printed version of the service's schema including the annotations of federation directives. This SDL does not include the additions of the federation spec. */
export interface _Service {
  readonly __typename?: '_Service'
  readonly sdl: Scalars['String']
}

export type ResolverTypeWrapper<T> = Promise<T> | T

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>
}
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>

export type ResolverFn<TResult, TParent, TContext, TArgs> = (parent: TParent, args: TArgs, context: TContext, info: GraphQLResolveInfo) => Promise<TResult> | TResult

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (parent: TParent, args: TArgs, context: TContext, info: GraphQLResolveInfo) => TResult | Promise<TResult>

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (parent: TParent, context: TContext, info: GraphQLResolveInfo) => Maybe<TTypes> | Promise<Maybe<TTypes>>

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>

export type NextResolverFn<T> = () => Promise<T>

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  ApplyPolicy: ApplyPolicy
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>
  Branch: ResolverTypeWrapper<Branch>
  CitiesConnection: ResolverTypeWrapper<CitiesConnection>
  CitiesConnectionInput: CitiesConnectionInput
  City: ResolverTypeWrapper<City>
  CityDistrict: ResolverTypeWrapper<CityDistrict>
  CityEdge: ResolverTypeWrapper<CityEdge>
  CompanyStaffSize: ResolverTypeWrapper<CompanyStaffSize>
  EducationLevel: ResolverTypeWrapper<EducationLevel>
  Experience: ResolverTypeWrapper<Experience>
  ExternalCourse: ResolversTypes['PrometheusExternalCourse']
  FieldSet: ResolverTypeWrapper<Scalars['FieldSet']>
  Float: ResolverTypeWrapper<Scalars['Float']>
  ID: ResolverTypeWrapper<Scalars['ID']>
  Int: ResolverTypeWrapper<Scalars['Int']>
  Lang: ResolverTypeWrapper<Lang>
  LanguageSkillLevel: ResolverTypeWrapper<LanguageSkillLevel>
  LocalizedItem: ResolversTypes['MicroDistrict']
  Location: ResolverTypeWrapper<Location>
  Metro: ResolverTypeWrapper<Metro>
  MetroLine: ResolverTypeWrapper<MetroLine>
  MicroDistrict: ResolverTypeWrapper<MicroDistrict>
  PageInfo: ResolverTypeWrapper<PageInfo>
  PrometheusExternalCourse: ResolverTypeWrapper<PrometheusExternalCourse>
  Query: ResolverTypeWrapper<{}>
  ResumeFillingType: ResolverTypeWrapper<ResumeFillingType>
  Rubric: ResolverTypeWrapper<Rubric>
  Schedule: ResolverTypeWrapper<Schedule>
  SearchKeyword: ResolverTypeWrapper<SearchKeyword>
  SearchKeywordConnection: ResolverTypeWrapper<SearchKeywordConnection>
  SearchKeywordEdge: ResolverTypeWrapper<SearchKeywordEdge>
  SearchKeywordWhereIdInput: SearchKeywordWhereIdInput
  SearchKeywordWhereInput: SearchKeywordWhereInput
  SearchKeywordWhereNameInput: SearchKeywordWhereNameInput
  SearchKeywordWhereSlugInput: SearchKeywordWhereSlugInput
  String: ResolverTypeWrapper<Scalars['String']>
  Subrubric: ResolverTypeWrapper<Omit<Subrubric, 'externalCourses'> & { externalCourses: ReadonlyArray<ResolversTypes['ExternalCourse']> }>
  Synonym: ResolverTypeWrapper<Synonym>
  Translation: ResolverTypeWrapper<Translation>
  TranslationLanguageEnum: TranslationLanguageEnum
  VacancyDictionaryBenefit: ResolverTypeWrapper<VacancyDictionaryBenefit>
  _Any: ResolverTypeWrapper<Scalars['_Any']>
  _Entity:
    | ResolversTypes['Branch']
    | ResolversTypes['City']
    | ResolversTypes['CityDistrict']
    | ResolversTypes['CompanyStaffSize']
    | ResolversTypes['EducationLevel']
    | ResolversTypes['Experience']
    | ResolversTypes['Lang']
    | ResolversTypes['LanguageSkillLevel']
    | ResolversTypes['Metro']
    | ResolversTypes['MetroLine']
    | ResolversTypes['MicroDistrict']
    | ResolversTypes['ResumeFillingType']
    | ResolversTypes['Rubric']
    | ResolversTypes['Schedule']
    | ResolversTypes['Subrubric']
    | ResolversTypes['Synonym']
    | ResolversTypes['VacancyDictionaryBenefit']
  _Service: ResolverTypeWrapper<_Service>
}

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Boolean: Scalars['Boolean']
  Branch: Branch
  CitiesConnection: CitiesConnection
  CitiesConnectionInput: CitiesConnectionInput
  City: City
  CityDistrict: CityDistrict
  CityEdge: CityEdge
  CompanyStaffSize: CompanyStaffSize
  EducationLevel: EducationLevel
  Experience: Experience
  ExternalCourse: ResolversParentTypes['PrometheusExternalCourse']
  FieldSet: Scalars['FieldSet']
  Float: Scalars['Float']
  ID: Scalars['ID']
  Int: Scalars['Int']
  Lang: Lang
  LanguageSkillLevel: LanguageSkillLevel
  LocalizedItem: ResolversParentTypes['MicroDistrict']
  Location: Location
  Metro: Metro
  MetroLine: MetroLine
  MicroDistrict: MicroDistrict
  PageInfo: PageInfo
  PrometheusExternalCourse: PrometheusExternalCourse
  Query: {}
  ResumeFillingType: ResumeFillingType
  Rubric: Rubric
  Schedule: Schedule
  SearchKeyword: SearchKeyword
  SearchKeywordConnection: SearchKeywordConnection
  SearchKeywordEdge: SearchKeywordEdge
  SearchKeywordWhereIdInput: SearchKeywordWhereIdInput
  SearchKeywordWhereInput: SearchKeywordWhereInput
  SearchKeywordWhereNameInput: SearchKeywordWhereNameInput
  SearchKeywordWhereSlugInput: SearchKeywordWhereSlugInput
  String: Scalars['String']
  Subrubric: Omit<Subrubric, 'externalCourses'> & { externalCourses: ReadonlyArray<ResolversParentTypes['ExternalCourse']> }
  Synonym: Synonym
  Translation: Translation
  VacancyDictionaryBenefit: VacancyDictionaryBenefit
  _Any: Scalars['_Any']
  _Entity:
    | ResolversParentTypes['Branch']
    | ResolversParentTypes['City']
    | ResolversParentTypes['CityDistrict']
    | ResolversParentTypes['CompanyStaffSize']
    | ResolversParentTypes['EducationLevel']
    | ResolversParentTypes['Experience']
    | ResolversParentTypes['Lang']
    | ResolversParentTypes['LanguageSkillLevel']
    | ResolversParentTypes['Metro']
    | ResolversParentTypes['MetroLine']
    | ResolversParentTypes['MicroDistrict']
    | ResolversParentTypes['ResumeFillingType']
    | ResolversParentTypes['Rubric']
    | ResolversParentTypes['Schedule']
    | ResolversParentTypes['Subrubric']
    | ResolversParentTypes['Synonym']
    | ResolversParentTypes['VacancyDictionaryBenefit']
  _Service: _Service
}

export type CostDirectiveArgs = {
  weight: Scalars['String']
}

export type CostDirectiveResolver<Result, Parent, ContextType = any, Args = CostDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>

export type KeyDirectiveArgs = {
  fields: Scalars['FieldSet']
  resolvable?: Maybe<Scalars['Boolean']>
}

export type KeyDirectiveResolver<Result, Parent, ContextType = any, Args = KeyDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>

export type LinkDirectiveArgs = {
  import: Maybe<ReadonlyArray<Scalars['String']>>
  url: Scalars['String']
}

export type LinkDirectiveResolver<Result, Parent, ContextType = any, Args = LinkDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>

export type ListSizeDirectiveArgs = {
  assumedSize: Maybe<Scalars['Int']>
  requireOneSlicingArgument?: Scalars['Boolean']
  sizedFields: Maybe<ReadonlyArray<Scalars['String']>>
  slicingArgumentDefaultValue: Maybe<Scalars['Int']>
  slicingArguments: Maybe<ReadonlyArray<Scalars['String']>>
}

export type ListSizeDirectiveResolver<Result, Parent, ContextType = any, Args = ListSizeDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>

export type OneOfDirectiveArgs = {}

export type OneOfDirectiveResolver<Result, Parent, ContextType = any, Args = OneOfDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>

export type ShareableDirectiveArgs = {}

export type ShareableDirectiveResolver<Result, Parent, ContextType = any, Args = ShareableDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>

export type BranchResolvers<ContextType = any, ParentType extends ResolversParentTypes['Branch'] = ResolversParentTypes['Branch']> = {
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CitiesConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CitiesConnection'] = ResolversParentTypes['CitiesConnection']> = {
  edges: Resolver<Maybe<ReadonlyArray<ResolversTypes['CityEdge']>>, ParentType, ContextType>
  nodes: Resolver<Maybe<ReadonlyArray<ResolversTypes['City']>>, ParentType, ContextType>
  pageInfo: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CityResolvers<ContextType = any, ParentType extends ResolversParentTypes['City'] = ResolversParentTypes['City']> = {
  districts: Resolver<ReadonlyArray<ResolversTypes['CityDistrict']>, ParentType, ContextType>
  en: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  location: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>
  metroLines: Resolver<ReadonlyArray<ResolversTypes['MetroLine']>, ParentType, ContextType>
  microDistricts: Resolver<ReadonlyArray<ResolversTypes['MicroDistrict']>, ParentType, ContextType>
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>
  regionName: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  ru: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  ua: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CityDistrictResolvers<ContextType = any, ParentType extends ResolversParentTypes['CityDistrict'] = ResolversParentTypes['CityDistrict']> = {
  city: Resolver<ResolversTypes['City'], ParentType, ContextType>
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>
  slug: Resolver<ResolversTypes['String'], ParentType, ContextType>
  translations: Resolver<ReadonlyArray<ResolversTypes['Translation']>, ParentType, ContextType, Partial<CityDistrictTranslationsArgs>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CityEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CityEdge'] = ResolversParentTypes['CityEdge']> = {
  cursor: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node: Resolver<ResolversTypes['City'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompanyStaffSizeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyStaffSize'] = ResolversParentTypes['CompanyStaffSize']> = {
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EducationLevelResolvers<ContextType = any, ParentType extends ResolversParentTypes['EducationLevel'] = ResolversParentTypes['EducationLevel']> = {
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ExperienceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Experience'] = ResolversParentTypes['Experience']> = {
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ExternalCourseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExternalCourse'] = ResolversParentTypes['ExternalCourse']> = {
  __resolveType: TypeResolveFn<'PrometheusExternalCourse', ParentType, ContextType>
}

export interface FieldSetScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['FieldSet'], any> {
  name: 'FieldSet'
}

export type LangResolvers<ContextType = any, ParentType extends ResolversParentTypes['Lang'] = ResolversParentTypes['Lang']> = {
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LanguageSkillLevelResolvers<ContextType = any, ParentType extends ResolversParentTypes['LanguageSkillLevel'] = ResolversParentTypes['LanguageSkillLevel']> = {
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LocalizedItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['LocalizedItem'] = ResolversParentTypes['LocalizedItem']> = {
  __resolveType: TypeResolveFn<'MicroDistrict', ParentType, ContextType>
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>
  slug: Resolver<ResolversTypes['String'], ParentType, ContextType>
  translations: Resolver<ReadonlyArray<ResolversTypes['Translation']>, ParentType, ContextType, Partial<LocalizedItemTranslationsArgs>>
}

export type LocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Location'] = ResolversParentTypes['Location']> = {
  latitude: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  longitude: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MetroResolvers<ContextType = any, ParentType extends ResolversParentTypes['Metro'] = ResolversParentTypes['Metro']> = {
  city: Resolver<ResolversTypes['City'], ParentType, ContextType>
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  line: Resolver<ResolversTypes['MetroLine'], ParentType, ContextType>
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>
  slug: Resolver<ResolversTypes['String'], ParentType, ContextType>
  translations: Resolver<ReadonlyArray<ResolversTypes['Translation']>, ParentType, ContextType, Partial<MetroTranslationsArgs>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MetroLineResolvers<ContextType = any, ParentType extends ResolversParentTypes['MetroLine'] = ResolversParentTypes['MetroLine']> = {
  city: Resolver<ResolversTypes['City'], ParentType, ContextType>
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>
  slug: Resolver<ResolversTypes['String'], ParentType, ContextType>
  stations: Resolver<ReadonlyArray<ResolversTypes['Metro']>, ParentType, ContextType>
  translations: Resolver<ReadonlyArray<ResolversTypes['Translation']>, ParentType, ContextType, Partial<MetroLineTranslationsArgs>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MicroDistrictResolvers<ContextType = any, ParentType extends ResolversParentTypes['MicroDistrict'] = ResolversParentTypes['MicroDistrict']> = {
  city: Resolver<ResolversTypes['City'], ParentType, ContextType>
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>
  slug: Resolver<ResolversTypes['String'], ParentType, ContextType>
  synonyms: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>
  translations: Resolver<ReadonlyArray<ResolversTypes['Translation']>, ParentType, ContextType, Partial<MicroDistrictTranslationsArgs>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PageInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['PageInfo'] = ResolversParentTypes['PageInfo']> = {
  endCursor: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  hasNextPage: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  hasPreviousPage: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  startCursor: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PrometheusExternalCourseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PrometheusExternalCourse'] = ResolversParentTypes['PrometheusExternalCourse']> = {
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  url: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  _entities: Resolver<ReadonlyArray<Maybe<ResolversTypes['_Entity']>>, ParentType, ContextType, RequireFields<Query_EntitiesArgs, 'representations'>>
  _service: Resolver<ResolversTypes['_Service'], ParentType, ContextType>
  branch: Resolver<Maybe<ResolversTypes['Branch']>, ParentType, ContextType, RequireFields<QueryBranchArgs, 'id'>>
  branches: Resolver<ReadonlyArray<ResolversTypes['Branch']>, ParentType, ContextType>
  cities: Resolver<ReadonlyArray<ResolversTypes['City']>, ParentType, ContextType>
  citiesConnection: Resolver<Maybe<ResolversTypes['CitiesConnection']>, ParentType, ContextType, Partial<QueryCitiesConnectionArgs>>
  city: Resolver<Maybe<ResolversTypes['City']>, ParentType, ContextType, RequireFields<QueryCityArgs, 'id'>>
  cityDistrict: Resolver<ResolversTypes['CityDistrict'], ParentType, ContextType, RequireFields<QueryCityDistrictArgs, 'id'>>
  cityDistricts: Resolver<ReadonlyArray<ResolversTypes['CityDistrict']>, ParentType, ContextType, RequireFields<QueryCityDistrictsArgs, 'cityId'>>
  cityMetro: Resolver<ReadonlyArray<ResolversTypes['Metro']>, ParentType, ContextType, RequireFields<QueryCityMetroArgs, 'cityId'>>
  companyStaffSize: Resolver<ResolversTypes['CompanyStaffSize'], ParentType, ContextType, RequireFields<QueryCompanyStaffSizeArgs, 'id'>>
  companyStaffSizes: Resolver<ReadonlyArray<ResolversTypes['CompanyStaffSize']>, ParentType, ContextType>
  educationLevel: Resolver<Maybe<ResolversTypes['EducationLevel']>, ParentType, ContextType, RequireFields<QueryEducationLevelArgs, 'id'>>
  educationLevels: Resolver<ReadonlyArray<ResolversTypes['EducationLevel']>, ParentType, ContextType>
  experience: Resolver<Maybe<ResolversTypes['Experience']>, ParentType, ContextType, RequireFields<QueryExperienceArgs, 'id'>>
  experiences: Resolver<ReadonlyArray<ResolversTypes['Experience']>, ParentType, ContextType>
  language: Resolver<Maybe<ResolversTypes['Lang']>, ParentType, ContextType, RequireFields<QueryLanguageArgs, 'id'>>
  languageSkillLevel: Resolver<Maybe<ResolversTypes['LanguageSkillLevel']>, ParentType, ContextType, RequireFields<QueryLanguageSkillLevelArgs, 'id'>>
  languageSkillLevels: Resolver<ReadonlyArray<ResolversTypes['LanguageSkillLevel']>, ParentType, ContextType>
  languages: Resolver<ReadonlyArray<ResolversTypes['Lang']>, ParentType, ContextType>
  metro: Resolver<ResolversTypes['Metro'], ParentType, ContextType, RequireFields<QueryMetroArgs, 'id'>>
  microDistrict: Resolver<Maybe<ResolversTypes['MicroDistrict']>, ParentType, ContextType, RequireFields<QueryMicroDistrictArgs, 'id'>>
  positions: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType, RequireFields<QueryPositionsArgs, 'term'>>
  resumeFillingType: Resolver<ResolversTypes['ResumeFillingType'], ParentType, ContextType, RequireFields<QueryResumeFillingTypeArgs, 'id'>>
  resumeFillingTypes: Resolver<ReadonlyArray<ResolversTypes['ResumeFillingType']>, ParentType, ContextType>
  rubric: Resolver<Maybe<ResolversTypes['Rubric']>, ParentType, ContextType, RequireFields<QueryRubricArgs, 'id'>>
  rubrics: Resolver<ReadonlyArray<ResolversTypes['Rubric']>, ParentType, ContextType>
  schedule: Resolver<Maybe<ResolversTypes['Schedule']>, ParentType, ContextType, RequireFields<QueryScheduleArgs, 'id'>>
  schedules: Resolver<ReadonlyArray<ResolversTypes['Schedule']>, ParentType, ContextType>
  searchKeywords: Resolver<Maybe<ResolversTypes['SearchKeywordConnection']>, ParentType, ContextType, Partial<QuerySearchKeywordsArgs>>
  stopWords: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>
  subrubric: Resolver<Maybe<ResolversTypes['Subrubric']>, ParentType, ContextType, RequireFields<QuerySubrubricArgs, 'id'>>
  subrubrics: Resolver<ReadonlyArray<ResolversTypes['Subrubric']>, ParentType, ContextType>
  vacancyBenefit: Resolver<ResolversTypes['VacancyDictionaryBenefit'], ParentType, ContextType, RequireFields<QueryVacancyBenefitArgs, 'id'>>
  vacancyBenefits: Resolver<ReadonlyArray<ResolversTypes['VacancyDictionaryBenefit']>, ParentType, ContextType>
}

export type ResumeFillingTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResumeFillingType'] = ResolversParentTypes['ResumeFillingType']> = {
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RubricResolvers<ContextType = any, ParentType extends ResolversParentTypes['Rubric'] = ResolversParentTypes['Rubric']> = {
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>
  subrubrics: Resolver<ReadonlyArray<ResolversTypes['Subrubric']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ScheduleResolvers<ContextType = any, ParentType extends ResolversParentTypes['Schedule'] = ResolversParentTypes['Schedule']> = {
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SearchKeywordResolvers<ContextType = any, ParentType extends ResolversParentTypes['SearchKeyword'] = ResolversParentTypes['SearchKeyword']> = {
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>
  slug: Resolver<ResolversTypes['String'], ParentType, ContextType>
  translations: Resolver<ReadonlyArray<ResolversTypes['Translation']>, ParentType, ContextType, Partial<SearchKeywordTranslationsArgs>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SearchKeywordConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['SearchKeywordConnection'] = ResolversParentTypes['SearchKeywordConnection']> = {
  edges: Resolver<Maybe<ReadonlyArray<ResolversTypes['SearchKeywordEdge']>>, ParentType, ContextType>
  nodes: Resolver<Maybe<ReadonlyArray<ResolversTypes['SearchKeyword']>>, ParentType, ContextType>
  pageInfo: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SearchKeywordEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SearchKeywordEdge'] = ResolversParentTypes['SearchKeywordEdge']> = {
  cursor: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node: Resolver<ResolversTypes['SearchKeyword'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SubrubricResolvers<ContextType = any, ParentType extends ResolversParentTypes['Subrubric'] = ResolversParentTypes['Subrubric']> = {
  externalCourses: Resolver<ReadonlyArray<ResolversTypes['ExternalCourse']>, ParentType, ContextType>
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>
  rubric: Resolver<ResolversTypes['Rubric'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SynonymResolvers<ContextType = any, ParentType extends ResolversParentTypes['Synonym'] = ResolversParentTypes['Synonym']> = {
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  vacancySearchUsageCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TranslationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Translation'] = ResolversParentTypes['Translation']> = {
  language: Resolver<ResolversTypes['TranslationLanguageEnum'], ParentType, ContextType>
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyDictionaryBenefitResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyDictionaryBenefit'] = ResolversParentTypes['VacancyDictionaryBenefit']> = {
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface _AnyScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['_Any'], any> {
  name: '_Any'
}

export type _EntityResolvers<ContextType = any, ParentType extends ResolversParentTypes['_Entity'] = ResolversParentTypes['_Entity']> = {
  __resolveType: TypeResolveFn<
    | 'Branch'
    | 'City'
    | 'CityDistrict'
    | 'CompanyStaffSize'
    | 'EducationLevel'
    | 'Experience'
    | 'Lang'
    | 'LanguageSkillLevel'
    | 'Metro'
    | 'MetroLine'
    | 'MicroDistrict'
    | 'ResumeFillingType'
    | 'Rubric'
    | 'Schedule'
    | 'Subrubric'
    | 'Synonym'
    | 'VacancyDictionaryBenefit',
    ParentType,
    ContextType
  >
}

export type _ServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['_Service'] = ResolversParentTypes['_Service']> = {
  sdl: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Resolvers<ContextType = any> = {
  Branch: BranchResolvers<ContextType>
  CitiesConnection: CitiesConnectionResolvers<ContextType>
  City: CityResolvers<ContextType>
  CityDistrict: CityDistrictResolvers<ContextType>
  CityEdge: CityEdgeResolvers<ContextType>
  CompanyStaffSize: CompanyStaffSizeResolvers<ContextType>
  EducationLevel: EducationLevelResolvers<ContextType>
  Experience: ExperienceResolvers<ContextType>
  ExternalCourse: ExternalCourseResolvers<ContextType>
  FieldSet: GraphQLScalarType
  Lang: LangResolvers<ContextType>
  LanguageSkillLevel: LanguageSkillLevelResolvers<ContextType>
  LocalizedItem: LocalizedItemResolvers<ContextType>
  Location: LocationResolvers<ContextType>
  Metro: MetroResolvers<ContextType>
  MetroLine: MetroLineResolvers<ContextType>
  MicroDistrict: MicroDistrictResolvers<ContextType>
  PageInfo: PageInfoResolvers<ContextType>
  PrometheusExternalCourse: PrometheusExternalCourseResolvers<ContextType>
  Query: QueryResolvers<ContextType>
  ResumeFillingType: ResumeFillingTypeResolvers<ContextType>
  Rubric: RubricResolvers<ContextType>
  Schedule: ScheduleResolvers<ContextType>
  SearchKeyword: SearchKeywordResolvers<ContextType>
  SearchKeywordConnection: SearchKeywordConnectionResolvers<ContextType>
  SearchKeywordEdge: SearchKeywordEdgeResolvers<ContextType>
  Subrubric: SubrubricResolvers<ContextType>
  Synonym: SynonymResolvers<ContextType>
  Translation: TranslationResolvers<ContextType>
  VacancyDictionaryBenefit: VacancyDictionaryBenefitResolvers<ContextType>
  _Any: GraphQLScalarType
  _Entity: _EntityResolvers<ContextType>
  _Service: _ServiceResolvers<ContextType>
}

export type DirectiveResolvers<ContextType = any> = {
  cost: CostDirectiveResolver<any, any, ContextType>
  key: KeyDirectiveResolver<any, any, ContextType>
  link: LinkDirectiveResolver<any, any, ContextType>
  listSize: ListSizeDirectiveResolver<any, any, ContextType>
  oneOf: OneOfDirectiveResolver<any, any, ContextType>
  shareable: ShareableDirectiveResolver<any, any, ContextType>
}
