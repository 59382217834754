/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core'
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http'
import { BaseService } from '../base-service'
import { UsersOpenApiModuleConfig } from '../users-open-api-module-config'
import { StrictHttpResponse } from '../strict-http-response'
import { RequestBuilder } from '../request-builder'
import { Observable } from 'rxjs'
import { map, filter } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class UsersOpenApiSsoService extends BaseService {
  constructor(config: UsersOpenApiModuleConfig, http: HttpClient) {
    super(config, http)
  }

  /**
   * Path part for operation ssoGoogleGet
   */
  static readonly SsoGoogleGetPath = '/sso/google'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ssoGoogleGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  ssoGoogleGet$Response(params?: { code?: string; state?: string; context?: HttpContext }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, UsersOpenApiSsoService.SsoGoogleGetPath, 'get')
    if (params) {
      rb.query('code', params.code, {})
      rb.query('state', params.state, {})
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>
        })
      )
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ssoGoogleGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ssoGoogleGet(params?: { code?: string; state?: string; context?: HttpContext }): Observable<void> {
    return this.ssoGoogleGet$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void))
  }

  /**
   * Path part for operation ssoGoogleTokenGet
   */
  static readonly SsoGoogleTokenGetPath = '/sso/google/token'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ssoGoogleTokenGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  ssoGoogleTokenGet$Response(params?: { id_token?: string; context?: HttpContext }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, UsersOpenApiSsoService.SsoGoogleTokenGetPath, 'get')
    if (params) {
      rb.query('id_token', params.id_token, {})
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>
        })
      )
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ssoGoogleTokenGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ssoGoogleTokenGet(params?: { id_token?: string; context?: HttpContext }): Observable<void> {
    return this.ssoGoogleTokenGet$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void))
  }

  /**
   * Path part for operation ssoFacebookGet
   */
  static readonly SsoFacebookGetPath = '/sso/facebook'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ssoFacebookGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  ssoFacebookGet$Response(params?: { code?: string; state?: string; context?: HttpContext }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, UsersOpenApiSsoService.SsoFacebookGetPath, 'get')
    if (params) {
      rb.query('code', params.code, {})
      rb.query('state', params.state, {})
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>
        })
      )
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ssoFacebookGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ssoFacebookGet(params?: { code?: string; state?: string; context?: HttpContext }): Observable<void> {
    return this.ssoFacebookGet$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void))
  }

  /**
   * Path part for operation ssoLinkedinLoginGet
   */
  static readonly SsoLinkedinLoginGetPath = '/sso/linkedin/login'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ssoLinkedinLoginGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  ssoLinkedinLoginGet$Response(params?: { code?: string; state?: string; context?: HttpContext }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, UsersOpenApiSsoService.SsoLinkedinLoginGetPath, 'get')
    if (params) {
      rb.query('code', params.code, {})
      rb.query('state', params.state, {})
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>
        })
      )
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ssoLinkedinLoginGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ssoLinkedinLoginGet(params?: { code?: string; state?: string; context?: HttpContext }): Observable<void> {
    return this.ssoLinkedinLoginGet$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void))
  }

  /**
   * Path part for operation ssoLinkedinGet
   */
  static readonly SsoLinkedinGetPath = '/sso/linkedin'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ssoLinkedinGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  ssoLinkedinGet$Response(params?: { code?: string; state?: string; context?: HttpContext }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, UsersOpenApiSsoService.SsoLinkedinGetPath, 'get')
    if (params) {
      rb.query('code', params.code, {})
      rb.query('state', params.state, {})
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>
        })
      )
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ssoLinkedinGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ssoLinkedinGet(params?: { code?: string; state?: string; context?: HttpContext }): Observable<void> {
    return this.ssoLinkedinGet$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void))
  }

  /**
   * Path part for operation ssoIdgovuaGet
   */
  static readonly SsoIdgovuaGetPath = '/sso/idgovua'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ssoIdgovuaGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  ssoIdgovuaGet$Response(params?: { code?: string; state?: string; context?: HttpContext }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, UsersOpenApiSsoService.SsoIdgovuaGetPath, 'get')
    if (params) {
      rb.query('code', params.code, {})
      rb.query('state', params.state, {})
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
          context: params?.context
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>
        })
      )
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ssoIdgovuaGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ssoIdgovuaGet(params?: { code?: string; state?: string; context?: HttpContext }): Observable<void> {
    return this.ssoIdgovuaGet$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void))
  }
}
