/* eslint-disable max-lines */
import {
  CvDbHintsResponse,
  CvDbSearchDocumentsResponse,
  CvDbSearchFacetsResponse,
  CvDbSearchRequest,
  CvDbSelectedSearchItem,
  CvDbService as CvdbHttpService,
  CvdbService as CvdbServiceItem,
  CvKnockout,
  CvLastViewViewModel,
  CvNote,
  CvOfferInfo,
  CvSearchResponse,
  NoteService,
  ResumeInfo,
  ResumeService,
  SendOfferMessageRequest,
  SuggestKeyword,
  VacancyOffer,
  VacancyService,
  OpenContactsResult as OpenContactsEndpointResultV2
} from '@alliance/employer/data-access'
import { Vacancy } from '@alliance/shared/domain-gql'
import { AuthService } from '@alliance/shared/auth/api'
import { log } from '@alliance/shared/logger'
import { LocalStorage } from '@alliance/shared/storage'
import { TranslationService } from '@alliance/shared/translation'
import { DetectPlatformService, isValidKey, PubsubMessageTypesEnum, retryWhenStrategy, TransferService } from '@alliance/shared/utils'
import { Injectable } from '@angular/core'
import { makeStateKey, TransferState } from '@angular/platform-browser'
import { BehaviorSubject, combineLatest, merge, Observable, of } from 'rxjs'
import { catchError, distinctUntilChanged, distinctUntilKeyChanged, filter, first, map, pairwise, retryWhen, switchMap, take, takeUntil, tap } from 'rxjs/operators'
import { AnalyticsService } from '../analytics/analytics.service'
import { ResumeDislikeEvent } from '../analytics/constants/resume-dislike-event'
import { BillingService } from '../billing-store/billing.service'
import { LAST_CVDB_FILTERS_STORAGE_KEY } from '../constants/last-cvdb-filters-storage-key'
import { CvdbFacetsDictionaryService } from './cvdb.facets.dictionary'
import { CvdbStorePendingModel } from './cvdb.model'
import { CvdbQuery } from './cvdb.query'
import { CvdbStore } from './cvdb.store'
import { convertCvDbSearchRequestToCacheableParams } from './utils/convert-cv-db-search-request-to-cacheable-params'
import { createAlternativeFilters } from './utils/create-alternative-filters'
import { getResumesSearchFilterDefault } from './utils/get-resumes-search-filter-default'
import { normalizePage } from './utils/normalize-page'
import { OpenContactsStatusResult } from '../models/open-contacts-result-v2.interface'
import { SANTA_HIGHLIGHTER_CLASS } from '@alliance/shared/constants'
import { KeywordsHighlighter } from '../models/keywords-highlighter.interface'
import { CvdbFiltersFeatureService } from '../cvdb/cvdb-filters-feature.service'
import SearchContextEnum = CvDbSearchRequest.SearchContextEnum
import NotesParams = NoteService.NotesParams

@Injectable({ providedIn: 'root' })
export class CvdbService {
  public firstLoad = true
  public urlParamsForSsr$ = new BehaviorSubject<CvDbSearchRequest | null>(null)

  private readonly MAIN_CONTEXT_SEARCH_FILTERS: Array<keyof CvDbSearchRequest> = ['cityId', 'keyWords']
  private readonly LIST_TRANSFER_KEY = makeStateKey<CvDbSearchDocumentsResponse>('cv-list')
  private readonly CV_TRANSFER_KEY = makeStateKey<ResumeInfo>('cv')
  private readonly RESUMES_SEARCH_FILTER_DEFAULT = getResumesSearchFilterDefault(this.translationService.currentLangIsUkrainian(), this.cvdbFiltersFeatureService.isOnlyVeteransFeatureEnabled)

  public constructor(
    private authService: AuthService,
    private cvdbStore: CvdbStore,
    private cvdbQuery: CvdbQuery,
    private resumeService: ResumeService,
    private vacancyService: VacancyService,
    private billingService: BillingService,
    private translationService: TranslationService,
    private cvdbHttpService: CvdbHttpService,
    private noteService: NoteService,
    private transferState: TransferState,
    private platformService: DetectPlatformService,
    private cvdbFacetsDictionaryService: CvdbFacetsDictionaryService,
    private analyticsService: AnalyticsService,
    private transferService: TransferService,
    private readonly storageService: LocalStorage,
    private readonly cvdbFiltersFeatureService: CvdbFiltersFeatureService
  ) {
    this.authService.tokenAppeared$.pipe(distinctUntilChanged()).subscribe(() => {
      this.syncSearchHistory()
      this.syncSavedSearchList()
    })

    this.transferService.subscribeTo(PubsubMessageTypesEnum.syncCvDbSavedSearches).subscribe(() => this.syncSavedSearchList())

    this.subscribeToSearchFiltersChanged()
    this.subscribeToSelectResumeIdChanged()
    this.subscribeToSelectResumeChangedForLoggedIn()

    this.authService.token$
      .pipe(
        pairwise(),
        filter(([prev, current]) => current !== prev),
        switchMap(() => this.cvdbQuery.selectId$().pipe(tap(id => this.updateListByResume(id))))
      )
      .subscribe()
  }

  public get defaultFilterValues(): CvDbSearchRequest {
    return this.RESUMES_SEARCH_FILTER_DEFAULT
  }

  public setFirstLoad(value: boolean): void {
    this.firstLoad = value
  }

  public setPending(inputPending: Partial<CvdbStorePendingModel>): void {
    this.cvdbStore.set(store => ({ pending: { ...store.pending, ...inputPending } }))
  }

  public setResumeId(id: number | null): void {
    this.cvdbStore.id = id

    if (id && this.authService.isEmployer) {
      this.markResumeAsViewed(id)
    }
  }

  public setSearchFilter(params: CvDbSearchRequest): void {
    this.cvdbStore.set(store => {
      // prepare search context for history saving on BE
      const searchContext = params?.searchContext || (this.MAIN_CONTEXT_SEARCH_FILTERS.some(key => isValidKey(key, params)) ? SearchContextEnum.Main : SearchContextEnum.Filters)
      const page = params?.page ? params.page : 1
      const combined = {
        ...this.RESUMES_SEARCH_FILTER_DEFAULT,
        ...store.searchFilters,
        ...params,
        page,
        searchContext,
        ...createAlternativeFilters(params, this.RESUMES_SEARCH_FILTER_DEFAULT, store),
        ...(params?.cityId === this.RESUMES_SEARCH_FILTER_DEFAULT.cityId && {
          moveability: this.RESUMES_SEARCH_FILTER_DEFAULT.moveability,
          onlyMoveability: this.RESUMES_SEARCH_FILTER_DEFAULT.onlyMoveability
        })
      }

      Object.keys(combined).forEach(key => isValidKey(key, combined) && combined[key] === null && delete combined[key])
      this.saveSearchHistory(combined)
      return { searchFilters: combined }
    })
  }

  public setPaginationPage(page: number): void {
    this.cvdbStore.set(store => {
      const combined = { ...store.searchFilters, page }
      return { searchFilters: combined, pending: { ...store.pending, searchList: true } }
    })
  }

  // LIST & CV - START WITH DATA FROM SSR
  public fetchSearchListSSR(): Observable<CvDbSearchDocumentsResponse> {
    const emptyResponse: CvSearchResponse = { total: 0, totalFull: 0, documents: [] }
    if (this.platformService.isServer) {
      return this.urlParamsForSsr$.pipe(
        filter(urlParams => !!urlParams),
        take(1),
        switchMap(urlParams => {
          const params = { ...this.RESUMES_SEARCH_FILTER_DEFAULT, ...urlParams }
          return this.cvdbHttpService.search(normalizePage(params)).pipe(
            first(),
            catchError(() => of(emptyResponse)),
            tap(data => {
              this.cvdbStore.set({
                searchTotal: data.totalFull
              })
              this.transferState.set(this.LIST_TRANSFER_KEY, data)
            })
          )
        })
      )
    } else if (this.transferState.hasKey(this.LIST_TRANSFER_KEY)) {
      const res = this.transferState.get<CvDbSearchDocumentsResponse>(this.LIST_TRANSFER_KEY, emptyResponse)
      return of(res)
    } else {
      return of(emptyResponse)
    }
  }

  public resetFilters(): void {
    this.cvdbStore.set(store => ({
      ...store,
      searchFilters: { ...store.searchFilters, ...this.defaultFilterValues }
    }))
  }

  public resetDistricts(): void {
    this.cvdbStore.set(store => ({
      ...store,
      searchFilters: {
        ...store.searchFilters,
        districtIds: []
      }
    }))
  }

  public fetchCvSSR$(): Observable<ResumeInfo | null> {
    if (this.platformService.isServer) {
      return this.cvdbQuery.selectId$().pipe(
        filter((id): id is number => !!id),
        take(1),
        switchMap(id =>
          this.resumeService.get({ id, markView: true }).pipe(
            catchError(() => of(null)),
            tap(data => this.transferState.set(this.CV_TRANSFER_KEY, data))
          )
        )
      )
    } else if (this.transferState.hasKey(this.CV_TRANSFER_KEY)) {
      const cvFromState = this.transferState.get<ResumeInfo | null>(this.CV_TRANSFER_KEY, null)
      this.transferState.remove(this.CV_TRANSFER_KEY)
      return of(cvFromState).pipe(
        tap(cv => {
          const id = cv?.resumeId
          if (id) {
            this.cvdbStore.addResume(id, cv)
            this.authService.tokenAppeared$.pipe(take(1)).subscribe(() => {
              if (this.authService.isEmployer) {
                this.updateListByResume(id)
              }
            })
          }
        })
      )
    } else {
      const { id, resumeMap } = this.cvdbQuery.getValue()
      return of(id && resumeMap ? resumeMap[id] : null)
    }
  }

  public saveSearchHistory(params: CvDbSearchRequest): void {
    if (this.authService.isEmployer) {
      this.cvdbHttpService
        .saveSearchHistory(params)
        .pipe(catchError(() => of(false)))
        .subscribe()
    }
  }

  // UPDATE COUNTERS IN FILTERS PANEL WITHOUT SEARCH CHANGE
  public updateFacetsCounters(params: CvDbSearchRequest): void {
    const store = this.cvdbQuery.getValue()
    const combinedParams: CvDbSearchRequest = {
      ...store.searchFilters,
      ...params
    }
    this.getSearchFacets(combinedParams).subscribe(response => (this.cvdbStore.searchFacets = response ?? null))
  }

  public updateListByResume(resumeId: number): void {
    this.updateListByResume$(resumeId).subscribe()
  }

  public getAutoCompleteList(keywords: string): Observable<SuggestKeyword[]> {
    // todo: add language params
    return this.cvdbQuery.selectSearchFilters$().pipe(
      take(1),
      switchMap(
        ({ cityId }: CvDbSearchRequest): Observable<SuggestKeyword[]> =>
          this.cvdbHttpService.suggest({
            keywords,
            cityId,
            ukrainian: this.translationService.currentLangIsUkrainian()
          })
      ),
      catchError(() => of([]))
    )
  }

  public openContactsAndActivate(resumeId: number, service?: CvdbServiceItem): Observable<OpenContactsStatusResult | null> {
    return service && !service.isActivate && service.orderId && service.orderDetailId
      ? this.billingService.activateService(service.orderId, service.orderDetailId).pipe(
          switchMap(() => this.openResumeContacts(resumeId)),
          catchError(() => of(null))
        )
      : this.openResumeContacts(resumeId)
  }

  public openResumeContacts(resumeId: number): Observable<OpenContactsStatusResult> {
    return this.resumeService.openContactV2$Json({ id: resumeId }).pipe(
      switchMap(response => {
        if (response === OpenContactsEndpointResultV2.Succeed) {
          return of<OpenContactsStatusResult>({ isSuccess: true, status: response })
        }

        return of<OpenContactsStatusResult>({ isSuccess: false, status: response })
      }),
      tap(() => {
        this.billingService.syncResumeServicesOpenContactsCount()
        this.markResumeWithOpenContacts(resumeId)
        this.updateListByResume(resumeId)
        this.billingService.syncContactsServices()
      }),
      catchError(() => of<OpenContactsStatusResult>({ isSuccess: false, status: null }))
    )
  }

  public sendVacancySuggestion(params: SendOfferMessageRequest): Observable<boolean> {
    return this.vacancyService.addOffer(params).pipe(
      tap(() => {
        if (params.resumeId) {
          const currentResume = this.cvdbQuery.getValue().resumeMap[params.resumeId]
          if (currentResume && !currentResume.isOpenResumeInfo) {
            this.updateListByResume(params.resumeId)
            this.billingService.syncContactsServices()
          }
        }
      }),
      map(() => true),
      catchError(() => of(false))
    )
  }

  public updateNotes(cvId: number, list: CvNote[]): void {
    this.cvdbStore.updateSearchListWithNotes(cvId, list)
    this.cvdbStore.updateNotesMap(cvId, list)
  }

  public addCvToFavorites(cvId: number, vacancyId: number | null = null): Observable<boolean> {
    return this.resumeService.addSelectedResumeToVacancy({ resumeId: cvId, ...(vacancyId && { vacancyId }) }).pipe(
      map(() => true),
      tap(() => this.toggleCvFavoriteStatusInStore(cvId)),
      catchError(() => of(false))
    )
  }

  public removeCvFromFavorites(cvId: number): Observable<boolean> {
    return this.resumeService.removeSelectedResume(cvId).pipe(
      map(() => true),
      tap(() => this.toggleCvFavoriteStatusInStore(cvId)),
      catchError(() => of(false))
    )
  }

  public savedSelectedSearch(): Observable<CvDbSelectedSearchItem> {
    if (this.authService.isEmployer) {
      return this.cvdbHttpService.saveSelectedSearches(this.cvdbQuery.getValue().searchFilters).pipe(
        catchError(() => of(null)),
        filter((data): data is CvDbSelectedSearchItem => !!data),
        tap(data => data?.isSuccessfull && this.syncSavedSearchList())
      )
    }
    return of({})
  }

  public savedRemovedSearch(search: CvDbSearchRequest): Observable<CvDbSelectedSearchItem | null> {
    return this.cvdbHttpService.saveSelectedSearches(search).pipe(
      tap(() => this.syncSavedSearchList()),
      catchError(() => of(null))
    )
  }

  public deleteSelectedSearch(id: number): Observable<boolean> {
    if (this.authService.isEmployer) {
      return this.cvdbHttpService.deleteSelectedSearch(id).pipe(
        tap(() => this.syncSavedSearchList()),
        map(() => true),
        catchError(() => of(false))
      )
    }
    return of(false)
  }

  public dislikeCv(id: number): void {
    this.handleDislikeAction(id)
  }

  public undoDislikeCv(id: number): void {
    this.handleDislikeAction(id)
  }

  public getAlternativeHints(): Observable<CvDbHintsResponse> {
    const emptyResponse: CvDbHintsResponse = { count: 0, suggestions: [] }
    const filters: CvDbSearchRequest = this.cvdbQuery.getValue()?.searchFilters
    const hintsInStore = this.cvdbQuery.getValue()?.alternativeHints

    return filters.page !== 1 && hintsInStore
      ? of(hintsInStore)
      : this.cvdbHttpService.alternativeSearchHints(filters).pipe(
          catchError(() => of(emptyResponse)),
          tap(hints => (this.cvdbStore.alternativeHints = hints))
        )
  }

  public getCvdbTotal$(): Observable<number> {
    const cachedTotal = this.cvdbQuery.getValue()?.allCvdbTotal
    return cachedTotal ? of(cachedTotal) : this.fetchCvdbTotal$().pipe(tap(total => (this.cvdbStore.allCvdbTotal = total)))
  }

  public updateVacancyOffers(vacancy: Pick<Vacancy, 'id' | 'title'>): void {
    const id = this.cvdbQuery.getValue()?.id
    if (id) {
      const list = this.getVacancyOffersMap(vacancy)
      this.cvdbStore.updateVacancyOffersMap(id, list)
    }
  }

  public paramsWithMoveability(params: CvDbSearchRequest): CvDbSearchRequest {
    let preparedParams = params
    const { moveability, onlyMoveability } = this.cvdbQuery.getValue().searchFilters

    if (moveability !== this.RESUMES_SEARCH_FILTER_DEFAULT.moveability || onlyMoveability !== this.RESUMES_SEARCH_FILTER_DEFAULT.onlyMoveability) {
      preparedParams = { ...preparedParams, moveability, onlyMoveability }
    }
    return preparedParams
  }

  public getKeywordsHighlighter$(): Observable<KeywordsHighlighter> {
    return this.cvdbQuery.selectSearchFilters$().pipe(
      map(searchFilters => (searchFilters.keyWords ? [...new Set(searchFilters.keyWords.split(' '))] : [])),
      distinctUntilChanged(),
      map(keywords => ({ keywords, useClass: SANTA_HIGHLIGHTER_CLASS }))
    )
  }

  private subscribeToSearchFiltersChanged(): void {
    this.cvdbQuery
      .selectSearchFilters$()
      .pipe(
        filter(params => this.platformService.isBrowser && Object.keys(params).length > 0),
        tap(filterParams => {
          this.setPending({ searchList: true, searchFacets: true })
          this.storageService.setItem(LAST_CVDB_FILTERS_STORAGE_KEY, JSON.stringify(filterParams))
        }),
        switchMap((params: CvDbSearchRequest) => merge(this.syncSearchList$(params), this.syncSearchFacets$(params)))
      )
      .subscribe()
  }

  private subscribeToSelectResumeIdChanged(): void {
    this.cvdbQuery
      .selectId$()
      .pipe(distinctUntilChanged())
      .subscribe(resumeId => {
        if (!this.cvdbQuery.getValue()?.resumeMap[resumeId]) {
          this.setPending({ resume: true })
          this.updateListByResume(resumeId)
        }
      })
  }

  private subscribeToSelectResumeChangedForLoggedIn(): void {
    combineLatest([
      this.cvdbQuery.selectResume$().pipe(
        filter((resume): resume is ResumeInfo => !!resume),
        distinctUntilKeyChanged('resumeId')
      ),
      this.authService.token$.pipe(filter(token => !!token))
    ]).subscribe(([resume]) => {
      if (!resume?.isOpenResumeInfo) {
        const cities = (resume?.relocations || [])
          .map(city => city.cityId)
          .concat(resume.cityId)
          .filter<number>((cityId): cityId is number => typeof cityId !== 'undefined')
        const rubrics = (resume?.subRubrics || []).map(rubric => rubric.rubricId1).filter<number>((rubricId): rubricId is number => typeof rubricId !== 'undefined')
        this.billingService.setContactFilter(cities, rubrics)
      }
      if (resume?.resumeId) {
        this.syncNotesMap(resume.resumeId)
      }
    })
  }

  private showFullTotal(params: CvDbSearchRequest): boolean {
    const defaultParams = this.RESUMES_SEARCH_FILTER_DEFAULT
    const validParams: Array<keyof CvDbSearchRequest> = ['cityId', 'period', 'searchContext', 'page']
    const appliedFilters = Object.entries(params).filter(([key, value]) => {
      let isEmptyValue = false
      // params[key]: string | number | object | array - that's why can't check it's length directly
      if (value && Array.isArray(value)) {
        isEmptyValue = !value.length
      } else if (value && typeof value === 'object') {
        isEmptyValue = !Object.entries(value).reduce<string[]>((acc, [k, v]) => (v ? [...acc, k] : acc), []).length
      }
      return !isEmptyValue && isValidKey(key, defaultParams) && defaultParams[key] !== value && validParams.indexOf(key) < 0
    })
    return !appliedFilters.length
  }

  // SYNC WITH API & SIDE EFFECTS
  private syncSearchList$(params: CvDbSearchRequest): Observable<CvDbSearchDocumentsResponse> {
    return this.fetchSearchList(params).pipe(
      tap((response: CvDbSearchDocumentsResponse) => {
        const showFullTotal = this.showFullTotal(params)
        this.cvdbStore.set(store => ({
          searchList: response.documents,
          searchTotal: response.total,
          searchTotalFull: showFullTotal ? response.totalFull : response.total,
          cvsPerPage: response.requestedCount,
          pending: { ...store.pending, searchList: false }
        }))
      })
    )
  }
  private syncSearchFacets$(params: CvDbSearchRequest): Observable<CvDbSearchFacetsResponse | null> {
    const facetsInStore = this.cvdbQuery.getValue()?.searchFacets
    return params.page !== 1 && Object.keys(facetsInStore)?.length
      ? of(null).pipe(tap(() => this.setPending({ searchFacets: false })))
      : this.getSearchFacets(params).pipe(
          tap(response => {
            this.cvdbStore.searchFacets = response
            if (params?.searchContext === SearchContextEnum.History) {
              this.syncSearchHistory()
            }
          })
        )
  }

  // API CALLS WITH ERRORS HANDLING
  private fetchSearchList(params: CvDbSearchRequest): Observable<CvDbSearchDocumentsResponse> {
    const emptyResponse: CvSearchResponse = { total: 0, totalFull: 0, documents: [] }
    if (this.transferState.hasKey(this.LIST_TRANSFER_KEY) && !this.authService.token) {
      const res = this.transferState.get<CvDbSearchDocumentsResponse>(this.LIST_TRANSFER_KEY, emptyResponse)
      this.transferState.remove(this.LIST_TRANSFER_KEY)
      return of(res)
    } else {
      const normalized = normalizePage(params)
      return this.cvdbHttpService.search(normalized).pipe(
        retryWhen(retryWhenStrategy()),
        catchError(() => of(emptyResponse))
      )
    }
  }

  private getSearchFacets(params: CvDbSearchRequest): Observable<CvDbSearchFacetsResponse | null> {
    const normalized = normalizePage(params)

    return this.cvdbHttpService
      .facetsV2({
        ...convertCvDbSearchRequestToCacheableParams(normalized),
        ...(this.authService?.user
          ? {
              NotebookId: this.authService.user.NotebookId,
              MultiUserId: this.authService.user.MultiUserId,
              IsEmployer: this.authService.isEmployer
            }
          : {})
      })
      .pipe(
        retryWhen(retryWhenStrategy()),
        switchMap(facets => this.cvdbFacetsDictionaryService.addNames(facets, this.cvdbQuery.getValue().searchFilters.cityId)),
        catchError(e => {
          log.error({ where: 'employer-cvdb: CvdbService', category: 'api_call_failed', message: 'getSearchFacets failed', error: e })
          return of(null)
        })
      )
  }

  private markResumeWithOpenContacts(id: number): void {
    const resumeFromSearchList = (this.cvdbQuery.getValue().searchList || []).find(resume => resume.resumeId === id)

    if (resumeFromSearchList) {
      const user = this.authService.user

      const updatedResume: CvKnockout = {
        ...resumeFromSearchList,
        areContactsOpenedForCurrentUser: true,
        areContactsOpenedByMe: true,
        contactsWereOpenedAt: new Date(),
        userNameWhoOpenedContacts: user?.UserName
      }

      this.cvdbStore.updateSearchList(updatedResume)
    }
  }

  private markResumeAsViewed(id: number): void {
    const resumeFromSearchList = (this.cvdbQuery.getValue().searchList || []).find(resume => resume.resumeId === id)
    if (resumeFromSearchList) {
      if (resumeFromSearchList.isNew && !resumeFromSearchList.isViewed) {
        this.cvdbStore.set(store => ({
          searchNewCv: store.searchNewCv ? store.searchNewCv - 1 : 0
        }))
      }

      const user = this.authService.user
      const lastView: CvLastViewViewModel = {
        date: new Date(),
        isCurrentUser: true,
        multiUserId: user?.MultiUserId,
        multiUserName: user?.UserName
      }
      const updatedResume: CvKnockout = {
        ...resumeFromSearchList,
        isViewed: true,
        lastView
      }
      this.cvdbStore.updateSearchList(updatedResume)
    }
  }

  private syncNotesMap(resumeId: number): void {
    const isInStore = this.cvdbQuery.getValue()?.notesMap[resumeId]
    if (!isInStore && this.platformService.isBrowser) {
      const params: NotesParams = { id: resumeId }
      this.noteService
        .notes(params)
        .pipe(catchError(() => of([])))
        .subscribe(list => this.cvdbStore.updateNotesMap(resumeId, list))
    }
  }

  private toggleCvFavoriteStatusInStore(cvId: number): void {
    const resumeFromMap = this.cvdbQuery.getValue().resumeMap[cvId]
    if (resumeFromMap) {
      this.cvdbStore.addResume(cvId, {
        ...resumeFromMap,
        isSelectedToNotepad: !resumeFromMap.isSelectedToNotepad
      })
    }

    const resumeFromSearchList = (this.cvdbQuery.getValue().searchList || []).find(resume => resume.resumeId === cvId)
    if (resumeFromSearchList) {
      const updatedResume: CvKnockout = {
        ...resumeFromSearchList,
        starred: !resumeFromSearchList.starred
      }
      this.cvdbStore.updateSearchList(updatedResume)
    }
  }

  private syncSearchHistory(): void {
    if (this.authService.isEmployer) {
      this.cvdbHttpService
        .getSearchHistory()
        .pipe(
          takeUntil(
            this.authService.token$.pipe(
              filter(token => !token),
              take(1)
            )
          ),
          catchError(() => of([]))
        )
        .subscribe(res => (this.cvdbStore.searchHistoryList = res))
    }
  }

  private syncSavedSearchList(): void {
    if (this.authService.isEmployer) {
      this.cvdbHttpService
        .getSelectedSearches()
        .pipe(
          take(1),
          catchError(() => of([]))
        )
        .subscribe(res => (this.cvdbStore.selectedSearches = res))
    }
  }

  private handleDislikeAction(id: number): void {
    const listInStore = this.cvdbQuery.getValue()?.searchList || []
    const cvInStore = listInStore.find(cv => cv.resumeId === id) || {}
    const userId = cvInStore?.userId
    const duplicates = listInStore.reduce<number[]>((acc, cv) => (cv.resumeId && cv.userId === userId && cv.resumeId !== id ? [...acc, cv.resumeId] : acc), [])
    const all = [id, ...duplicates]

    if (listInStore.length) {
      all.forEach(resumeId => this.updateDislikedState(resumeId, !!cvInStore.isDisliked))
    }

    const updatedList = listInStore.reduce<CvKnockout[]>((list, res) => [...list, res.resumeId !== id ? res : { ...res, isDisliked: !cvInStore.isDisliked }], [])

    const cvToUpdate = this.cvdbQuery.getValue()?.resumeMap[id]

    if (cvToUpdate) {
      const currentResumeMap = this.cvdbQuery.getValue()?.resumeMap

      if (!listInStore.find(cv => cv.resumeId === id)) {
        this.updateDislikedState(id, !!cvToUpdate.hasDislike)
      }
      this.cvdbStore.set(store => ({
        ...store,
        resumeMap: { ...currentResumeMap, [id]: { ...cvToUpdate, hasDislike: !cvToUpdate.hasDislike } }
      }))
    }

    this.cvdbStore.set(store => ({ ...store, searchList: updatedList }))

    if (!cvInStore.isDisliked) {
      this.analyticsService.sendNextGtmEvent({
        type: ResumeDislikeEvent,
        param: id || ''
      })
    }
  }

  private updateDislikedState(id: number, isCurrentlyDisliked: boolean): void {
    const dislikeCv$ = !isCurrentlyDisliked ? this.resumeService.dislikeCv(id) : this.resumeService.dislikeCvRevocation(id)
    dislikeCv$.pipe(catchError(() => of(false))).subscribe()
  }

  private fetchCvdbTotal$(): Observable<number> {
    return this.cvdbHttpService.totalResumeCounter().pipe(catchError(() => of(0)))
  }

  private getVacancyOffersMap(vacancy: Pick<Vacancy, 'id' | 'title'>): VacancyOffer[] {
    const { MultiUserId = 0, UserName } = this.authService?.user || {}

    const id = this.cvdbQuery.getValue().id
    const resumeMap = this.cvdbQuery.getValue().resumeMap
    const vacancyOfferMap = (id && resumeMap ? resumeMap[id]?.vacancyOffers : []) || []

    const vacancyOffer: CvOfferInfo = {
      multiUserId: +MultiUserId,
      name: UserName,
      addDate: new Date(),
      vacancyName: vacancy.title,
      vacancyId: +vacancy.id
    }

    return vacancyOfferMap.some(item => item.multiUserId === +MultiUserId && item.vacancyId === +vacancy.id) ? vacancyOfferMap : [vacancyOffer, ...vacancyOfferMap]
  }

  private updateListByResume$(resumeId: number): Observable<boolean> {
    return this.resumeService.get({ id: resumeId, markView: true }).pipe(
      take(1),
      map(resume => {
        this.cvdbStore.addResume(resumeId, resume)
        return !!resume
      }),
      catchError(() => of(false))
    )
  }
}
