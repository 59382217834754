/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http'
import { Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { StrictHttpResponse } from '../../strict-http-response'
import { RequestBuilder } from '../../request-builder'

import { StartBotResponse } from '../../models/start-bot-response'

export interface ChatWizardStartCvBot$Plain$Params {}

export function chatWizardStartCvBot$Plain(http: HttpClient, rootUrl: string, params?: ChatWizardStartCvBot$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<StartBotResponse>> {
  const rb = new RequestBuilder(rootUrl, chatWizardStartCvBot$Plain.PATH, 'post')
  if (params) {
  }

  return http.request(rb.build({ responseType: 'text', accept: 'text/plain', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<StartBotResponse>
    })
  )
}

chatWizardStartCvBot$Plain.PATH = '/v1/chatWizard/cv'
