import { VacancyClosingBehavior } from '@alliance/shared/domain-gql'

export enum BehavioursFilterEnum {
  onlyWith = 'onlyWith',
  onlyWithout = 'onlyWithout'
}

export const BehavioursFilterMap: Record<BehavioursFilterEnum, VacancyClosingBehavior[]> = {
  onlyWith: [VacancyClosingBehavior.RepublishForOneMonth, VacancyClosingBehavior.RepublishEveryNextMonth],
  onlyWithout: [VacancyClosingBehavior.Close, VacancyClosingBehavior.CloseAndNotify]
}
