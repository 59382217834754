import { MyVacanciesSortType } from '@alliance/shared/domain-gql'

import { VacanciesListFilter, VacanciesListFilters } from '../../models/vacancies-list'

export const ITEMS_PER_PAGE = 20

export const DEFAULT_PAGE = 1

export const DEFAULT_SORT_TYPE = MyVacanciesSortType.ByStatus

export const PAGE_PARAM_KEY: keyof VacanciesListFilters = 'page'

export const SORT_TYPE_PARAM_KEY: keyof VacanciesListFilters = 'sortType'

export const DEFAULT_FILTER: VacanciesListFilter = {
  cityIds: [],
  closingBehaviors: [],
  employerIds: [],
  keyword: '',
  statuses: []
}

export const DEFAULT_FILTERS: VacanciesListFilters = {
  filter: DEFAULT_FILTER,
  page: DEFAULT_PAGE,
  sortType: DEFAULT_SORT_TYPE
}
