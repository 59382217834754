import { InjectionToken } from '@angular/core'
import { PartialDeep } from '@alliance/shared/models'
import { SeoParamsResponseModel } from '../openapi/model/seo-params-response.model'
import { VacancyListModel } from '../openapi/model/vacancy-list.model'
import { CompanyModel } from '../openapi/model/company.model'
import { ProzoraModel } from '../openapi/model/prozora.model'
import { VacancyModel } from '../openapi/model/vacancy.model'
import { SearchByProfessionCityModel } from '../openapi/model/search-by-profession-city.model'
import { SeoSearchByCompanyAlphabetModel } from '../openapi/model/seo-search-by-company-alphabet.model'
import { SeoCandidatesSearchByModel } from '../openapi/model/seo-candidates-search-by.model'
import { SeoCandidatesModel } from '../openapi/model/candidates.model'
import { CandidatePageModel } from '../openapi/model/candidate-page.model'
import { DictionaryCityAndRegionsItem } from '@alliance/jobseeker/domain'
import { EmployerHomeModel } from '../openapi/model/employer-home.model'

export const SEO_PLATFORM_TOKEN = new InjectionToken('SEO_PLATFORM_TOKEN')

export enum PlatformHosts {
  desktop,
  accountHost
}

export enum SeoPageKeysEnum {
  'home',
  'employer',
  'vacancyList',
  'vacancy',
  'prozora',
  'company',
  'cvwelcome',
  'searchByRubrics',
  'searchByCities',
  'searchByCitiesRegions',
  'searchByProfessionsAlphabet',
  'searchByProfessionsCities',
  'searchByProfessionsCity',
  'searchByCompanyBranch',
  'searchByCompanyAlphabet',
  'candidatesSearchBy',
  'candidates',
  'candidatePage'
}

export interface HomePageSeoParams {
  key: SeoPageKeysEnum.home
}

export interface EmployerPageSeoParams {
  key: SeoPageKeysEnum.employer
  params: EmployerHomeModel
}

export interface VacancyListSeoParams {
  key: SeoPageKeysEnum.vacancyList
  params: VacancyListModel
}

export interface VacancySeoParams {
  key: SeoPageKeysEnum.vacancy
  params: VacancyModel
}

export interface ProzoraSeoParams {
  key: SeoPageKeysEnum.prozora
  params: ProzoraModel
}

export interface CompanySeoParams {
  key: SeoPageKeysEnum.company
  params: CompanyModel
}

export interface CvWelcomeSeoParams {
  key: SeoPageKeysEnum.cvwelcome
}

export interface SearchByRubricsSeoParams {
  key: SeoPageKeysEnum.searchByRubrics
}

export interface SearchByCitiesSeoParams {
  key: SeoPageKeysEnum.searchByCities
}

export interface SearchByCitiesRegionsSeoParams {
  key: SeoPageKeysEnum.searchByCitiesRegions
}

export interface SearchByProfessionsAlphabetSeoParams {
  key: SeoPageKeysEnum.searchByProfessionsAlphabet
}

export interface SearchByProfessionsCitiesSeoParams {
  key: SeoPageKeysEnum.searchByProfessionsCities
}

export interface SearchByProfessionsCitySeoParams {
  key: SeoPageKeysEnum.searchByProfessionsCity
  params: SearchByProfessionCityModel
}

export interface SearchCompanyBranchSeoParams {
  key: SeoPageKeysEnum.searchByCompanyBranch
}

export interface SearchCompanyAlphabetSeoParams {
  key: SeoPageKeysEnum.searchByCompanyAlphabet
  params: SeoSearchByCompanyAlphabetModel
}

export interface CandidatesSearchBySeoParams {
  key: SeoPageKeysEnum.candidatesSearchBy
  params: SeoCandidatesSearchByModel
}

export interface CandidatesSeoParams {
  key: SeoPageKeysEnum.candidates
  params: SeoCandidatesModel
}

export interface CandidatePageSeoParams {
  key: SeoPageKeysEnum.candidatePage
  params: CandidatePageModel
}

export type SeoDictionary =
  | HomePageSeoParams
  | EmployerPageSeoParams
  | VacancyListSeoParams
  | VacancySeoParams
  | ProzoraSeoParams
  | CompanySeoParams
  | CvWelcomeSeoParams
  | SearchByRubricsSeoParams
  | SearchByCitiesSeoParams
  | SearchByCitiesRegionsSeoParams
  | SearchByProfessionsAlphabetSeoParams
  | SearchByProfessionsCitiesSeoParams
  | SearchByProfessionsCitySeoParams
  | SearchCompanyBranchSeoParams
  | SearchCompanyAlphabetSeoParams
  | CandidatesSearchBySeoParams
  | CandidatesSeoParams
  | CandidatePageSeoParams

export type SeoParamsResponse = SeoParamsResponseModel

export type PartialSeoParamsResponse = PartialDeep<SeoParamsResponseModel>

export interface UrlSegmentCityItem extends DictionaryCityAndRegionsItem {
  urlSegment: string
}
