/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum ConversationsFetchType {
  Archived = 'Archived',
  Regular = 'Regular',
  Blacklist = 'Blacklist',
  All = 'All'
}
