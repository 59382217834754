import { MyVacanciesFilterInput } from '@alliance/shared/domain-gql'
import { Provider } from '@angular/core'
import { VacanciesEmployerIdsService } from '../services/vacancies-employer-ids.service'
import { VACANCIES_COUNT_FILTERS, VacanciesCountService } from '../services/vacancies-count/vacancies-count.service'

export const provideVacanciesCount = (params: { filters?: MyVacanciesFilterInput } = {}): Provider[] => [
  VacanciesEmployerIdsService,
  VacanciesCountService,
  { provide: VACANCIES_COUNT_FILTERS, useValue: params.filters }
]
