import { CookieStorage } from '@alliance/shared/storage'
import { Inject, Injectable } from '@angular/core'
import { NAVIGATOR } from '@ng-web-apis/common'
import { Observable, of } from 'rxjs'
import { dataUrlToBlob } from './data-url-to-blob'
import { VideoThumbnailsService } from './video-thumbnails.service'

@Injectable({
  providedIn: 'root'
})
export class HelpersService {
  public constructor(private thumbnailsService: VideoThumbnailsService, private cookieStorage: CookieStorage, @Inject(NAVIGATOR) private readonly navigator: Navigator) {}

  public getCid(): string {
    return (this.cookieStorage.getItem('_ga') || '').split('.').slice(2).join('.')
  }

  public isIos(): boolean {
    return this.navigator && ['iphone', 'ipad', 'ipod'].some(device => this.navigator.userAgent.toLowerCase().indexOf(device) > -1)
  }

  public isAndroid(): boolean {
    return this.navigator && this.navigator.userAgent.toLowerCase().indexOf('android') > -1
  }

  public isMobile(): boolean {
    return this.navigator && /iphone|ipod|ipad|android|mobile|iemobile|blackberry/.test(this.navigator.userAgent.toLowerCase())
  }

  public isApp(): boolean {
    return (
      this.navigator &&
      // custom userAgents uses only in the app (were agreed upon here - https://rabota.slack.com/archives/C0481N30953/p1681299880598359)
      /employer.andriod.app|employer.ios.app/.test(this.navigator.userAgent.toLowerCase())
    )
  }

  public isIosApp(): boolean {
    return (
      this.navigator &&
      // custom userAgents uses only in the app (were agreed upon here - https://rabota.slack.com/archives/C0481N30953/p1681299880598359)
      /employer.ios.app/.test(this.navigator.userAgent.toLowerCase())
    )
  }

  public isSafari(): boolean {
    return this.navigator && this.navigator.userAgent.indexOf('Safari') > -1 && this.navigator.userAgent.indexOf('CriOS') === -1 && this.navigator.userAgent.indexOf('FxiOS') === -1 && this.isIos()
  }

  public getYoutubeVideoId(url: string): string | null {
    // from https://stackoverflow.com/a/8260383
    const regExp = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm
    const match = regExp.exec(url)

    return match && match[3]?.length === 11 ? match[3] : null
  }

  public getYoutubeThumbnail(url: string): Observable<string> {
    const videoId = this.getYoutubeVideoId(url)
    return videoId ? this.thumbnailsService.getYoutubeThumbnail(videoId) : of(url)
  }

  public getEmbedYtUrlForIframe(url: string): string {
    return `https://www.youtube.com/embed/${this.getYoutubeVideoId(url) ?? ''}?autoplay=1`
  }

  public dataURLToBlob(dataURL: string = ''): Blob | null {
    return dataUrlToBlob(dataURL)
  }

  public isOnline(date: string | number | Date): boolean {
    return Date.now() - new Date(date).getTime() < 1000 * 60 * 60 // 1 hour
  }

  public getLastActivityDate(
    dateStart: string | number | Date,
    dateEnd?: string | number | Date
  ): {
    date: number
    periodKey: 'years' | 'months' | 'weeks' | 'days' | 'hours' | 'minutes'
  } {
    const minutes = Math.floor((new Date(dateEnd || Date.now()).getTime() - new Date(dateStart).getTime()) / 1000 / 60)
    const hours = Math.floor(minutes / 60)
    const days = Math.floor(hours / 24)
    const weeks = Math.floor(days / 7)
    const months = Math.floor(days / 30)
    const years = Math.floor(months / 12)

    switch (true) {
      case years > 0:
        return { date: years, periodKey: 'years' }
      case months > 0:
        return { date: months, periodKey: 'months' }
      case weeks > 0:
        return { date: weeks, periodKey: 'weeks' }
      case days > 0:
        return { date: days, periodKey: 'days' }
      case hours > 0:
        return { date: hours, periodKey: 'hours' }
      default: {
        return { date: minutes, periodKey: 'minutes' }
      }
    }
  }
}
