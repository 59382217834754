import { typedObjectKeys } from './typedObject'

export const clearEmptyFields = <T extends object>(obj: T): T =>
  Object.entries(obj).reduce<T>((acc, [field, value]) => {
    if (typeof value === 'boolean') {
      return { ...acc, [field]: value }
    } else if (Array.isArray(value)) {
      return value?.length ? { ...acc, [field]: value } : acc
    } else if (value && typeof value === 'object') {
      const nested = clearEmptyFields(value) as T[keyof T]
      return typedObjectKeys(value)?.length && Object.keys(nested)?.length ? { ...acc, [field]: { ...nested } } : acc
    } else {
      return value ? { ...acc, [field]: value as T[keyof T] } : acc
    }
  }, {} as unknown as T)
