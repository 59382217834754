import { ElementRef, Inject, OnDestroy, Optional, Attribute, Directive } from '@angular/core'
import { SafeObserver } from '../classes/safe-observer'
import { INTERSECTION_ROOT } from '../tokens/intersection-root'
import { rootMarginFactory } from '../utils/root-margin-factory'
import { thresholdFactory } from '../utils/threshold-factory'

@Directive({
  selector: '[allianceIntersectionObserver]',
  exportAs: 'IntersectionObserver'
})
export class IntersectionObserverDirective extends SafeObserver implements OnDestroy {
  private readonly callbacks = new Map<Element, IntersectionObserverCallback>()

  public constructor(
    @Attribute('intersectionRootMargin') rootMargin: string | null,
    @Attribute('intersectionThreshold') threshold: string | null,
    @Inject(INTERSECTION_ROOT) @Optional() root: ElementRef<Element> | null
  ) {
    super(
      entries => {
        this.callbacks.forEach((callback, element) => {
          const filtered = entries.filter(({ target }) => target === element)

          return filtered.length && callback(filtered, this)
        })
      },
      {
        root: root?.nativeElement,
        rootMargin: rootMarginFactory(rootMargin),
        threshold: thresholdFactory(threshold)
      }
    )
  }

  public override observe(
    target: Element,
    callback: IntersectionObserverCallback = (): void => {
      //
    }
  ): void {
    super.observe(target)
    this.callbacks.set(target, callback)
  }

  public override unobserve(target: Element): void {
    super.unobserve(target)
    this.callbacks.delete(target)
  }

  public ngOnDestroy(): void {
    this.callbacks.clear()
    this.disconnect()
  }
}
