import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'

import { LocalStorage } from '@alliance/shared/storage'

const VACANCIES_HOT_RECOMMENDATION_FEATURE_KEY = 'VACANCIES_HOT_RECOMMENDATION_FEATURE_KEY'

const VACANCIES_NEW_VACANCY_FORM_PAGE_FEATURE_KEY = 'VACANCIES_NEW_VACANCY_FORM_PAGE_FEATURE_KEY'

@Injectable({
  providedIn: 'root'
})
export class VacanciesFeatureService {
  public constructor(private readonly storage: LocalStorage) {}

  public isVacanciesHotRecommendationEnabled$(): Observable<boolean> {
    return of(!!this.storage.getItem(VACANCIES_HOT_RECOMMENDATION_FEATURE_KEY))
  }

  public isNewVacanciesFormPageEnabled$(): Observable<boolean> {
    return of(!!this.storage.getItem(VACANCIES_NEW_VACANCY_FORM_PAGE_FEATURE_KEY))
  }
}
