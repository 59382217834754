import { Injectable } from '@angular/core'
import { PartialSeoParamsResponse, SeoDictionary, SeoPageKeysEnum } from './models'
import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { SeoParamsResponseModel } from '../openapi/model/seo-params-response.model'
import { SeoParamsResponseHrefLangModel } from '../openapi/model/seo-params-response-href-lang.model'
import { VacancyDictionaryService } from './dictionaries/vacancy-dictionary.service'
import { VacancyListDictionaryService } from './dictionaries/vacancy-list-dictionary.service'
import { CompanyDictionaryService } from './dictionaries/company-dictionary.service'
import { CvwelcomeDictionaryService } from './dictionaries/cvwelcome-dictionary.service'
import { HomePageDictionaryService } from './dictionaries/home-page-dictionary.service'
import { EmployerHomePageDictionaryService } from './dictionaries/employer-home-page-dictionary.service'
import { SearchByRubricsDictionaryService } from './dictionaries/search-by-rubrics-dictionary.service'
import { ProzoraDictionaryService } from './dictionaries/prozora-dictionary.service'
import { SearchByCitiesDictionaryService } from './dictionaries/search-by-cities-dictionary.service'
import { SearchByCitiesRegionsDictionaryService } from './dictionaries/search-by-cities-regions-dictionary.service'
import { SearchByProfessionsAlphabetDictionaryService } from './dictionaries/search-by-professions-alphabet-dictionary.service'
import { SearchByProfessionsCitiesDictionaryService } from './dictionaries/search-by-professions-cities-dictionary.service'
import { SearchByProfessionsCityDictionaryService } from './dictionaries/search-by-professions-city-dictionary.service'
import { SearchByCompanyBranchDictionaryService } from './dictionaries/search-by-company-branch-dictionary.service'
import { SearchByCompanyAlphabetDictionaryService } from './dictionaries/search-by-company-alphabet-dictionary.service'
import { SearchByRootPageDictionaryService } from './dictionaries/search-by-root-page-dictionary.service'
import { CandidatesDictionaryService } from './dictionaries/candidates-dictionary.service'
import { CandidatePageDictionaryService } from './dictionaries/candidate-page-dictionary.service'
import { LanguageCodesEnum, TranslationService } from '@alliance/shared/translation'

@Injectable({ providedIn: 'root' })
export class SeoDictionaryService {
  private readonly DEFAULT_RESPONSE_SEO_PARAMS: SeoParamsResponseModel = {
    title: '',
    description: '',
    alternateUrl: '',
    canonicalUrl: '',
    noIndexNoFollow: false,
    hrefLang: [
      {
        langCode: SeoParamsResponseHrefLangModel.LangCodeEnum.Uk,
        desktopUrl: '',
        mobileUrl: ''
      },
      {
        langCode: SeoParamsResponseHrefLangModel.LangCodeEnum.Ru,
        desktopUrl: '',
        mobileUrl: ''
      }
    ],
    h1: [],
    jsonLd: {
      desktop: '',
      mobile: ''
    }
  }

  public constructor(
    private translationService: TranslationService,
    private companyDictionary: CompanyDictionaryService,
    private cvWelcomeDictionary: CvwelcomeDictionaryService,
    private homePageDictionary: HomePageDictionaryService,
    private employerHomePageDictionaryService: EmployerHomePageDictionaryService,
    private prozoraDictionary: ProzoraDictionaryService,
    private vacancyDictionary: VacancyDictionaryService,
    private vacancyListDictionary: VacancyListDictionaryService,
    private searchByRubricsDictionary: SearchByRubricsDictionaryService,
    private searchTabCityDictionary: SearchByCitiesDictionaryService,
    private searchTabRegionDictionary: SearchByCitiesRegionsDictionaryService,
    private searchProfAlphabetDictionary: SearchByProfessionsAlphabetDictionaryService,
    private searchProfCityDictionary: SearchByProfessionsCitiesDictionaryService,
    private searchProfCitySelectDictionary: SearchByProfessionsCityDictionaryService,
    private searchCompanyIndustryDictionary: SearchByCompanyBranchDictionaryService,
    private searchCompanyNameDictionary: SearchByCompanyAlphabetDictionaryService,
    private searchByRootPageDictionaryService: SearchByRootPageDictionaryService,
    private candidatesDictionaryService: CandidatesDictionaryService,
    private candidatePageDictionaryService: CandidatePageDictionaryService
  ) {}

  public setActiveLanguage(langCode: LanguageCodesEnum): void {
    this.translationService.setActiveLang(langCode)
  }

  public getSeoParams(params: SeoDictionary): Observable<SeoParamsResponseModel> {
    return this.seoPatternMatching(params).pipe(
      map(seoParams => {
        seoParams.hrefLang = seoParams.hrefLang && seoParams.hrefLang.length === this.DEFAULT_RESPONSE_SEO_PARAMS.hrefLang.length ? seoParams.hrefLang : this.DEFAULT_RESPONSE_SEO_PARAMS.hrefLang

        return {
          ...this.DEFAULT_RESPONSE_SEO_PARAMS,
          ...seoParams,
          h1: (seoParams?.h1 ?? []).filter<string>((item): item is string => !!item),
          hrefLang: seoParams.hrefLang.map(item => {
            const defaultParams = this.DEFAULT_RESPONSE_SEO_PARAMS.hrefLang[0]
            return {
              langCode: item?.langCode || defaultParams.langCode,
              desktopUrl: item?.desktopUrl || defaultParams.desktopUrl,
              mobileUrl: item?.mobileUrl || defaultParams.mobileUrl
            }
          }),
          jsonLd: {
            ...this.DEFAULT_RESPONSE_SEO_PARAMS.jsonLd,
            ...seoParams.jsonLd
          }
        }
      }),
      catchError(() => of(this.DEFAULT_RESPONSE_SEO_PARAMS))
    )
  }

  private seoPatternMatching(params: SeoDictionary): Observable<PartialSeoParamsResponse> {
    switch (params.key) {
      case SeoPageKeysEnum.home:
        return this.homePageDictionary.getParams()
      case SeoPageKeysEnum.employer:
        return this.employerHomePageDictionaryService.getParams(params)
      case SeoPageKeysEnum.vacancyList:
        return this.vacancyListDictionary.getParams(params)
      case SeoPageKeysEnum.vacancy:
        return this.vacancyDictionary.getParams(params)
      case SeoPageKeysEnum.prozora:
        return this.prozoraDictionary.getParams(params)
      case SeoPageKeysEnum.company:
        return this.companyDictionary.getParams(params)
      case SeoPageKeysEnum.cvwelcome:
        return this.cvWelcomeDictionary.getParams()
      case SeoPageKeysEnum.searchByRubrics:
        return this.searchByRubricsDictionary.getParams()
      case SeoPageKeysEnum.searchByCities:
        return this.searchTabCityDictionary.getParams()
      case SeoPageKeysEnum.searchByCitiesRegions:
        return this.searchTabRegionDictionary.getParams()
      case SeoPageKeysEnum.searchByProfessionsAlphabet:
        return this.searchProfAlphabetDictionary.getParams()
      case SeoPageKeysEnum.searchByProfessionsCities:
        return this.searchProfCityDictionary.getParams()
      case SeoPageKeysEnum.searchByProfessionsCity:
        return this.searchProfCitySelectDictionary.getParams(params)
      case SeoPageKeysEnum.searchByCompanyBranch:
        return this.searchCompanyIndustryDictionary.getParams()
      case SeoPageKeysEnum.searchByCompanyAlphabet:
        return this.searchCompanyNameDictionary.getParams(params)
      case SeoPageKeysEnum.candidatesSearchBy:
        return this.searchByRootPageDictionaryService.getParams(params)
      case SeoPageKeysEnum.candidates:
        return this.candidatesDictionaryService.getParams(params)
      case SeoPageKeysEnum.candidatePage:
        return this.candidatePageDictionaryService.getParams(params)
      default:
        return of({})
    }
  }
}
