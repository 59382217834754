import { Inject, Injectable, Optional } from '@angular/core'
import { catchError, finalize, retry } from 'rxjs/operators'
import { MonoTypeOperatorFunction, of, pipe } from 'rxjs'
import { retryWhenStrategy } from '@alliance/shared/utils'
import {
  CityDataLocalized,
  CityRegionDataLocalized,
  DataLocalized,
  DictionaryService as DictionaryRequestService,
  LanguageDataLocalized,
  PopularTagInfo,
  ResourceLocalized
} from '@alliance/jobseeker/data-access'
import { DictionaryProfessionItem, LangCode } from './dictionary.model'
import { DictionaryStore } from './dictionary.store'
import { allCitiesItem, allRubricsItem, allSphereItem } from '../constants/dictionary-all-item'

@Injectable({
  providedIn: 'root'
})
export class DictionaryService {
  private synced: { [key: string]: boolean } = {}

  public constructor(
    private store: DictionaryStore,
    private dictionaryRequestService: DictionaryRequestService,
    @Optional() @Inject(DictionaryRequestService.dictionaryGetCitiesWithRegionsPath) private dictionaryGetCitiesWithRegionsPath: CityDataLocalized[],
    @Optional() @Inject(DictionaryRequestService.dictionaryGetCityPath) private dictionaryGetCities: CityDataLocalized[],
    @Optional() @Inject(DictionaryRequestService.dictionaryGetZaprosPath) private dictionaryGetProfessional: unknown[],
    @Optional() @Inject(DictionaryRequestService.dictionaryGetRubricPath) private dictionaryGetRubricPath: DataLocalized[],
    @Optional() @Inject(DictionaryRequestService.dictionaryGetLanguagePath) private dictionaryGetLanguagePath: LanguageDataLocalized[],
    @Optional() @Inject(DictionaryRequestService.dictionaryGetLanguageSkillPath) private dictionaryGetLanguageSkillPath: DataLocalized[],
    @Optional() @Inject(DictionaryRequestService.dictionaryGetSchedulePath) private dictionaryGetSchedulePath: DataLocalized[],
    @Optional() @Inject(DictionaryRequestService.dictionaryGetBranchPath) private dictionaryGetBranchPath: DataLocalized[],
    @Optional() @Inject(DictionaryRequestService.dictionaryGetExperiencePath) private dictionaryGetExperiencePath: DataLocalized[],
    @Optional() @Inject(DictionaryRequestService.dictionaryGetCvDbExperiencePath) private dictionaryGetCvDbExperiencePath: DataLocalized[],
    @Optional() @Inject(DictionaryRequestService.dictionaryGetEducationPath) private dictionaryGetEducationPath: DataLocalized[],
    @Optional() @Inject(DictionaryRequestService.dictionaryGetFillingTypesPath) private dictionaryGetFillingTypesPath: DataLocalized[],
    @Optional() @Inject(DictionaryRequestService.dictionaryGetSubRubricPath) private dictionaryGetSubRubricPath: DataLocalized[],
    @Optional() @Inject(DictionaryRequestService.dictionaryGetAdditionalPath) private dictionaryGetAdditionalPath: ResourceLocalized[],
    @Optional() @Inject(DictionaryRequestService.dictionaryGetSpheresPath) private dictionaryGetSpheresPath: DataLocalized[],
    @Optional() @Inject(DictionaryRequestService.dictionaryGetKeywordsPath) private dictionaryGetKeywordsPath: string[],
    @Optional() @Inject(DictionaryRequestService.dictionaryGetPopularTagsPath) private dictionaryGetPopularTagsPath: PopularTagInfo[],
    @Optional() @Inject(DictionaryRequestService.dictionaryGetSubRubricPath) private dictionaryGetStatusApplicationExperience: DataLocalized[],
    @Optional() @Inject(DictionaryRequestService.dictionaryGetSubRubricPath) private dictionaryGetStatusApplicationSalary: DataLocalized[],
    @Optional() @Inject(DictionaryRequestService.dictionaryGetCitiesAndRegionsPath) private dictionaryGetCitiesAndRegions: CityRegionDataLocalized[]
  ) {}

  public syncRubricList(): void {
    const key = 'rubricList'
    if (this.store.isEmpty(key) && !this.synced[key]) {
      this.synced[key] = true
      const source = this.dictionaryGetRubricPath ? of(this.dictionaryGetRubricPath) : this.dictionaryRequestService.dictionaryGetRubric().pipe(this.customRetry())
      source.pipe(finalize(() => (this.synced[key] = false))).subscribe(data => (this.store.rubricList = [allRubricsItem, ...data]))
    }
  }

  public syncCityList(): void {
    const key = 'cityList'
    if (this.store.isEmpty(key) && !this.synced[key]) {
      this.synced[key] = true
      const source = this.dictionaryGetCitiesWithRegionsPath ? of(this.dictionaryGetCitiesWithRegionsPath) : this.dictionaryRequestService.dictionaryGetCitiesWithRegions().pipe(this.customRetry())
      source.pipe(finalize(() => (this.synced[key] = false))).subscribe(data => (this.store.cityList = [allCitiesItem, ...data]))
    }
  }

  public syncCityListWithCountry(): void {
    const key = 'cityWithCountry'
    if (this.store.isEmpty(key) && !this.synced[key]) {
      this.synced[key] = true
      const source = this.dictionaryGetCities ? of(this.dictionaryGetCities) : this.dictionaryRequestService.dictionaryGetCity().pipe(this.customRetry())
      source.pipe(finalize(() => (this.synced[key] = false))).subscribe(data => (this.store.cityWithCountries = [allCitiesItem, ...data]))
    }
  }

  public syncCityListAndRegions(): void {
    const key = 'citiesAndRegions'
    if (this.store.isEmpty(key) && !this.synced[key]) {
      this.synced[key] = true
      const source = this.dictionaryGetCitiesAndRegions ? of(this.dictionaryGetCitiesAndRegions) : this.dictionaryRequestService.dictionaryGetCitiesAndRegions().pipe(this.customRetry())
      source.pipe(finalize(() => (this.synced[key] = false))).subscribe(data => (this.store.cityAndRegions = [allCitiesItem, ...data]))
    }
  }

  public syncLanguageList(): void {
    const key = 'languageList'
    if (this.store.isEmpty(key) && !this.synced[key]) {
      this.synced[key] = true
      const source = this.dictionaryGetLanguagePath ? of(this.dictionaryGetLanguagePath) : this.dictionaryRequestService.dictionaryGetLanguage().pipe(this.customRetry())
      source.pipe(finalize(() => (this.synced[key] = false))).subscribe(data => (this.store.languageList = data))
    }
  }

  public syncLanguageSkillList(): void {
    const key = 'languageSkillList'
    if (this.store.isEmpty(key) && !this.synced[key]) {
      this.synced[key] = true
      const source = this.dictionaryGetLanguageSkillPath ? of(this.dictionaryGetLanguageSkillPath) : this.dictionaryRequestService.dictionaryGetLanguageSkill().pipe(this.customRetry())
      source.pipe(finalize(() => (this.synced[key] = false))).subscribe(data => (this.store.languageSkillList = data))
    }
  }

  public syncScheduleList(): void {
    const key = 'scheduleList'
    if (this.store.isEmpty(key) && !this.synced[key]) {
      this.synced[key] = true
      const source = this.dictionaryGetSchedulePath ? of(this.dictionaryGetSchedulePath) : this.dictionaryRequestService.dictionaryGetSchedule().pipe(this.customRetry())
      source.pipe(finalize(() => (this.synced[key] = false))).subscribe(data => (this.store.scheduleList = data))
    }
  }

  public syncBranchList(): void {
    const key = 'branchList'
    if (this.store.isEmpty(key) && !this.synced[key]) {
      this.synced[key] = true
      const source = this.dictionaryGetBranchPath ? of(this.dictionaryGetBranchPath) : this.dictionaryRequestService.dictionaryGetBranch().pipe(this.customRetry())
      source.pipe(finalize(() => (this.synced[key] = false))).subscribe(data => (this.store.branchList = data))
    }
  }

  public syncExperienceList(): void {
    const key = 'experienceList'
    if (this.store.isEmpty(key) && !this.synced[key]) {
      this.synced[key] = true
      const source = this.dictionaryGetExperiencePath ? of(this.dictionaryGetExperiencePath) : this.dictionaryRequestService.dictionaryGetExperience().pipe(this.customRetry())
      source.pipe(finalize(() => (this.synced[key] = false))).subscribe(data => (this.store.experienceList = data))
    }
  }

  public syncCvdbExperienceList(): void {
    const key = 'cvdbExperienceList'
    if (this.store.isEmpty(key) && !this.synced[key]) {
      this.synced[key] = true
      const source = this.dictionaryGetCvDbExperiencePath ? of(this.dictionaryGetCvDbExperiencePath) : this.dictionaryRequestService.dictionaryGetCvDbExperience().pipe(this.customRetry())
      source.pipe(finalize(() => (this.synced[key] = false))).subscribe(data => (this.store.cvdbExperienceList = data))
    }
  }

  public syncEducationList(): void {
    const key = 'educationList'
    if (this.store.isEmpty(key) && !this.synced[key]) {
      this.synced[key] = true
      const source = this.dictionaryGetEducationPath ? of(this.dictionaryGetEducationPath) : this.dictionaryRequestService.dictionaryGetEducation().pipe(this.customRetry())
      source.pipe(finalize(() => (this.synced[key] = false))).subscribe(data => (this.store.educationList = data))
    }
  }

  public syncFillingTypesList(): void {
    const key = 'fillingTypesList'
    if (this.store.isEmpty(key) && !this.synced[key]) {
      this.synced[key] = true
      const source = this.dictionaryGetFillingTypesPath ? of(this.dictionaryGetFillingTypesPath) : this.dictionaryRequestService.dictionaryGetFillingTypes().pipe(this.customRetry())
      source.pipe(finalize(() => (this.synced[key] = false))).subscribe(data => (this.store.fillingTypesList = data))
    }
  }

  public syncSubRubricList(): void {
    const key = 'subRubricList'
    if (this.store.isEmpty(key) && !this.synced[key]) {
      this.synced[key] = true
      const source = this.dictionaryGetSubRubricPath ? of(this.dictionaryGetSubRubricPath) : this.dictionaryRequestService.dictionaryGetSubRubric().pipe(this.customRetry())
      source.pipe(finalize(() => (this.synced[key] = false))).subscribe(data => (this.store.subRubricList = data))
    }
  }

  public syncProfessionsList(lang: LangCode): void {
    const key = 'professionsList'
    if (this.store.isEmpty(key) && !this.synced[key]) {
      this.synced[key] = true
      const source = this.dictionaryGetProfessional ? of(this.dictionaryGetProfessional) : this.dictionaryRequestService.dictionaryGetZapros(lang === 'ua')
      source.pipe(finalize(() => (this.synced[key] = false))).subscribe((data: unknown) => {
        this.store.professionsList = Array.isArray(data) ? (data as DictionaryProfessionItem[]) : []
      })
    }
  }

  public syncAdditionalList(): void {
    const key = 'additionalList'
    if (this.store.isEmpty(key) && !this.synced[key]) {
      this.synced[key] = true
      const source = this.dictionaryGetAdditionalPath ? of(this.dictionaryGetAdditionalPath) : this.dictionaryRequestService.dictionaryGetAdditional().pipe(this.customRetry())
      source.pipe(finalize(() => (this.synced[key] = false))).subscribe(data => (this.store.additionalList = data))
    }
  }

  public syncSphereList(): void {
    const key = 'sphereList'
    if (this.store.isEmpty(key) && !this.synced[key]) {
      this.synced[key] = true
      const source = this.dictionaryGetSpheresPath ? of(this.dictionaryGetSpheresPath) : this.dictionaryRequestService.dictionaryGetSpheres().pipe(this.customRetry())
      source.pipe(finalize(() => (this.synced[key] = false))).subscribe(data => (this.store.sphereList = [allSphereItem, ...data]))
    }
  }

  public syncKeywordsList(): void {
    const key = 'keywordsList'
    if (this.store.isEmpty(key) && !this.synced[key]) {
      this.synced[key] = true
      const source = this.dictionaryGetKeywordsPath ? of(this.dictionaryGetKeywordsPath) : this.dictionaryRequestService.dictionaryGetKeywords().pipe(this.customRetry())
      source.pipe(finalize(() => (this.synced[key] = false))).subscribe(data => (this.store.keywordsList = data))
    }
  }

  public syncPopularKeywordsList(): void {
    const key = 'popularKeywordsList'
    if (this.store.isEmpty(key) && !this.synced[key]) {
      this.synced[key] = true
      const source = this.dictionaryGetPopularTagsPath ? of(this.dictionaryGetPopularTagsPath) : this.dictionaryRequestService.dictionaryGetPopularTags().pipe(this.customRetry())
      source.pipe(finalize(() => (this.synced[key] = false))).subscribe(data => (this.store.popularKeywordsList = data))
    }
  }

  public syncStatusApplicationExperience(): void {
    const key = 'statusApplicationExperience'
    if (this.store.isEmpty(key) && !this.synced[key]) {
      this.synced[key] = true
      const source = this.dictionaryGetStatusApplicationExperience
        ? of(this.dictionaryGetStatusApplicationExperience)
        : this.dictionaryRequestService.dictionaryGetExperienceStatus().pipe(this.customRetry())
      source.pipe(finalize(() => (this.synced[key] = false))).subscribe(data => (this.store.popularStatusApplicationExperience = data))
    }
  }

  public syncStatusApplicationSalary(): void {
    const key = 'statusApplicationSalary'
    if (this.store.isEmpty(key) && !this.synced[key]) {
      this.synced[key] = true
      const source = this.dictionaryGetStatusApplicationSalary ? of(this.dictionaryGetStatusApplicationSalary) : this.dictionaryRequestService.dictionaryGetSalaryStatus().pipe(this.customRetry())
      source.pipe(finalize(() => (this.synced[key] = false))).subscribe(data => (this.store.popularStatusApplicationSalary = data))
    }
  }

  protected customRetry<T>(): MonoTypeOperatorFunction<T[]> {
    return pipe(
      retry({ delay: retryWhenStrategy() }),
      catchError(() => of([]))
    )
  }
}
