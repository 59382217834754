import { AlertNotificationStreamMessageType } from '../models/alert-notification-stream-message-type'
import { NotificationStreamMessageDetailTypeEnum } from '../services/notification-stream/notification-stream-message-type'

export const ALERT_NOTIFICATION_STREAM_MESSAGE_TYPE_LIST: AlertNotificationStreamMessageType[] = [
  NotificationStreamMessageDetailTypeEnum.NewApply,
  NotificationStreamMessageDetailTypeEnum.PublicationEnded,
  NotificationStreamMessageDetailTypeEnum.VacancyEndingLeftDays,
  NotificationStreamMessageDetailTypeEnum.UnpaidOrderReceived,
  NotificationStreamMessageDetailTypeEnum.VacancyBannedByModerator
]
