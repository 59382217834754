/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { BaseService } from '../base-service'
import { TagsSuggesterDataAccessConfiguration } from '../tags-suggester-data-access-configuration'
import { StrictHttpResponse } from '../strict-http-response'

import { generateDescriptionGenerateDescriptionPost } from '../fn/cv/generate-description-generate-description-post'
import { generateDescriptionGenerateDescriptionPost_1 } from '../fn/cv/generate-description-generate-description-post-1'
import { GenerateDescriptionGenerateDescriptionPost_1$Params } from '../fn/cv/generate-description-generate-description-post-1'
import { GenerateDescriptionGenerateDescriptionPost$Params } from '../fn/cv/generate-description-generate-description-post'
import { JobOutput } from '../models/job-output'

@Injectable({ providedIn: 'root' })
export class TagsSuggesterCvService extends BaseService {
  constructor(config: TagsSuggesterDataAccessConfiguration, http: HttpClient) {
    super(config, http)
  }

  /** Path part for operation `generateDescriptionGenerateDescriptionPost()` */
  static readonly GenerateDescriptionGenerateDescriptionPostPath = '/generate-description/'

  /**
   * Generate detailed job description based on job title and duties.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateDescriptionGenerateDescriptionPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateDescriptionGenerateDescriptionPost$Response(params: GenerateDescriptionGenerateDescriptionPost$Params, context?: HttpContext): Observable<StrictHttpResponse<JobOutput>> {
    return generateDescriptionGenerateDescriptionPost(this.http, this.rootUrl, params, context)
  }

  /**
   * Generate detailed job description based on job title and duties.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `generateDescriptionGenerateDescriptionPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateDescriptionGenerateDescriptionPost(params: GenerateDescriptionGenerateDescriptionPost$Params, context?: HttpContext): Observable<JobOutput> {
    return this.generateDescriptionGenerateDescriptionPost$Response(params, context).pipe(map((r: StrictHttpResponse<JobOutput>): JobOutput => r.body))
  }

  /** Path part for operation `generateDescriptionGenerateDescriptionPost_1()` */
  static readonly GenerateDescriptionGenerateDescriptionPost_1Path = '/generate-description'

  /**
   * Generate detailed job description based on job title and duties.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateDescriptionGenerateDescriptionPost_1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateDescriptionGenerateDescriptionPost_1$Response(params: GenerateDescriptionGenerateDescriptionPost_1$Params, context?: HttpContext): Observable<StrictHttpResponse<JobOutput>> {
    return generateDescriptionGenerateDescriptionPost_1(this.http, this.rootUrl, params, context)
  }

  /**
   * Generate detailed job description based on job title and duties.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `generateDescriptionGenerateDescriptionPost_1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateDescriptionGenerateDescriptionPost_1(params: GenerateDescriptionGenerateDescriptionPost_1$Params, context?: HttpContext): Observable<JobOutput> {
    return this.generateDescriptionGenerateDescriptionPost_1$Response(params, context).pipe(map((r: StrictHttpResponse<JobOutput>): JobOutput => r.body))
  }
}
