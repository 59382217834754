/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http'
import { Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { StrictHttpResponse } from '../../strict-http-response'
import { RequestBuilder } from '../../request-builder'

import { TopTagsResponse } from '../../models/top-tags-response'

export interface TopTagsByNameEndpointTopNameGet$Params {
  tag: string
  'accept-language'?: string
}

export function topTagsByNameEndpointTopNameGet(
  http: HttpClient,
  rootUrl: string,
  params: TopTagsByNameEndpointTopNameGet$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<TopTagsResponse>> {
  const rb = new RequestBuilder(rootUrl, topTagsByNameEndpointTopNameGet.PATH, 'get')
  if (params) {
    rb.query('tag', params.tag, {})
    rb.header('accept-language', params['accept-language'], {})
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<TopTagsResponse>
    })
  )
}

topTagsByNameEndpointTopNameGet.PATH = '/top/name/'
