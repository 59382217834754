import { NotificationsCountService, NotificationStreamMessage, NotificationStreamMessageDetailTypeEnum, NotificationStreamService } from '@alliance/employer/notification/domain'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class NotificationApiService {
  public constructor(private readonly notificationsCountService: NotificationsCountService, private readonly notificationStreamService: NotificationStreamService) {}

  public getUnreadCount$(): Observable<number | null> {
    return this.notificationsCountService.select('unreadCount')
  }

  public getNotificaitonStream$(types?: NotificationStreamMessageDetailTypeEnum[]): Observable<NotificationStreamMessage> {
    return this.notificationStreamService.getNotificationStream$(types)
  }
}
