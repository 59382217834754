import { Injectable } from '@angular/core'
import { GetVacancyBenefitsGQL } from './base-form-benefits.generated'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { getNonNullableItems } from '@alliance/shared/utils'
import { VacancyDictionaryBenefit } from '@alliance/shared/domain-gql'

@Injectable()
export class VacancyBenefitsService {
  public constructor(private getVacancyBenefitsGQL: GetVacancyBenefitsGQL) {}

  public getAll(): Observable<VacancyDictionaryBenefit[]> {
    return this.getVacancyBenefitsGQL.fetch({}, { fetchPolicy: 'cache-first' }).pipe(map(response => getNonNullableItems<VacancyDictionaryBenefit>(response?.data?.vacancyBenefits ?? [])))
  }
}
