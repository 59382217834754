import { Injectable } from '@angular/core'
import { SearchKeywordItemFragment, SearchKeywordsGQL } from './search-keywords-dictionary.generated'
import { map, Observable, of } from 'rxjs'
import { catchError, retryWhen } from 'rxjs/operators'
import { retryWhenStrategy } from '@alliance/shared/utils'
import { SearchKeywordWhereInput, TranslationLanguageEnum } from '../../types-generated'

@Injectable({
  providedIn: 'root'
})
export class SearchKeywordsDictionaryService {
  public constructor(private searchKeywordsGQL: SearchKeywordsGQL) {}

  public getSearchKeywordsDictionaryList$(where: SearchKeywordWhereInput, languages: TranslationLanguageEnum[]): Observable<SearchKeywordItemFragment[]> {
    return this.searchKeywordsGQL
      .fetch(
        {
          where,
          languages
        },
        { fetchPolicy: 'cache-first' }
      )
      .pipe(
        map(({ data: { searchKeywords } }) => (searchKeywords?.nodes || []).reduce<SearchKeywordItemFragment[]>((acc, keyword) => (keyword ? [...acc, keyword] : acc), [])),
        retryWhen(retryWhenStrategy()),
        catchError(() => of([]))
      )
  }
}
