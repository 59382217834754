import { Router } from '@angular/router'
import { FactoryProvider, inject } from '@angular/core'

import { GQL_AUTH_ERROR_HANDLER_TOKEN, GqlAuthErrorHandler, USER_IS_NOT_AUTHENTICATED_ERROR_CODE } from '@alliance/shared/gql/utils'

import { EMPLOYER_APOLLO_CLIENT_NAME } from '../constants'

export const GQL_AUTH_ERROR_HANDLER_PROVIDER: FactoryProvider = {
  provide: GQL_AUTH_ERROR_HANDLER_TOKEN,
  useFactory: (): GqlAuthErrorHandler => {
    const router = inject(Router)

    return {
      clientName: EMPLOYER_APOLLO_CLIENT_NAME,
      errorsCodes: [USER_IS_NOT_AUTHENTICATED_ERROR_CODE],
      onErrorCallback: (): void => {
        router.navigate(['/auth/login'])
      }
    }
  }
}
