import { Injectable } from '@angular/core'
import { catchError, filter as filterOperator, map, Observable, of, switchMap, retry, finalize, distinctUntilChanged, tap } from 'rxjs'

import { RxStateService } from '@alliance/shared/models'
import { AuthService } from '@alliance/shared/auth/api'
import { EmployerAccessByRightsEnum, EmployerRightsService } from '@alliance/employer/gql-domain'
import { deepEqual, getNonNullableItems, retryWhenStrategy } from '@alliance/shared/utils'

import { GetVacanciesListAdditionalDataGQL, GetVacanciesListGQL, GetVacanciesListQueryVariables, VacanciesListItemFragment } from './vacancies-list.generated'
import { VacancyAdditionalInfoMap, VacanciesFiltersCounts, PartialVacanciesListFilters, VacanciesListFilters } from '../../models/vacancies-list'
import { mergeVacanciesListFilters, DEFAULT_FILTERS, DEFAULT_PAGE, ITEMS_PER_PAGE } from '../../constants/vacancies-list'
import { GetVacanciesFiltesCountsGQL } from '../vacancies-filters/vacancies-filters.generated'

@Injectable({ providedIn: 'root' })
export class VacanciesListService extends RxStateService<{
  vacanciesList: VacanciesListItemFragment[]
  filters: VacanciesListFilters | null
  filterCounts: VacanciesFiltersCounts | null
  hasRightToViewSomeoneElseVacancies: boolean
  isVacanciesListLoading: boolean
  totalVacanciesCount: number
  filteredVacanciesCount: number
  isInitiated: boolean
  vacancyAdditionalInfoMap: VacancyAdditionalInfoMap
}> {
  public constructor(
    private readonly getVacanciesListGQL: GetVacanciesListGQL,
    private readonly getVacanciesListAdditionalDataGQL: GetVacanciesListAdditionalDataGQL,
    private readonly getVacanciesFiltesCountsGQL: GetVacanciesFiltesCountsGQL,
    private readonly authService: AuthService,
    private readonly employerRightsService: EmployerRightsService
  ) {
    super()

    this.initState({
      vacanciesList: this.vacanciesList$,
      filters: null,
      filterCounts: this.filterCounts$,
      hasRightToViewSomeoneElseVacancies: this.employerRightsService.hasRightTo$(EmployerAccessByRightsEnum.viewSomeoneElseVacancies),
      isVacanciesListLoading: true,
      totalVacanciesCount: 0,
      filteredVacanciesCount: 0,
      isInitiated: false,
      vacancyAdditionalInfoMap: this.vacancyAdditionalInfoMap$
    })
  }

  private get vacanciesList$(): Observable<VacanciesListItemFragment[]> {
    return this.select('hasRightToViewSomeoneElseVacancies').pipe(
      switchMap(hasRightToViewSomeoneElseVacancies =>
        this.select('filters').pipe(
          filterOperator(Boolean),
          tap(() => {
            if (!this.get('isVacanciesListLoading')) {
              this.set({ isVacanciesListLoading: true })
            }
          }),
          switchMap(filters =>
            this.getVacanciesListGQL.fetch(this.getVacanciesListQueryVariabels(filters, hasRightToViewSomeoneElseVacancies), { fetchPolicy: 'network-only' }).pipe(
              tap(({ data }) =>
                this.set({
                  totalVacanciesCount: data?.totalVacancies?.totalCount || 0,
                  filteredVacanciesCount: data?.myVacancies?.totalCount || 0
                })
              ),
              map(({ data }) => getNonNullableItems(data?.myVacancies?.edges?.map(edges => edges?.node || null) ?? [])),
              retry({ delay: retryWhenStrategy() }),
              finalize(() => this.set({ isVacanciesListLoading: false })),
              catchError(() => of([]))
            )
          )
        )
      )
    )
  }

  private get vacancyAdditionalInfoMap$(): Observable<VacancyAdditionalInfoMap> {
    return this.select('hasRightToViewSomeoneElseVacancies').pipe(
      switchMap(hasRightToViewSomeoneElseVacancies =>
        this.select('filters').pipe(
          filterOperator(Boolean),
          switchMap(filters =>
            this.getVacanciesListAdditionalDataGQL.fetch(this.getVacanciesListQueryVariabels(filters, hasRightToViewSomeoneElseVacancies), { fetchPolicy: 'no-cache' }).pipe(
              map(({ data }) =>
                (data?.myVacancies?.items || []).reduce<VacancyAdditionalInfoMap>((acc, item) => {
                  if (item?.id) {
                    acc[item.id] = item
                  }
                  return acc
                }, {})
              ),
              map(vacancyAdditionalInfoMap => ({ ...this.get('vacancyAdditionalInfoMap'), ...vacancyAdditionalInfoMap })),
              retry({ delay: retryWhenStrategy() }),
              catchError(() => of({}))
            )
          )
        )
      )
    )
  }

  private get filterCounts$(): Observable<VacanciesFiltersCounts | null> {
    return this.select('filters').pipe(
      filterOperator(Boolean),
      map(filters => filters.filter),
      distinctUntilChanged((prev, curr) => deepEqual(prev, curr)),
      switchMap(filter =>
        this.getVacanciesFiltesCountsGQL.fetch(filter, { fetchPolicy: 'no-cache' }).pipe(
          map(({ data }) => {
            const myVacanciesByCity = getNonNullableItems(data?.myVacanciesByCity ?? [])
            const myVacanciesByEmployer = getNonNullableItems(data?.myVacanciesByEmployer ?? [])
            const myVacanciesByStatus = getNonNullableItems(data?.myVacanciesByStatus ?? [])

            return { myVacanciesByCity, myVacanciesByEmployer, myVacanciesByStatus }
          }),
          retry({ delay: retryWhenStrategy() }),
          catchError(() => of(null))
        )
      )
    )
  }

  public init(filters?: PartialVacanciesListFilters): void {
    const { isInitiated } = this.get() || {}

    const currentFilters = mergeVacanciesListFilters(DEFAULT_FILTERS, filters || {})

    if (!isInitiated) {
      this.set({ isInitiated: true, filters: currentFilters })
      return
    }

    this.set({ filters: currentFilters })
  }

  public setFilters(inputFilters: PartialVacanciesListFilters): void {
    const { filters, isVacanciesListLoading } = this.get() || {}

    if (!filters || isVacanciesListLoading) {
      return
    }

    const currentFilters = mergeVacanciesListFilters(filters, inputFilters)

    this.set({ filters: { ...currentFilters, ...((inputFilters.filter || inputFilters.sortType) && { page: DEFAULT_PAGE }) } })
  }

  public resetFilters(): void {
    this.set({ filters: DEFAULT_FILTERS })
  }

  public refetchVacanciesList(): void {
    this.hold(this.vacanciesList$, vacanciesList => this.set({ vacanciesList }))
    this.hold(this.vacancyAdditionalInfoMap$, vacancyAdditionalInfoMap => this.set({ vacancyAdditionalInfoMap }))
  }

  public refetchFilterCounts(): void {
    this.hold(this.filterCounts$, filterCounts => this.set({ filterCounts }))
  }

  private getVacanciesListQueryVariabels(filters: VacanciesListFilters, hasRightToViewSomeoneElseVacancies: boolean): GetVacanciesListQueryVariables {
    const { filter, sortType, page } = filters

    const employerIds = hasRightToViewSomeoneElseVacancies ? filter.employerIds : [`${this.authService.user?.MultiUserId?.toString() ?? ''}`]

    return {
      first: ITEMS_PER_PAGE,
      after: btoa(`${(page - 1) * ITEMS_PER_PAGE}`),
      statuses: filter.statuses,
      employerIds,
      closingBehaviors: filter.closingBehaviors,
      cityIds: filter.cityIds,
      keyword: filter.keyword,
      sortType
    }
  }
}
