import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { catchError, filter, map } from 'rxjs/operators'

import { CompanyStateEnum } from '@alliance/shared/domain-gql'
import { RxStateService } from '@alliance/shared/models'
import { getNonNullableItems } from '@alliance/shared/utils'
import { NotificationApiService, NotificationStreamMessageDetailTypeEnum } from '@alliance/employer/notification/api'

import { GenerateQesVerificationLinkGQL } from './company-verification.generated'
import { CompanyVerificationsState } from '../../models/company-verification-state.interface'
import { FailureGenerateQesVerificationLinkResult, GenerateQesVerificationLinkResult, SuccessGenerateQesVerificationLinkResult } from '../../models'
import { getVerificationByQes, getVerificationStatusByCompanyData } from '../../constants'
import { CompanyDataService } from '../company-data/company-data.service'

@Injectable({ providedIn: 'root' })
export class CompanyVerificationService extends RxStateService<{
  companyVerificationsState: CompanyVerificationsState
  isVerified: boolean
}> {
  public isVerified$: Observable<boolean> = this.select('isVerified')

  public constructor(
    private readonly generateQesVerificationLinkGQL: GenerateQesVerificationLinkGQL,
    private readonly notificationApiService: NotificationApiService,
    private readonly companyDataService: CompanyDataService
  ) {
    super()

    this.initState({
      companyVerificationsState: this.companyVerificationsState$,
      isVerified: this.companyDataService.companyData$.pipe(map(d => !!d && (d.companyState === CompanyStateEnum.Validated || d.companyState === CompanyStateEnum.Mega)))
    })

    this.hold(this.notificationApiService.getNotificaitonStream$([NotificationStreamMessageDetailTypeEnum.CompanyStateChanged]), () => this.refetchCompanyData())
  }

  private get companyVerificationsState$(): Observable<CompanyVerificationsState> {
    return this.companyDataService.companyData$.pipe(
      filter(Boolean),
      map(companyData => {
        const state = companyData.companyState ?? null

        const expectationOfActivationReasons = state === CompanyStateEnum.ExpectationOfActivation ? getNonNullableItems(companyData.verificationBlockingReasons) : [] // is ExpectationOfActivation needed

        const blockingReasons = expectationOfActivationReasons.length ? expectationOfActivationReasons : null

        const isCompanyNotVerified = state === CompanyStateEnum.Validated && !(companyData.edrpouVerification?.edrpou || companyData.edrpouVerification?.edrpouFile || companyData.companyUrl)

        const isAwaitingVerification = state === CompanyStateEnum.Validated && !!(companyData.edrpouVerification?.edrpou && companyData.edrpouVerification?.edrpouFile)

        const verifiedByAdminState = companyData.adminVerification?.state ?? null

        const verificationByQES = getVerificationByQes(companyData.qesVerification, blockingReasons)

        return { state, blockingReasons, isCompanyNotVerified, isAwaitingVerification, verifiedByAdminState, verificationByQES }
      }),

      map(companyDataWithoutVerificationStatus => ({ ...companyDataWithoutVerificationStatus, verificationStatus: getVerificationStatusByCompanyData(companyDataWithoutVerificationStatus) }))
    )
  }

  public refetchCompanyData(): void {
    this.companyDataService.refetchCompanyData()
  }

  public generateQesVerificationLink$(): Observable<GenerateQesVerificationLinkResult | null> {
    return this.generateQesVerificationLinkGQL.mutate().pipe(
      map(({ data }) => {
        const qesLink = data?.generateQesVerificationLink?.url
        const error = data?.generateQesVerificationLink?.error

        if (qesLink && !error) {
          const success: SuccessGenerateQesVerificationLinkResult = { isSuccess: true, qesLink }

          return success
        }

        if (error) {
          const failure: FailureGenerateQesVerificationLinkResult = { isSuccess: false, error }

          return failure
        }

        return null
      }),
      catchError(() => of(null))
    )
  }
}
