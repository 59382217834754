import { GqlRestrictedByRightErrorService } from '@alliance/employer/interceptors/restricted-by-right-middleware'

import { Environment } from '@alliance/shared/environment'
import { LoggerMiddlewareService } from '@alliance/shared/gql/logger'
import { GetApolloClientMetricsService, GqlErrorHandlerMiddleware } from '@alliance/shared/gql/utils'
import { GqlLanguageMiddlewareService } from '@alliance/shared/translation'

import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { from, InMemoryCache } from '@apollo/client/core'
import { Apollo } from 'apollo-angular'
import { HttpLink } from 'apollo-angular/http'

import { EMPLOYER_APOLLO_CLIENT_NAME } from './constants'
import { GQL_AUTH_ERROR_HANDLER_PROVIDER } from './providers'

@NgModule({
  imports: [],
  exports: [CommonModule, HttpClientModule],
  providers: [GqlLanguageMiddlewareService, GqlRestrictedByRightErrorService, GQL_AUTH_ERROR_HANDLER_PROVIDER]
})
export class EmployerGqlDomainModule {
  public constructor(
    private apollo: Apollo,
    private httpLink: HttpLink,
    private environment: Environment,
    private langMiddlewareService: GqlLanguageMiddlewareService,
    private gqlLoggerMiddleware: LoggerMiddlewareService,
    private gqlRestrictedByRightErrorService: GqlRestrictedByRightErrorService,
    private getApolloClientMetricsService: GetApolloClientMetricsService,
    private gqlErrorHandlerMiddleware: GqlErrorHandlerMiddleware
  ) {
    if (!this.apollo.use(EMPLOYER_APOLLO_CLIENT_NAME)) {
      this.createClient()
    }
  }

  private createClient(): void {
    const cache = new InMemoryCache({
      typePolicies: {
        CatalogCvDbService: { keyFields: false },
        Company: { keyFields: false, merge: true },
        BasicQuestionItem: { keyFields: ['id', 'name'] },
        QuestionnaireItem: { keyFields: false },
        QuestionnaireSelectItem: { keyFields: false },
        QuestionnaireEssayItem: { keyFields: false },
        QuestionnaireExperienceItem: { keyFields: false },
        QuestionnaireLanguageItem: { keyFields: false },
        AnswerOption: { keyFields: false },
        AnswerOptionSuggest: { keyFields: false },
        EmployerEventSnapshot: { keyFields: false },
        BannerQueries: { keyFields: false, merge: true },
        ApplyProfResume: { keyFields: false },
        PromoCode: { keyFields: false, merge: true }
        // TODO: uncomment when old applies deleted
        // Apply: { keyFields: false, merge: true }
      }
    })

    const link = from([
      this.langMiddlewareService.getMiddleware(),
      this.gqlLoggerMiddleware.operationsLogger(),
      this.gqlLoggerMiddleware.onErrorLogger(),
      this.gqlErrorHandlerMiddleware.onGqlError(),
      this.gqlErrorHandlerMiddleware.onUserAuthenticationError(),
      this.gqlRestrictedByRightErrorService.onRestrictedByRightError(EMPLOYER_APOLLO_CLIENT_NAME),
      this.httpLink.create({
        uri: this.environment.graphqlApi,
        withCredentials: false
      })
    ])

    const context = { clientName: EMPLOYER_APOLLO_CLIENT_NAME }

    this.apollo.createNamed(EMPLOYER_APOLLO_CLIENT_NAME, {
      link,
      cache,
      ...this.getApolloClientMetricsService.apolloClientMetrics,
      defaultOptions: {
        watchQuery: { context, errorPolicy: 'all' },
        mutate: { context },
        query: { context }
      }
    })
  }
}
