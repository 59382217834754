export interface ResumeContactsFilters {
  cityIds: number[]
  rubricIds: number[]
}

export enum ResumeContactsScenariosEnum {
  action = 'action',
  activation = 'activation',
  activationWithCvdbServices = 'activationWithCvdbServices',
  activationLimited = 'activationLimited',
  offer = 'offer',
  offerWithCvdbServices = 'offerWithCvdbServices',
  offerLimited = 'offerLimited',
  // open contacts V2
  // On new endpoint for open contacts we have new enum with statuses that comes from API
  CompanyIsRestricted = 'CompanyIsRestricted'
}
