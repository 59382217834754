import { Directive, Output } from '@angular/core'

import { IntersectionObserveeService } from '../services/intersection-observee.service'

@Directive({
  selector: '[allianceIntersectionObservee]',
  providers: [IntersectionObserveeService]
})
export class IntersectionObserveeDirective {
  @Output() public allianceIntersectionObservee

  public constructor(private intersectionObserveeService: IntersectionObserveeService) {
    this.allianceIntersectionObservee = this.intersectionObserveeService
  }
}
