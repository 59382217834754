const RESTRICTED_ROBOTS_LIST: string[] = [
  // SEO контора — отлучить?
  // 'NetpeakSpiderBot',
  // pracuj automated tools
  'Bot O7@MuV8JjoaQ#c$4VC62'
]

const ALLOWED_ROBOTS_REGEX = new RegExp(
  [
    /bot/,
    /spider/,
    /crawl/,
    /APIs-Google/,
    /AdsBot/,
    /Googlebot/,
    /mediapartners/,
    /Google Favicon/,
    /FeedFetcher/,
    /Google-Read-Aloud/,
    /DuplexWeb-Google/,
    /googleweblight/,
    /ia_archiver/,
    /facebook/,
    /instagram/,
    /pinterest/,
    /reddit/,
    /slack/,
    /twitter/,
    /whatsapp/,
    /youtube/,
    /telegram/,
    /viber/,
    /Google-InspectionTool/
  ]
    .map(r => r.source)
    .join('|'),
  'i' // case insensitive
)

export const detectRobot = (userAgent: string): boolean => {
  if (!userAgent?.trim()) {
    return false
  }

  if (RESTRICTED_ROBOTS_LIST.some(restricted => userAgent.includes(restricted))) {
    return false
  }

  return ALLOWED_ROBOTS_REGEX.test(userAgent)
}
