import { Injectable } from '@angular/core'

import { ExperimentsService } from '@alliance/shared/utils'
import { AuthService } from '@alliance/shared/auth/api'
import { map, Observable } from 'rxjs'

const REDIRECT_TO_LOGIN_CID = [0, 1, 2, 3, 4]

type CvdbAccessExperimentActionType = 'redirectToLoginCvdbAccessExperiment' | 'allowCvdbAccessExperiment' | 'allowCvdbAccess'

@Injectable({
  providedIn: 'root'
})
export class CvdbAccessExperimentService {
  public constructor(private readonly authService: AuthService, private readonly experimentsService: ExperimentsService) {}

  public get cvdbAccessExperimentAction$(): Observable<CvdbAccessExperimentActionType> {
    return this.authService.token$.pipe(map(token => !!token)).pipe(
      map(isLoggedIn => {
        if (isLoggedIn) {
          return 'allowCvdbAccess'
        }

        if (this.experimentsService.isInExperimentByCid(REDIRECT_TO_LOGIN_CID)) {
          return 'redirectToLoginCvdbAccessExperiment'
        }

        return 'allowCvdbAccessExperiment'
      })
    )
  }
}
