import { RxStateService } from '@alliance/shared/models'
import { getNonNullableItems } from '@alliance/shared/utils'
import { Injectable } from '@angular/core'
import { QueryRef } from 'apollo-angular'
import { Observable, of } from 'rxjs'
import { catchError, filter, map, startWith, take } from 'rxjs/operators'
import { DEFAULT_PAGE_INFO, ITEMS_PER_PAGE } from '../../constants/constants'
import { NotificationItem } from '../../models/notification-item-type'
import { mapToNotificationItem } from '../../utils/map-to-notification-item'
import { GetNotificationsGQL, GetNotificationsQuery, GetNotificationsQueryVariables, NotificationsListPageInfoFragment } from './notification-list.generated'

@Injectable({ providedIn: 'root' })
export class NotificationListService extends RxStateService<{
  notifications: NotificationItem[]
  totalCount: number | null
  pageInfo: NotificationsListPageInfoFragment | null
  hasNextPage: boolean
  isFirstLoad: boolean
  isLoading: boolean
  isEmpty: boolean
  fetchMoreLoading: boolean
}> {
  private notificationsQueryRef: QueryRef<GetNotificationsQuery, GetNotificationsQueryVariables> | undefined

  public constructor(private readonly getNotificationsGQL: GetNotificationsGQL) {
    super()

    this.initState({
      notifications: this.getNotifications$(),
      totalCount: null,
      pageInfo: null,
      hasNextPage: this.select('pageInfo').pipe(map(info => !!info?.hasNextPage)),
      isFirstLoad: this.select('notifications').pipe(
        map(() => false),
        take(1),
        startWith(true)
      ),
      isLoading: true,
      isEmpty: this.select('notifications').pipe(map(list => !list.length)),
      fetchMoreLoading: false
    })
  }

  public fetchMore(inputVariables: GetNotificationsQueryVariables | null = null): void {
    const { pageInfo, isLoading } = this.get()

    if (!pageInfo || !pageInfo.hasNextPage || isLoading) {
      return
    }

    const variables = inputVariables || this.getNotificationsQueryVariables(ITEMS_PER_PAGE, pageInfo.endCursor)

    this.set({ fetchMoreLoading: true })

    this.getNotificationsQueryRef().fetchMore({
      variables,
      updateQuery: (previousResult, { fetchMoreResult }) => {
        this.set({ fetchMoreLoading: false })

        if (!fetchMoreResult?.notifications) {
          return previousResult
        }

        const previousNodes = getNonNullableItems<NotificationItem>((previousResult.notifications?.nodes ?? []).map(mapToNotificationItem))
        const fetchMoreNodes = getNonNullableItems<NotificationItem>((fetchMoreResult.notifications?.nodes ?? []).map(mapToNotificationItem))

        return {
          notifications: {
            ...fetchMoreResult.notifications,
            nodes: [...previousNodes, ...fetchMoreNodes]
          }
        }
      }
    })
  }

  public refetchList(itemsCount = ITEMS_PER_PAGE): void {
    if (this.notificationsQueryRef) {
      this.notificationsQueryRef.refetch({ first: itemsCount })
    }
  }

  private getNotifications$(): Observable<NotificationItem[]> {
    return this.getNotificationsQueryRef().valueChanges.pipe(
      filter(({ loading }) => {
        this.set({ isLoading: loading })
        return !loading
      }),
      map(({ data }) => {
        const { nodes, totalCount, pageInfo } = data?.notifications || {}

        this.set({
          totalCount: totalCount || 0,
          pageInfo: { ...DEFAULT_PAGE_INFO, ...(pageInfo || {}) }
        })

        return getNonNullableItems<NotificationItem>((nodes || []).map(mapToNotificationItem))
      }),
      catchError(() => {
        this.set({ isLoading: false })
        return of(this.get()?.notifications || [])
      })
    )
  }

  private getNotificationsQueryRef(): QueryRef<GetNotificationsQuery, GetNotificationsQueryVariables> {
    if (!this.notificationsQueryRef) {
      this.notificationsQueryRef = this.getNotificationsGQL.watch(this.getNotificationsQueryVariables(), {
        useInitialLoading: true,
        notifyOnNetworkStatusChange: true
      })
    }

    return this.notificationsQueryRef
  }

  private getNotificationsQueryVariables(items = ITEMS_PER_PAGE, cursor: string | null = null): GetNotificationsQueryVariables {
    return {
      first: items,
      after: cursor
    }
  }
}
