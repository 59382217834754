import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'

import { FeatureAccessService } from '@alliance/employer/data-access'
import { RxStateService } from '@alliance/shared/models'
import { AuthService } from '@alliance/shared/auth/api'

@Injectable({ providedIn: 'root' })
export class CompanyVerificationFeatureService extends RxStateService<{ isEnabled: boolean }> {
  public constructor(private readonly authService: AuthService, private readonly featureAccessService: FeatureAccessService) {
    super()

    this.initState({ isEnabled: this.isEnabled$ })
  }

  private get isEnabled$(): Observable<boolean> {
    const companyId = this.authService.user?.NotebookId

    if (!companyId) {
      return of(false)
    }

    return this.featureAccessService.getFeatureAccess({ context: 'QesCompanyVerification', companyId })
  }

  public isVerificationByCapEnabled$(): Observable<boolean> {
    return this.select('isEnabled')
  }
}
