import { RxStateService } from '@alliance/shared/models'
import { LocalStorage } from '@alliance/shared/storage'
import { NgZoneHelperService, safeJsonParse } from '@alliance/shared/utils'
import { DOCUMENT } from '@angular/common'
import { Inject, Injectable } from '@angular/core'
import { fromEvent, Observable } from 'rxjs'
import { map, startWith } from 'rxjs/operators'

const SOUND_URL = 'https://images.cf-rabota.com.ua/2020/06/Pop%20Ding%20Notification%20Sound%2001.mp3'
const DISABLE_SOUND_LOCAL_STORAGE_KEY = 'notification_disable_sound'

@Injectable({ providedIn: 'root' })
export class NotificationSoundService extends RxStateService<{
  canPlay: boolean
  isEnabled: boolean
}> {
  private readonly sound = this.loadSound()

  public constructor(@Inject(DOCUMENT) private readonly document: Document, private readonly localStorage: LocalStorage, private readonly ngZoneHelperService: NgZoneHelperService) {
    super()

    this.initState({
      canPlay: this.canPlay$(),
      isEnabled: !this.getDisableSoundFromStorage()
    })

    this.hold(this.select('isEnabled'), isEnabled => this.setDisableSoundToStorage(!isEnabled))
  }

  public get isEnabled$(): Observable<boolean> {
    return this.select('isEnabled')
  }

  public toggleSound(): void {
    this.set(({ isEnabled }) => ({ isEnabled: !isEnabled }))
  }

  public playSound(): void {
    const { canPlay, isEnabled } = this.get() || {}

    if (canPlay && isEnabled) {
      this.sound.play().catch(() => null)
    }
  }

  private loadSound(): HTMLAudioElement {
    const sound = new Audio()
    sound.src = SOUND_URL
    sound.load()
    return sound
  }

  private canPlay$(): Observable<boolean> {
    return fromEvent(this.document, 'visibilitychange').pipe(
      this.ngZoneHelperService.outsideNgZone(),
      map(() => !this.document.hidden),
      startWith(!this.document.hidden)
    )
  }

  private getDisableSoundFromStorage(): boolean {
    try {
      return safeJsonParse(this.localStorage.getItem(DISABLE_SOUND_LOCAL_STORAGE_KEY), false)
    } catch (e) {
      return false
    }
  }

  private setDisableSoundToStorage(value: boolean): void {
    this.localStorage.setItem(DISABLE_SOUND_LOCAL_STORAGE_KEY, JSON.stringify(value))
  }
}
