/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http'
import { Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { StrictHttpResponse } from '../../strict-http-response'
import { RequestBuilder } from '../../request-builder'

import { FetchDirection } from '../../models/fetch-direction'
import { MessageViewModel } from '../../models/message-view-model'

export interface MessagesGet$Plain$Params {
  conversationId: string
  lastSeen?: string
  direction?: FetchDirection
  limit?: number
}

export function messagesGet$Plain(http: HttpClient, rootUrl: string, params: MessagesGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MessageViewModel>>> {
  const rb = new RequestBuilder(rootUrl, messagesGet$Plain.PATH, 'get')
  if (params) {
    rb.path('conversationId', params.conversationId, {})
    rb.query('lastSeen', params.lastSeen, {})
    rb.query('direction', params.direction, {})
    rb.query('limit', params.limit, {})
  }

  return http.request(rb.build({ responseType: 'text', accept: 'text/plain', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<MessageViewModel>>
    })
  )
}

messagesGet$Plain.PATH = '/v1/conversations/{conversationId}/messages'
