/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http'
import { Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { StrictHttpResponse } from '../../strict-http-response'
import { RequestBuilder } from '../../request-builder'

import { GetConversationByEmailResponse } from '../../models/get-conversation-by-email-response'

export interface ConversationGetConversationByEmail$Plain$Params {
  email?: string
}

export function conversationGetConversationByEmail$Plain(
  http: HttpClient,
  rootUrl: string,
  params?: ConversationGetConversationByEmail$Plain$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<GetConversationByEmailResponse>> {
  const rb = new RequestBuilder(rootUrl, conversationGetConversationByEmail$Plain.PATH, 'get')
  if (params) {
    rb.query('email', params.email, {})
  }

  return http.request(rb.build({ responseType: 'text', accept: 'text/plain', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<GetConversationByEmailResponse>
    })
  )
}

conversationGetConversationByEmail$Plain.PATH = '/v2/conversations/by-email'
