import { AccountJobsearcherService, ResumeService as JobseekerResumeService } from '@alliance/jobseeker/data-access'
import { Environment } from '@alliance/shared/environment'
import { TransferInterceptorUrlHandler } from '@alliance/shared/ssr/transfer-state'
import { Injectable } from '@angular/core'

@Injectable()
export class JobseekerTransferInterceptorUrlHandlerService implements TransferInterceptorUrlHandler {
  private readonly ignoreUrls = [
    new URL(AccountJobsearcherService.getAttachListPath, this.environment.jobseekerApi ?? '').toString(),
    new URL(AccountJobsearcherService.getAttachListPath, this.environment.jobseekerApiUk ?? '').toString(),
    new URL(JobseekerResumeService.resumeGetJobseekerResumeListPath, this.environment.jobseekerApi ?? '').toString(),
    new URL(JobseekerResumeService.resumeGetJobseekerResumeListPath, this.environment.jobseekerApiUk ?? '').toString()
  ]

  public constructor(private readonly environment: Environment) {}

  public check(url: string): boolean {
    return !this.ignoreUrls.some(ignoredUrl => url.includes(ignoredUrl))
  }
}
