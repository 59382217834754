import { Injectable } from '@angular/core'
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http'
import { BaseService as __BaseService } from '../base-service'
import { EmployerDataAccessConfiguration as __Configuration } from '../employer-data-access-configuration'
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response'
import { Observable as __Observable } from 'rxjs'
import { map as __map, filter as __filter } from 'rxjs/operators'

import { SuggestKeyword } from '../models/suggest-keyword'
import { CvDbSearchHistoryItemResponse } from '../models/cv-db-search-history-item-response'
import { CvDbSearchRequest } from '../models/cv-db-search-request'
import { SearchItem } from '../models/search-item'
import { CvDbSelectedSearchItem } from '../models/cv-db-selected-search-item'
import { CvDbSearchDocumentsResponse } from '../models/cv-db-search-documents-response'
import { CvDbSearchFacetsResponseV2 } from '../models/cv-db-search-facets-response-v2'
import { CvDbSearchFacetsResponse } from '../models/cv-db-search-facets-response'
import { CvDbHintsResponse } from '../models/cv-db-hints-response'
@Injectable({
  providedIn: 'root'
})
class CvDbService extends __BaseService {
  public static readonly suggestPath = '/cvdb/autocomplete'
  public static readonly getSearchHistoryPath = '/cvdb/search-history'
  public static readonly saveSearchHistoryPath = '/cvdb/search-history'
  public static readonly getSelectedSearchesPath = '/cvdb/selected-search-list'
  public static readonly saveSelectedSearchesPath = '/cvdb/selected-search'
  public static readonly deleteSelectedSearchPath = '/cvdb/selected-search'
  public static readonly cacheableSearchPath = '/cvdb/resumes'
  public static readonly searchPath = '/cvdb/resumes'
  public static readonly facetsV2Path = '/cvdb/facets'
  public static readonly facetsPath = '/cvdb/facets'
  public static readonly alternativeSearchHintsPath = '/cvdb/alternative-search-hints'
  public static readonly totalResumeCounterPath = '/cvdb/resumes-count'

  public constructor(config: __Configuration, http: HttpClient) {
    super(config, http)
  }

  /**
   * @param params The `CvDbService.SuggestParams` containing the following parameters:
   *
   * - `ukrainian`:
   *
   * - `keywords`:
   *
   * - `cityId`:
   *
   * @return Success
   */
  suggestResponse(params: CvDbService.SuggestParams): __Observable<__StrictHttpResponse<Array<SuggestKeyword>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.ukrainian != null) __params = __params.set('ukrainian', params.ukrainian.toString())
    if (params.keywords != null) __params = __params.set('keywords', params.keywords.toString())
    if (params.cityId != null) __params = __params.set('cityId', params.cityId.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/cvdb/autocomplete`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<SuggestKeyword>>
      })
    )
  }

  /**
   * @param params The `CvDbService.SuggestParams` containing the following parameters:
   *
   * - `ukrainian`:
   *
   * - `keywords`:
   *
   * - `cityId`:
   *
   * @return Success
   */
  suggest(params: CvDbService.SuggestParams): __Observable<Array<SuggestKeyword>> {
    return this.suggestResponse(params).pipe(__map(_r => _r.body as Array<SuggestKeyword>))
  }

  /**
   * @return Success
   */
  getSearchHistoryResponse(): __Observable<__StrictHttpResponse<Array<CvDbSearchHistoryItemResponse>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/cvdb/search-history`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<CvDbSearchHistoryItemResponse>>
      })
    )
  }

  /**
   * @return Success
   */
  getSearchHistory(): __Observable<Array<CvDbSearchHistoryItemResponse>> {
    return this.getSearchHistoryResponse().pipe(__map(_r => _r.body as Array<CvDbSearchHistoryItemResponse>))
  }

  /**
   * @param request undefined
   */
  saveSearchHistoryResponse(request?: CvDbSearchRequest): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = request
    let req = new HttpRequest<any>('POST', this.rootUrl + `/cvdb/search-history`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param request undefined
   */
  saveSearchHistory(request?: CvDbSearchRequest): __Observable<any> {
    return this.saveSearchHistoryResponse(request).pipe(__map(_r => _r.body as any))
  }

  /**
   * @return Success
   */
  getSelectedSearchesResponse(): __Observable<__StrictHttpResponse<Array<SearchItem>>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/cvdb/selected-search-list`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<Array<SearchItem>>
      })
    )
  }

  /**
   * @return Success
   */
  getSelectedSearches(): __Observable<Array<SearchItem>> {
    return this.getSelectedSearchesResponse().pipe(__map(_r => _r.body as Array<SearchItem>))
  }

  /**
   * @param request undefined
   * @return Success
   */
  saveSelectedSearchesResponse(request?: CvDbSearchRequest): __Observable<__StrictHttpResponse<CvDbSelectedSearchItem>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = request
    let req = new HttpRequest<any>('POST', this.rootUrl + `/cvdb/selected-search`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<CvDbSelectedSearchItem>
      })
    )
  }

  /**
   * @param request undefined
   * @return Success
   */
  saveSelectedSearches(request?: CvDbSearchRequest): __Observable<CvDbSelectedSearchItem> {
    return this.saveSelectedSearchesResponse(request).pipe(__map(_r => _r.body as CvDbSelectedSearchItem))
  }

  /**
   * @param selectedSearchId undefined
   */
  deleteSelectedSearchResponse(selectedSearchId?: number): __Observable<__StrictHttpResponse<any>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (selectedSearchId != null) __params = __params.set('selectedSearchId', selectedSearchId.toString())
    let req = new HttpRequest<any>('DELETE', this.rootUrl + `/cvdb/selected-search`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<any>
      })
    )
  }

  /**
   * @param selectedSearchId undefined
   */
  deleteSelectedSearch(selectedSearchId?: number): __Observable<any> {
    return this.deleteSelectedSearchResponse(selectedSearchId).pipe(__map(_r => _r.body as any))
  }

  /**
   * @param params The `CvDbService.CacheableSearchParams` containing the following parameters:
   *
   * - `Ukrainian`: Мова відвовіді.
   *   true - укр, false - рус.
   *
   * - `Sort`: Сортувати за.
   *   0 - релевантність, 1 - дата оновлення. 2 -  дата активності
   *
   * - `ShowCvWithoutSalary`:
   *
   * - `Sex`:
   *
   * - `SearchType`: Де шукати.
   *   everywhere - по всьому тексту, default - з урахуванням синонімів, speciality - в назві резюме, education - в освіті, skills - в ключових навичках, experience - в досвіді роботи.
   *
   * - `SearchContext`: Место в интерфейсе, откуда был инициирован поиск
   *   0 - не обрано, 1 - пошук, 2 -  фільтри
   *
   * - `ScheduleIds`: Вид зайнятості.
   *   1 - повна, 2 - не повна, 3 - віддалена, 4 - стажування/практика, 5- проектна робота, 6 - сезонна/тимчасова робота.
   *
   * - `Salary.To`: Може приймати значенн null
   *
   * - `Salary.From`: Може приймати значенн null
   *
   * - `Rubrics`:
   *
   * - `Reverse`:
   *
   * - `ResumeFillingTypeIds`:
   *
   * - `ProfLevelIds`: Не використовується.
   *
   * - `Period`:
   *
   * - `Page`: Номер сторінки, починаючи з 0.
   *
   * - `OnlyWithOwnCar`:
   *
   * - `OnlyWithOpenedContacts`:
   *
   * - `OnlyWithCurrentNotebookNotes`:
   *
   * - `OnlyViewed`:
   *
   * - `OnlyStudents`:
   *
   * - `OnlyNew`:
   *
   * - `OnlyMoveability`: Готовність до переїзду.
   *   true - тільки готових до переїзду
   *
   * - `OnlyFavorite`:
   *
   * - `OnlyDisliked`:
   *
   * - `OnlyDisabled`:
   *
   * - `Moveability`: Готовність до переїзду.
   *   true - якщо готовий до переїзду; false - не готовий до переїзду.
   *
   * - `LastSort`:
   *
   * - `Languages`: Володіння мовами -  https://api.rabota.ua/dictionary/language, https://api.rabota.ua/dictionary/language/skill
   *
   * - `KeyWords`: Пошуковий запит.
   *
   * - `IsSynonym`:
   *
   * - `Inside`: Включаючи в області.
   *
   * - `IncludeDisliked`:
   *
   * - `HasPhoto`: Фоточка.
   *   true - yes, false - no.
   *
   * - `ExperienceIds`: Досвід роботи - https://employer-api.rabota.ua/values/cv-search-experience
   *   0 - без досвіду, 1 - до 1 року, 2 - від 1 до 2 років, 3 - від 2 до 5 років, 4 - від 5 до 10 років, 5 - більше 10 років
   *
   * - `EducationIds`: Вид освіти.
   *   1 - вища, 2 - незакінченна вища, 3 - середньо-спеціальна, 4 - середня, 5 - МВА, 6- Дія. Фифрова освіта
   *
   * - `DistrictIds`:
   *
   * - `Disabled`:
   *
   * - `CvLanguage`: Мова резюме.
   *   1 - рус, 2 - анг, 3 - укр.
   *
   * - `Count`:
   *
   * - `CityId`: ID міста.
   *
   * - `BranchIds`: Галузь компанії - https://api.rabota.ua/dictionary/branch
   *
   * - `Age.To`: Може приймати значенн null
   *
   * - `Age.From`: Може приймати значенн null
   *
   * @return Success
   */
  cacheableSearchResponse(params: CvDbService.CacheableSearchParams): __Observable<__StrictHttpResponse<CvDbSearchDocumentsResponse>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.Ukrainian != null) __params = __params.set('Ukrainian', params.Ukrainian.toString())
    if (params.Sort != null) __params = __params.set('Sort', params.Sort.toString())
    if (params.ShowCvWithoutSalary != null) __params = __params.set('ShowCvWithoutSalary', params.ShowCvWithoutSalary.toString())
    if (params.Sex != null) __params = __params.set('Sex', params.Sex.toString())
    if (params.SearchType != null) __params = __params.set('SearchType', params.SearchType.toString())
    if (params.SearchContext != null) __params = __params.set('SearchContext', params.SearchContext.toString())
    ;(params.ScheduleIds || []).forEach(val => {
      if (val != null) __params = __params.append('ScheduleIds', val.toString())
    })
    if (params.SalaryTo != null) __params = __params.set('Salary.To', params.SalaryTo.toString())
    if (params.SalaryFrom != null) __params = __params.set('Salary.From', params.SalaryFrom.toString())
    ;(params.Rubrics || []).forEach(val => {
      if (val != null) __params = __params.append('Rubrics', val.toString())
    })
    if (params.Reverse != null) __params = __params.set('Reverse', params.Reverse.toString())
    ;(params.ResumeFillingTypeIds || []).forEach(val => {
      if (val != null) __params = __params.append('ResumeFillingTypeIds', val.toString())
    })
    ;(params.ProfLevelIds || []).forEach(val => {
      if (val != null) __params = __params.append('ProfLevelIds', val.toString())
    })
    if (params.Period != null) __params = __params.set('Period', params.Period.toString())
    if (params.Page != null) __params = __params.set('Page', params.Page.toString())
    if (params.OnlyWithOwnCar != null) __params = __params.set('OnlyWithOwnCar', params.OnlyWithOwnCar.toString())
    if (params.OnlyWithOpenedContacts != null) __params = __params.set('OnlyWithOpenedContacts', params.OnlyWithOpenedContacts.toString())
    if (params.OnlyWithCurrentNotebookNotes != null) __params = __params.set('OnlyWithCurrentNotebookNotes', params.OnlyWithCurrentNotebookNotes.toString())
    if (params.OnlyViewed != null) __params = __params.set('OnlyViewed', params.OnlyViewed.toString())
    if (params.OnlyStudents != null) __params = __params.set('OnlyStudents', params.OnlyStudents.toString())
    if (params.OnlyNew != null) __params = __params.set('OnlyNew', params.OnlyNew.toString())
    if (params.OnlyMoveability != null) __params = __params.set('OnlyMoveability', params.OnlyMoveability.toString())
    if (params.OnlyFavorite != null) __params = __params.set('OnlyFavorite', params.OnlyFavorite.toString())
    if (params.OnlyDisliked != null) __params = __params.set('OnlyDisliked', params.OnlyDisliked.toString())
    if (params.OnlyDisabled != null) __params = __params.set('OnlyDisabled', params.OnlyDisabled.toString())
    if (params.OnlyVeterans != null) __params = __params.set('OnlyVeterans', params.OnlyVeterans.toString())
    if (params.Moveability != null) __params = __params.set('Moveability', params.Moveability.toString())
    if (params.LastSort != null) __params = __params.set('LastSort', params.LastSort.toString())
    ;(params.Languages || []).forEach(val => {
      if (val != null) __params = __params.append('Languages', val.toString())
    })
    if (params.KeyWords != null) __params = __params.set('KeyWords', params.KeyWords.toString())
    if (params.IsSynonym != null) __params = __params.set('IsSynonym', params.IsSynonym.toString())
    if (params.Inside != null) __params = __params.set('Inside', params.Inside.toString())
    if (params.IncludeDisliked != null) __params = __params.set('IncludeDisliked', params.IncludeDisliked.toString())
    if (params.HasPhoto != null) __params = __params.set('HasPhoto', params.HasPhoto.toString())
    ;(params.ExperienceIds || []).forEach(val => {
      if (val != null) __params = __params.append('ExperienceIds', val.toString())
    })
    ;(params.EducationIds || []).forEach(val => {
      if (val != null) __params = __params.append('EducationIds', val.toString())
    })
    ;(params.DistrictIds || []).forEach(val => {
      if (val != null) __params = __params.append('DistrictIds', val.toString())
    })
    if (params.Disabled != null) __params = __params.set('Disabled', params.Disabled.toString())
    if (params.CvLanguage != null) __params = __params.set('CvLanguage', params.CvLanguage.toString())
    if (params.Count != null) __params = __params.set('Count', params.Count.toString())
    if (params.CityId != null) __params = __params.set('CityId', params.CityId.toString())
    ;(params.BranchIds || []).forEach(val => {
      if (val != null) __params = __params.append('BranchIds', val.toString())
    })
    if (params.AgeTo != null) __params = __params.set('Age.To', params.AgeTo.toString())
    if (params.AgeFrom != null) __params = __params.set('Age.From', params.AgeFrom.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/cvdb/resumes`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<CvDbSearchDocumentsResponse>
      })
    )
  }

  /**
   * @param params The `CvDbService.CacheableSearchParams` containing the following parameters:
   *
   * - `Ukrainian`: Мова відвовіді.
   *   true - укр, false - рус.
   *
   * - `Sort`: Сортувати за.
   *   0 - релевантність, 1 - дата оновлення. 2 -  дата активності
   *
   * - `ShowCvWithoutSalary`:
   *
   * - `Sex`:
   *
   * - `SearchType`: Де шукати.
   *   everywhere - по всьому тексту, default - з урахуванням синонімів, speciality - в назві резюме, education - в освіті, skills - в ключових навичках, experience - в досвіді роботи.
   *
   * - `SearchContext`: Место в интерфейсе, откуда был инициирован поиск
   *   0 - не обрано, 1 - пошук, 2 -  фільтри
   *
   * - `ScheduleIds`: Вид зайнятості.
   *   1 - повна, 2 - не повна, 3 - віддалена, 4 - стажування/практика, 5- проектна робота, 6 - сезонна/тимчасова робота.
   *
   * - `Salary.To`: Може приймати значенн null
   *
   * - `Salary.From`: Може приймати значенн null
   *
   * - `Rubrics`:
   *
   * - `Reverse`:
   *
   * - `ResumeFillingTypeIds`:
   *
   * - `ProfLevelIds`: Не використовується.
   *
   * - `Period`:
   *
   * - `Page`: Номер сторінки, починаючи з 0.
   *
   * - `OnlyWithOwnCar`:
   *
   * - `OnlyWithOpenedContacts`:
   *
   * - `OnlyWithCurrentNotebookNotes`:
   *
   * - `OnlyViewed`:
   *
   * - `OnlyStudents`:
   *
   * - `OnlyNew`:
   *
   * - `OnlyMoveability`: Готовність до переїзду.
   *   true - тільки готових до переїзду
   *
   * - `OnlyFavorite`:
   *
   * - `OnlyDisliked`:
   *
   * - `OnlyDisabled`:
   *
   * - `Moveability`: Готовність до переїзду.
   *   true - якщо готовий до переїзду; false - не готовий до переїзду.
   *
   * - `LastSort`:
   *
   * - `Languages`: Володіння мовами -  https://api.rabota.ua/dictionary/language, https://api.rabota.ua/dictionary/language/skill
   *
   * - `KeyWords`: Пошуковий запит.
   *
   * - `IsSynonym`:
   *
   * - `Inside`: Включаючи в області.
   *
   * - `IncludeDisliked`:
   *
   * - `HasPhoto`: Фоточка.
   *   true - yes, false - no.
   *
   * - `ExperienceIds`: Досвід роботи - https://employer-api.rabota.ua/values/cv-search-experience
   *   0 - без досвіду, 1 - до 1 року, 2 - від 1 до 2 років, 3 - від 2 до 5 років, 4 - від 5 до 10 років, 5 - більше 10 років
   *
   * - `EducationIds`: Вид освіти.
   *   1 - вища, 2 - незакінченна вища, 3 - середньо-спеціальна, 4 - середня, 5 - МВА, 6- Дія. Фифрова освіта
   *
   * - `DistrictIds`:
   *
   * - `Disabled`:
   *
   * - `CvLanguage`: Мова резюме.
   *   1 - рус, 2 - анг, 3 - укр.
   *
   * - `Count`:
   *
   * - `CityId`: ID міста.
   *
   * - `BranchIds`: Галузь компанії - https://api.rabota.ua/dictionary/branch
   *
   * - `Age.To`: Може приймати значенн null
   *
   * - `Age.From`: Може приймати значенн null
   *
   * @return Success
   */
  cacheableSearch(params: CvDbService.CacheableSearchParams): __Observable<CvDbSearchDocumentsResponse> {
    return this.cacheableSearchResponse(params).pipe(__map(_r => _r.body as CvDbSearchDocumentsResponse))
  }

  /**
   * @param request undefined
   * @return Success
   */
  searchResponse(request?: CvDbSearchRequest): __Observable<__StrictHttpResponse<CvDbSearchDocumentsResponse>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = request
    let req = new HttpRequest<any>('POST', this.rootUrl + `/cvdb/resumes`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<CvDbSearchDocumentsResponse>
      })
    )
  }

  /**
   * @param request undefined
   * @return Success
   */
  search(request?: CvDbSearchRequest): __Observable<CvDbSearchDocumentsResponse> {
    return this.searchResponse(request).pipe(__map(_r => _r.body as CvDbSearchDocumentsResponse))
  }

  /**
   * @param params The `CvDbService.FacetsV2Params` containing the following parameters:
   *
   * - `Ukrainian`: Мова відвовіді.
   *   true - укр, false - рус.
   *
   * - `Sort`: Сортувати за.
   *   0 - релевантність, 1 - дата оновлення. 2 -  дата активності
   *
   * - `ShowCvWithoutSalary`:
   *
   * - `Sex`:
   *
   * - `SearchType`: Де шукати.
   *   everywhere - по всьому тексту, default - з урахуванням синонімів, speciality - в назві резюме, education - в освіті, skills - в ключових навичках, experience - в досвіді роботи.
   *
   * - `SearchContext`: Место в интерфейсе, откуда был инициирован поиск
   *   0 - не обрано, 1 - пошук, 2 -  фільтри
   *
   * - `ScheduleIds`: Вид зайнятості.
   *   1 - повна, 2 - не повна, 3 - віддалена, 4 - стажування/практика, 5- проектна робота, 6 - сезонна/тимчасова робота.
   *
   * - `Salary.To`: Може приймати значенн null
   *
   * - `Salary.From`: Може приймати значенн null
   *
   * - `Rubrics`:
   *
   * - `Reverse`:
   *
   * - `ResumeFillingTypeIds`:
   *
   * - `ProfLevelIds`: Не використовується.
   *
   * - `Period`:
   *
   * - `Page`: Номер сторінки, починаючи з 0.
   *
   * - `OnlyWithOwnCar`:
   *
   * - `OnlyWithOpenedContacts`:
   *
   * - `OnlyWithCurrentNotebookNotes`:
   *
   * - `OnlyViewed`:
   *
   * - `OnlyStudents`:
   *
   * - `OnlyNew`:
   *
   * - `OnlyMoveability`: Готовність до переїзду.
   *   true - тільки готових до переїзду
   *
   * - `OnlyFavorite`:
   *
   * - `OnlyDisliked`:
   *
   * - `OnlyDisabled`:
   *
   * - `NotebookStateId`:
   *
   * - `NotebookId`:
   *
   * - `MultiUserId`:
   *
   * - `Moveability`: Готовність до переїзду.
   *   true - якщо готовий до переїзду; false - не готовий до переїзду.
   *
   * - `LastSort`:
   *
   * - `Languages`: Володіння мовами -  https://api.rabota.ua/dictionary/language, https://api.rabota.ua/dictionary/language/skill
   *
   * - `KeyWords`: Пошуковий запит.
   *
   * - `IsSynonym`:
   *
   * - `IsEmployer`:
   *
   * - `Inside`: Включаючи в області.
   *
   * - `IncludeDisliked`:
   *
   * - `HasPhoto`: Фоточка.
   *   true - yes, false - no.
   *
   * - `GoogleAnalyticsId`:
   *
   * - `ExperienceIds`: Досвід роботи - https://employer-api.rabota.ua/values/cv-search-experience
   *   0 - без досвіду, 1 - до 1 року, 2 - від 1 до 2 років, 3 - від 2 до 5 років, 4 - від 5 до 10 років, 5 - більше 10 років
   *
   * - `EducationIds`: Вид освіти.
   *   1 - вища, 2 - незакінченна вища, 3 - середньо-спеціальна, 4 - середня, 5 - МВА, 6- Дія. Фифрова освіта
   *
   * - `DistrictIds`:
   *
   * - `Disabled`:
   *
   * - `CvLanguage`: Мова резюме.
   *   1 - рус, 2 - анг, 3 - укр.
   *
   * - `Count`:
   *
   * - `CityId`: ID міста.
   *
   * - `BranchIds`: Галузь компанії - https://api.rabota.ua/dictionary/branch
   *
   * - `Age.To`: Може приймати значенн null
   *
   * - `Age.From`: Може приймати значенн null
   *
   * @return Success
   */
  facetsV2Response(params: CvDbService.FacetsV2Params): __Observable<__StrictHttpResponse<CvDbSearchFacetsResponseV2>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    if (params.Ukrainian != null) __params = __params.set('Ukrainian', params.Ukrainian.toString())
    if (params.Sort != null) __params = __params.set('Sort', params.Sort.toString())
    if (params.ShowCvWithoutSalary != null) __params = __params.set('ShowCvWithoutSalary', params.ShowCvWithoutSalary.toString())
    if (params.Sex != null) __params = __params.set('Sex', params.Sex.toString())
    if (params.SearchType != null) __params = __params.set('SearchType', params.SearchType.toString())
    if (params.SearchContext != null) __params = __params.set('SearchContext', params.SearchContext.toString())
    ;(params.ScheduleIds || []).forEach(val => {
      if (val != null) __params = __params.append('ScheduleIds', val.toString())
    })
    if (params.SalaryTo != null) __params = __params.set('Salary.To', params.SalaryTo.toString())
    if (params.SalaryFrom != null) __params = __params.set('Salary.From', params.SalaryFrom.toString())
    ;(params.Rubrics || []).forEach(val => {
      if (val != null) __params = __params.append('Rubrics', val.toString())
    })
    if (params.Reverse != null) __params = __params.set('Reverse', params.Reverse.toString())
    ;(params.ResumeFillingTypeIds || []).forEach(val => {
      if (val != null) __params = __params.append('ResumeFillingTypeIds', val.toString())
    })
    ;(params.ProfLevelIds || []).forEach(val => {
      if (val != null) __params = __params.append('ProfLevelIds', val.toString())
    })
    if (params.Period != null) __params = __params.set('Period', params.Period.toString())
    if (params.Page != null) __params = __params.set('Page', params.Page.toString())
    if (params.OnlyWithOwnCar != null) __params = __params.set('OnlyWithOwnCar', params.OnlyWithOwnCar.toString())
    if (params.OnlyWithOpenedContacts != null) __params = __params.set('OnlyWithOpenedContacts', params.OnlyWithOpenedContacts.toString())
    if (params.OnlyWithCurrentNotebookNotes != null) __params = __params.set('OnlyWithCurrentNotebookNotes', params.OnlyWithCurrentNotebookNotes.toString())
    if (params.OnlyViewed != null) __params = __params.set('OnlyViewed', params.OnlyViewed.toString())
    if (params.OnlyStudents != null) __params = __params.set('OnlyStudents', params.OnlyStudents.toString())
    if (params.OnlyNew != null) __params = __params.set('OnlyNew', params.OnlyNew.toString())
    if (params.OnlyMoveability != null) __params = __params.set('OnlyMoveability', params.OnlyMoveability.toString())
    if (params.OnlyFavorite != null) __params = __params.set('OnlyFavorite', params.OnlyFavorite.toString())
    if (params.OnlyDisliked != null) __params = __params.set('OnlyDisliked', params.OnlyDisliked.toString())
    if (params.OnlyDisabled != null) __params = __params.set('OnlyDisabled', params.OnlyDisabled.toString())
    if (params.OnlyVeterans != null) __params = __params.set('OnlyVeterans', params.OnlyVeterans.toString())
    if (params.NotebookStateId != null) __params = __params.set('NotebookStateId', params.NotebookStateId.toString())
    if (params.NotebookId != null) __params = __params.set('NotebookId', params.NotebookId.toString())
    if (params.MultiUserId != null) __params = __params.set('MultiUserId', params.MultiUserId.toString())
    if (params.Moveability != null) __params = __params.set('Moveability', params.Moveability.toString())
    if (params.LastSort != null) __params = __params.set('LastSort', params.LastSort.toString())
    ;(params.Languages || []).forEach(val => {
      if (val != null) __params = __params.append('Languages', val.toString())
    })
    if (params.KeyWords != null) __params = __params.set('KeyWords', params.KeyWords.toString())
    if (params.IsSynonym != null) __params = __params.set('IsSynonym', params.IsSynonym.toString())
    if (params.IsEmployer != null) __params = __params.set('IsEmployer', params.IsEmployer.toString())
    if (params.Inside != null) __params = __params.set('Inside', params.Inside.toString())
    if (params.IncludeDisliked != null) __params = __params.set('IncludeDisliked', params.IncludeDisliked.toString())
    if (params.HasPhoto != null) __params = __params.set('HasPhoto', params.HasPhoto.toString())
    if (params.GoogleAnalyticsId != null) __params = __params.set('GoogleAnalyticsId', params.GoogleAnalyticsId.toString())
    ;(params.ExperienceIds || []).forEach(val => {
      if (val != null) __params = __params.append('ExperienceIds', val.toString())
    })
    ;(params.EducationIds || []).forEach(val => {
      if (val != null) __params = __params.append('EducationIds', val.toString())
    })
    ;(params.DistrictIds || []).forEach(val => {
      if (val != null) __params = __params.append('DistrictIds', val.toString())
    })
    if (params.Disabled != null) __params = __params.set('Disabled', params.Disabled.toString())
    if (params.CvLanguage != null) __params = __params.set('CvLanguage', params.CvLanguage.toString())
    if (params.Count != null) __params = __params.set('Count', params.Count.toString())
    if (params.CityId != null) __params = __params.set('CityId', params.CityId.toString())
    ;(params.BranchIds || []).forEach(val => {
      if (val != null) __params = __params.append('BranchIds', val.toString())
    })
    if (params.AgeTo != null) __params = __params.set('Age.To', params.AgeTo.toString())
    if (params.AgeFrom != null) __params = __params.set('Age.From', params.AgeFrom.toString())
    let req = new HttpRequest<any>('GET', this.rootUrl + `/cvdb/facets`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<CvDbSearchFacetsResponseV2>
      })
    )
  }

  /**
   * @param params The `CvDbService.FacetsV2Params` containing the following parameters:
   *
   * - `Ukrainian`: Мова відвовіді.
   *   true - укр, false - рус.
   *
   * - `Sort`: Сортувати за.
   *   0 - релевантність, 1 - дата оновлення. 2 -  дата активності
   *
   * - `ShowCvWithoutSalary`:
   *
   * - `Sex`:
   *
   * - `SearchType`: Де шукати.
   *   everywhere - по всьому тексту, default - з урахуванням синонімів, speciality - в назві резюме, education - в освіті, skills - в ключових навичках, experience - в досвіді роботи.
   *
   * - `SearchContext`: Место в интерфейсе, откуда был инициирован поиск
   *   0 - не обрано, 1 - пошук, 2 -  фільтри
   *
   * - `ScheduleIds`: Вид зайнятості.
   *   1 - повна, 2 - не повна, 3 - віддалена, 4 - стажування/практика, 5- проектна робота, 6 - сезонна/тимчасова робота.
   *
   * - `Salary.To`: Може приймати значенн null
   *
   * - `Salary.From`: Може приймати значенн null
   *
   * - `Rubrics`:
   *
   * - `Reverse`:
   *
   * - `ResumeFillingTypeIds`:
   *
   * - `ProfLevelIds`: Не використовується.
   *
   * - `Period`:
   *
   * - `Page`: Номер сторінки, починаючи з 0.
   *
   * - `OnlyWithOwnCar`:
   *
   * - `OnlyWithOpenedContacts`:
   *
   * - `OnlyWithCurrentNotebookNotes`:
   *
   * - `OnlyViewed`:
   *
   * - `OnlyStudents`:
   *
   * - `OnlyNew`:
   *
   * - `OnlyMoveability`: Готовність до переїзду.
   *   true - тільки готових до переїзду
   *
   * - `OnlyFavorite`:
   *
   * - `OnlyDisliked`:
   *
   * - `OnlyDisabled`:
   *
   * - `NotebookStateId`:
   *
   * - `NotebookId`:
   *
   * - `MultiUserId`:
   *
   * - `Moveability`: Готовність до переїзду.
   *   true - якщо готовий до переїзду; false - не готовий до переїзду.
   *
   * - `LastSort`:
   *
   * - `Languages`: Володіння мовами -  https://api.rabota.ua/dictionary/language, https://api.rabota.ua/dictionary/language/skill
   *
   * - `KeyWords`: Пошуковий запит.
   *
   * - `IsSynonym`:
   *
   * - `IsEmployer`:
   *
   * - `Inside`: Включаючи в області.
   *
   * - `IncludeDisliked`:
   *
   * - `HasPhoto`: Фоточка.
   *   true - yes, false - no.
   *
   * - `GoogleAnalyticsId`:
   *
   * - `ExperienceIds`: Досвід роботи - https://employer-api.rabota.ua/values/cv-search-experience
   *   0 - без досвіду, 1 - до 1 року, 2 - від 1 до 2 років, 3 - від 2 до 5 років, 4 - від 5 до 10 років, 5 - більше 10 років
   *
   * - `EducationIds`: Вид освіти.
   *   1 - вища, 2 - незакінченна вища, 3 - середньо-спеціальна, 4 - середня, 5 - МВА, 6- Дія. Фифрова освіта
   *
   * - `DistrictIds`:
   *
   * - `Disabled`:
   *
   * - `CvLanguage`: Мова резюме.
   *   1 - рус, 2 - анг, 3 - укр.
   *
   * - `Count`:
   *
   * - `CityId`: ID міста.
   *
   * - `BranchIds`: Галузь компанії - https://api.rabota.ua/dictionary/branch
   *
   * - `Age.To`: Може приймати значенн null
   *
   * - `Age.From`: Може приймати значенн null
   *
   * @return Success
   */
  facetsV2(params: CvDbService.FacetsV2Params): __Observable<CvDbSearchFacetsResponseV2> {
    return this.facetsV2Response(params).pipe(__map(_r => _r.body as CvDbSearchFacetsResponseV2))
  }

  /**
   * @param request undefined
   * @return Success
   */
  facetsResponse(request?: CvDbSearchRequest): __Observable<__StrictHttpResponse<CvDbSearchFacetsResponse>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = request
    let req = new HttpRequest<any>('POST', this.rootUrl + `/cvdb/facets`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<CvDbSearchFacetsResponse>
      })
    )
  }

  /**
   * @param request undefined
   * @return Success
   */
  facets(request?: CvDbSearchRequest): __Observable<CvDbSearchFacetsResponse> {
    return this.facetsResponse(request).pipe(__map(_r => _r.body as CvDbSearchFacetsResponse))
  }

  /**
   * @param request undefined
   * @return Success
   */
  alternativeSearchHintsResponse(request?: CvDbSearchRequest): __Observable<__StrictHttpResponse<CvDbHintsResponse>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    __body = request
    let req = new HttpRequest<any>('POST', this.rootUrl + `/cvdb/alternative-search-hints`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return _r as __StrictHttpResponse<CvDbHintsResponse>
      })
    )
  }

  /**
   * @param request undefined
   * @return Success
   */
  alternativeSearchHints(request?: CvDbSearchRequest): __Observable<CvDbHintsResponse> {
    return this.alternativeSearchHintsResponse(request).pipe(__map(_r => _r.body as CvDbHintsResponse))
  }

  /**
   * @return Success
   */
  totalResumeCounterResponse(): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams()
    let __headers = new HttpHeaders()
    let __body: any = null
    let req = new HttpRequest<any>('GET', this.rootUrl + `/cvdb/resumes-count`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    })

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map(_r => {
        return (_r as HttpResponse<any>).clone({
          body: parseFloat((_r as HttpResponse<any>).body as string)
        }) as __StrictHttpResponse<number>
      })
    )
  }

  /**
   * @return Success
   */
  totalResumeCounter(): __Observable<number> {
    return this.totalResumeCounterResponse().pipe(__map(_r => _r.body as number))
  }
}

module CvDbService {
  /**
   * Parameters for Suggest
   */
  export interface SuggestParams {
    ukrainian?: boolean
    keywords?: string
    cityId?: number
  }

  /**
   * Parameters for CacheableSearch
   */
  export interface CacheableSearchParams {
    /**
     * Мова відвовіді.
     * true - укр, false - рус.
     */
    Ukrainian?: boolean

    /**
     * Сортувати за.
     * 0 - релевантність, 1 - дата оновлення. 2 -  дата активності
     */
    Sort?: 'Score' | 'UpdateDate' | 'LastActivity'
    ShowCvWithoutSalary?: boolean
    Sex?: 'Any' | 'Male' | 'Female'

    /**
     * Де шукати.
     * everywhere - по всьому тексту, default - з урахуванням синонімів, speciality - в назві резюме, education - в освіті, skills - в ключових навичках, experience - в досвіді роботи.
     */
    SearchType?: string

    /**
     * Место в интерфейсе, откуда был инициирован поиск
     * 0 - не обрано, 1 - пошук, 2 -  фільтри
     */
    SearchContext?: 'None' | 'Main' | 'Filters' | 'History'

    /**
     * Вид зайнятості.
     * 1 - повна, 2 - не повна, 3 - віддалена, 4 - стажування/практика, 5- проектна робота, 6 - сезонна/тимчасова робота.
     */
    ScheduleIds?: Array<string>

    /**
     * Може приймати значенн null
     */
    SalaryTo?: number

    /**
     * Може приймати значенн null
     */
    SalaryFrom?: number
    Rubrics?: Array<string>
    Reverse?: boolean
    ResumeFillingTypeIds?: Array<string>

    /**
     * Не використовується.
     */
    ProfLevelIds?: Array<string>
    Period?: 'Today' | 'ThreeDays' | 'Week' | 'Month' | 'Year' | 'All' | 'ThreeMonths'

    /**
     * Номер сторінки, починаючи з 0.
     */
    Page?: number
    OnlyWithOwnCar?: boolean
    OnlyWithOpenedContacts?: boolean
    OnlyWithCurrentNotebookNotes?: boolean
    OnlyViewed?: boolean
    OnlyStudents?: boolean
    OnlyNew?: boolean

    /**
     * Готовність до переїзду.
     * true - тільки готових до переїзду
     */
    OnlyMoveability?: boolean
    OnlyFavorite?: boolean
    OnlyDisliked?: boolean
    OnlyDisabled?: boolean
    OnlyVeterans?: boolean

    /**
     * Готовність до переїзду.
     * true - якщо готовий до переїзду; false - не готовий до переїзду.
     */
    Moveability?: boolean
    LastSort?: string

    /**
     * Володіння мовами -  https://api.rabota.ua/dictionary/language, https://api.rabota.ua/dictionary/language/skill
     */
    Languages?: Array<string>

    /**
     * Пошуковий запит.
     */
    KeyWords?: string
    IsSynonym?: boolean

    /**
     * Включаючи в області.
     */
    Inside?: boolean
    IncludeDisliked?: boolean

    /**
     * Фоточка.
     * true - yes, false - no.
     */
    HasPhoto?: boolean

    /**
     * Досвід роботи - https://employer-api.rabota.ua/values/cv-search-experience
     * 0 - без досвіду, 1 - до 1 року, 2 - від 1 до 2 років, 3 - від 2 до 5 років, 4 - від 5 до 10 років, 5 - більше 10 років
     */
    ExperienceIds?: Array<string>

    /**
     * Вид освіти.
     * 1 - вища, 2 - незакінченна вища, 3 - середньо-спеціальна, 4 - середня, 5 - МВА, 6- Дія. Фифрова освіта
     */
    EducationIds?: Array<string>
    DistrictIds?: Array<string>
    Disabled?: boolean

    /**
     * Мова резюме.
     * 1 - рус, 2 - анг, 3 - укр.
     */
    CvLanguage?: 'All' | 'Russian' | 'English' | 'Ukrainian'
    Count?: number

    /**
     * ID міста.
     */
    CityId?: number

    /**
     * Галузь компанії - https://api.rabota.ua/dictionary/branch
     */
    BranchIds?: Array<string>

    /**
     * Може приймати значенн null
     */
    AgeTo?: number

    /**
     * Може приймати значенн null
     */
    AgeFrom?: number
  }

  /**
   * Parameters for FacetsV2
   */
  export interface FacetsV2Params {
    /**
     * Мова відвовіді.
     * true - укр, false - рус.
     */
    Ukrainian?: boolean

    /**
     * Сортувати за.
     * 0 - релевантність, 1 - дата оновлення. 2 -  дата активності
     */
    Sort?: 'Score' | 'UpdateDate' | 'LastActivity'
    ShowCvWithoutSalary?: boolean
    Sex?: 'Any' | 'Male' | 'Female'

    /**
     * Де шукати.
     * everywhere - по всьому тексту, default - з урахуванням синонімів, speciality - в назві резюме, education - в освіті, skills - в ключових навичках, experience - в досвіді роботи.
     */
    SearchType?: string

    /**
     * Место в интерфейсе, откуда был инициирован поиск
     * 0 - не обрано, 1 - пошук, 2 -  фільтри
     */
    SearchContext?: 'None' | 'Main' | 'Filters' | 'History'

    /**
     * Вид зайнятості.
     * 1 - повна, 2 - не повна, 3 - віддалена, 4 - стажування/практика, 5- проектна робота, 6 - сезонна/тимчасова робота.
     */
    ScheduleIds?: Array<string>

    /**
     * Може приймати значенн null
     */
    SalaryTo?: number

    /**
     * Може приймати значенн null
     */
    SalaryFrom?: number
    Rubrics?: Array<string>
    Reverse?: boolean
    ResumeFillingTypeIds?: Array<string>

    /**
     * Не використовується.
     */
    ProfLevelIds?: Array<string>
    Period?: 'Today' | 'ThreeDays' | 'Week' | 'Month' | 'Year' | 'All' | 'ThreeMonths'

    /**
     * Номер сторінки, починаючи з 0.
     */
    Page?: number
    OnlyWithOwnCar?: boolean
    OnlyWithOpenedContacts?: boolean
    OnlyWithCurrentNotebookNotes?: boolean
    OnlyViewed?: boolean
    OnlyStudents?: boolean
    OnlyNew?: boolean

    /**
     * Готовність до переїзду.
     * true - тільки готових до переїзду
     */
    OnlyMoveability?: boolean
    OnlyFavorite?: boolean
    OnlyDisliked?: boolean
    OnlyDisabled?: boolean
    OnlyVeterans?: boolean
    NotebookStateId?: 'New' | 'ExpectationOfActivation' | 'NotActivated' | 'BlackList' | 'Validated' | 'Undefined' | 'Mega'
    NotebookId?: number
    MultiUserId?: number

    /**
     * Готовність до переїзду.
     * true - якщо готовий до переїзду; false - не готовий до переїзду.
     */
    Moveability?: boolean
    LastSort?: string

    /**
     * Володіння мовами -  https://api.rabota.ua/dictionary/language, https://api.rabota.ua/dictionary/language/skill
     */
    Languages?: Array<string>

    /**
     * Пошуковий запит.
     */
    KeyWords?: string
    IsSynonym?: boolean
    IsEmployer?: boolean

    /**
     * Включаючи в області.
     */
    Inside?: boolean
    IncludeDisliked?: boolean

    /**
     * Фоточка.
     * true - yes, false - no.
     */
    HasPhoto?: boolean
    GoogleAnalyticsId?: string

    /**
     * Досвід роботи - https://employer-api.rabota.ua/values/cv-search-experience
     * 0 - без досвіду, 1 - до 1 року, 2 - від 1 до 2 років, 3 - від 2 до 5 років, 4 - від 5 до 10 років, 5 - більше 10 років
     */
    ExperienceIds?: Array<string>

    /**
     * Вид освіти.
     * 1 - вища, 2 - незакінченна вища, 3 - середньо-спеціальна, 4 - середня, 5 - МВА, 6- Дія. Фифрова освіта
     */
    EducationIds?: Array<string>
    DistrictIds?: Array<string>
    Disabled?: boolean

    /**
     * Мова резюме.
     * 1 - рус, 2 - анг, 3 - укр.
     */
    CvLanguage?: 'All' | 'Russian' | 'English' | 'Ukrainian'
    Count?: number

    /**
     * ID міста.
     */
    CityId?: number

    /**
     * Галузь компанії - https://api.rabota.ua/dictionary/branch
     */
    BranchIds?: Array<string>

    /**
     * Може приймати значенн null
     */
    AgeTo?: number

    /**
     * Може приймати значенн null
     */
    AgeFrom?: number
  }
}

export { CvDbService }
