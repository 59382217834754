import { CompanyStateEnum, CompanyVerificationStateEnum } from '@alliance/shared/domain-gql'

import { CompanyVerificationsState, CompanyVerificationStatus } from '../models'

const VERIFICATION_BY_COMPANY_STATE_MAP: Record<CompanyVerificationStatus, CompanyStateEnum[]> = {
  checked: [CompanyStateEnum.Mega, CompanyStateEnum.Validated],
  onInspection: [CompanyStateEnum.ExpectationOfActivation],
  deflected: [CompanyStateEnum.ExpectationOfActivation],
  registered: [CompanyStateEnum.ExpectationOfActivation]
}

const DEFAULT_VERIFICATION_STATUS: CompanyVerificationStatus = 'registered'

export const getVerificationStatusByCompanyData = (company: Omit<CompanyVerificationsState, 'verificationStatus'>): CompanyVerificationStatus => {
  const { blockingReasons, state, verificationByQES, verifiedByAdminState } = company

  if (!state) {
    return DEFAULT_VERIFICATION_STATUS
  }

  if (VERIFICATION_BY_COMPANY_STATE_MAP.checked.includes(state) && verificationByQES?.isVerified) {
    return 'checked'
  }

  if (VERIFICATION_BY_COMPANY_STATE_MAP.onInspection.includes(state) && !blockingReasons && verifiedByAdminState === CompanyVerificationStateEnum.Pending) {
    return 'onInspection'
  }

  if (VERIFICATION_BY_COMPANY_STATE_MAP.deflected.includes(state) && blockingReasons) {
    return 'deflected'
  }

  return DEFAULT_VERIFICATION_STATUS
}
