import { APP_INITIALIZER, ErrorHandler, Injector, ModuleWithProviders, NgModule } from '@angular/core'
import { Router } from '@angular/router'
import { NAVIGATOR, WINDOW } from '@ng-web-apis/common'

import { BrowserTracing, init, TraceService } from '@sentry/angular-ivy'
import { TransactionContext, Event, EventHint } from '@sentry/types'

import { Environment } from '@alliance/shared/environment'
import { LocalStorage } from '@alliance/shared/storage'
import { CountryCodesEnum } from '@alliance/shared/translation'
import { DetectPlatformService, MediaService, commit, isObjectWithProperty } from '@alliance/shared/utils'

import { denyUrls, ignoreErrors } from './constants'
import { SENTRY_INIT_OPTIONS, SentryInitOptions } from './models'
import { SentryBrowserErrorHandlerService } from './sentry-browser-error-handler.service'
import { sentryIsEnabled } from './utils'

const beforeNavigate = (context: TransactionContext, isMobile: boolean, mappingFunc: (contextName: string) => string = (name: string): string => name): TransactionContext => {
  let nameFormatted = context.name.replace(`/${CountryCodesEnum.RU}`, '') || '/'

  if (mappingFunc) {
    nameFormatted = mappingFunc(nameFormatted)
  }

  return {
    ...context,
    data: {
      ...context.data,
      originalName: context.name,
      isMobile
    },
    name: nameFormatted
  }
}

const beforeSend = (event: Event, injector: Injector, hint?: EventHint): Event | null => {
  const windowRef = injector.get(WINDOW)
  const navigator = injector.get(NAVIGATOR)

  if (!windowRef || navigator?.userAgent.includes('AdsBot-Google') || navigator?.userAgent.includes('Opera Mini')) {
    return null
  }

  const eventException = event?.exception?.values?.[0]?.value || ''
  const hintOriginalException = hint?.originalException
    ? isObjectWithProperty<{ message: unknown }>(hint.originalException, 'message')
      ? hint.originalException.message
      : hint.originalException ?? ''
    : ''

  // We want to ignore those kind of errors
  const isNonErrorException =
    typeof hintOriginalException === 'string' ? eventException.startsWith('Non-Error exception captured') || hintOriginalException.startsWith('Non-Error exception captured') : false

  return isNonErrorException ? null : event
}

export const sentryInit = (
  options: SentryInitOptions,
  environment: Environment,
  localStorage: LocalStorage,
  platform: DetectPlatformService,
  injector: Injector,
  mediaService: MediaService
): (() => void) => {
  const isMobile = mediaService.isMobileScreen()

  return (): void => {
    if (sentryIsEnabled(localStorage, platform)) {
      init({
        dsn: options.dsn,
        debug: !!localStorage.getItem('sentry_debug'),
        release: `${environment.name}_${commit}`,
        environment: environment.name,
        ignoreErrors,
        denyUrls,
        integrations: [
          new BrowserTracing({
            startTransactionOnLocationChange: false, // Отключаем автоматическое отслеживание навигации
            beforeNavigate: context => beforeNavigate(context, isMobile, options.transactionMapping),
            idleTimeout: 6000 // Время ожидания перед закрытием транзакции
          })
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampler: samplingContext => {
          if (options.transactionNamesList && options.transactionNamesList.includes(samplingContext.transactionContext.name)) {
            return parseFloat(options.tracesSampleRate || '0.1') // Процент отправляемых перфоманс-данных
          }

          return 0
        },

        /* for filtering some errors */
        beforeSend: (event: Event, hint?: EventHint): Event | null => beforeSend(event, injector, hint)
      })
    }
  }
}

@NgModule({
  providers: [
    {
      provide: ErrorHandler,
      useClass: SentryBrowserErrorHandlerService
    }
  ]
})
export class SharedSentryModule {
  public static forRoot(options: SentryInitOptions): ModuleWithProviders<SharedSentryModule> {
    return {
      ngModule: SharedSentryModule,
      providers: [
        {
          provide: TraceService,
          deps: [Router]
        },
        {
          provide: SENTRY_INIT_OPTIONS,
          useValue: options
        },
        {
          provide: APP_INITIALIZER,
          useFactory: sentryInit,
          deps: [
            SENTRY_INIT_OPTIONS,
            Environment,
            LocalStorage,
            DetectPlatformService,
            Injector,
            MediaService,
            /* force instantiate Tracing */
            TraceService
          ],
          multi: true
        }
      ]
    }
  }
}
