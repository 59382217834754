import { DataLayerPushEvent } from '@alliance/shared/utils'

type EventLabel = 'show' | 'click'

export const getUserIdleChatNotificationEvent = (eventLabel: EventLabel): DataLayerPushEvent => ({
  event: 'EMP_Events',
  eventCategory: 'EMP_Events',
  eventAction: 'chat_time_notification',
  eventLabel
})
