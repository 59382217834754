import { NotificationItemFragment } from '../services/notification-list/notification-list.generated'
import { NotificationDetailsUnion } from './notification-details-type'

export enum NotificationType {
  companyStateChanged = 'companyStateChanged',
  limitsChangeRequested = 'limitsChangeRequested',
  orderActivatedByTurbota = 'orderActivatedByTurbota',
  unpaidOrder = 'unpaidOrder',
  vacancyAwaitingApproval = 'vacancyAwaitingApproval',
  vacancyBannedByModerator = 'vacancyBannedByModerator',
  vacancyEndingCount = 'vacancyEndingCount',
  vacancyEndingLeftDays = 'vacancyEndingLeftDays',
  vacancyPublicationApproved = 'vacancyPublicationApproved',
  vacancyPublicationEnded = 'vacancyPublicationEnded',
  vacancyPublicationRejected = 'vacancyPublicationRejected',
  vacancyOwnerChanged = 'vacancyOwnerChanged',
  custom = 'custom'
}

export interface NotificationItem extends NotificationItemFragment {
  type: NotificationType
  details: NotificationDetailsUnion
}
