import { Environment } from '@alliance/shared/environment'
import { UserService } from '@alliance/shared/utils'
import { Inject, Injectable } from '@angular/core'
import { Params } from '@angular/router'
import { WINDOW } from '@ng-web-apis/common'

@Injectable({ providedIn: 'root' })
export class BuildFeedbackUrlService {
  public constructor(@Inject(WINDOW) private readonly windowRef: Window, private environment: Environment, private userService: UserService) {}

  public get feedbackQueryParams(): Params {
    return {
      userSide: this.userService.userSide,
      cameFromUrl: this.windowRef?.location?.href ?? ''
    }
  }

  public get feedbackUrl(): string {
    const baseFeedbackUrl = new URL((this.environment.mainSiteUrl ?? '') + 'feedback')
    Object.entries<string>(this.feedbackQueryParams).forEach(([key, value]) => baseFeedbackUrl.searchParams.append(key, value))
    return baseFeedbackUrl.toString()
  }
}
