/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { BaseService } from '../base-service'
import { NotificationDataAccessConfiguration } from '../notification-data-access-configuration'
import { StrictHttpResponse } from '../strict-http-response'

import { Message } from '../models/message'
import { messagesGet$Json } from '../fn/messages/messages-get-json'
import { MessagesGet$Json$Params } from '../fn/messages/messages-get-json'
import { messagesGet$Plain } from '../fn/messages/messages-get-plain'
import { MessagesGet$Plain$Params } from '../fn/messages/messages-get-plain'
import { messagesGetSingle$Json } from '../fn/messages/messages-get-single-json'
import { MessagesGetSingle$Json$Params } from '../fn/messages/messages-get-single-json'
import { messagesGetSingle$Plain } from '../fn/messages/messages-get-single-plain'
import { MessagesGetSingle$Plain$Params } from '../fn/messages/messages-get-single-plain'
import { messagesPatch$Json } from '../fn/messages/messages-patch-json'
import { MessagesPatch$Json$Params } from '../fn/messages/messages-patch-json'
import { messagesPatch$Plain } from '../fn/messages/messages-patch-plain'
import { MessagesPatch$Plain$Params } from '../fn/messages/messages-patch-plain'
import { messagesPost$Json } from '../fn/messages/messages-post-json'
import { MessagesPost$Json$Params } from '../fn/messages/messages-post-json'
import { messagesPost$Plain } from '../fn/messages/messages-post-plain'
import { MessagesPost$Plain$Params } from '../fn/messages/messages-post-plain'
import { MessageUpdateContextViewModel } from '../models/message-update-context-view-model'
import { MessageViewModel } from '../models/message-view-model'

@Injectable({ providedIn: 'root' })
export class MessagesService extends BaseService {
  constructor(config: NotificationDataAccessConfiguration, http: HttpClient) {
    super(config, http)
  }

  /** Path part for operation `messagesGet()` */
  static readonly MessagesGetPath = '/v1/conversations/{conversationId}/messages'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagesGet$Plain$Response(params: MessagesGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MessageViewModel>>> {
    return messagesGet$Plain(this.http, this.rootUrl, params, context)
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagesGet$Plain(params: MessagesGet$Plain$Params, context?: HttpContext): Observable<Array<MessageViewModel>> {
    return this.messagesGet$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<Array<MessageViewModel>>): Array<MessageViewModel> => r.body))
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagesGet$Json$Response(params: MessagesGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MessageViewModel>>> {
    return messagesGet$Json(this.http, this.rootUrl, params, context)
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagesGet$Json(params: MessagesGet$Json$Params, context?: HttpContext): Observable<Array<MessageViewModel>> {
    return this.messagesGet$Json$Response(params, context).pipe(map((r: StrictHttpResponse<Array<MessageViewModel>>): Array<MessageViewModel> => r.body))
  }

  /** Path part for operation `messagesPost()` */
  static readonly MessagesPostPath = '/v1/conversations/{conversationId}/messages'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagesPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  messagesPost$Plain$Response(params: MessagesPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MessageViewModel>> {
    return messagesPost$Plain(this.http, this.rootUrl, params, context)
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagesPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  messagesPost$Plain(params: MessagesPost$Plain$Params, context?: HttpContext): Observable<MessageViewModel> {
    return this.messagesPost$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<MessageViewModel>): MessageViewModel => r.body))
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagesPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  messagesPost$Json$Response(params: MessagesPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<MessageViewModel>> {
    return messagesPost$Json(this.http, this.rootUrl, params, context)
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagesPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  messagesPost$Json(params: MessagesPost$Json$Params, context?: HttpContext): Observable<MessageViewModel> {
    return this.messagesPost$Json$Response(params, context).pipe(map((r: StrictHttpResponse<MessageViewModel>): MessageViewModel => r.body))
  }

  /** Path part for operation `messagesPatch()` */
  static readonly MessagesPatchPath = '/v1/conversations/{conversationId}/messages'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagesPatch$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  messagesPatch$Plain$Response(params: MessagesPatch$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MessageUpdateContextViewModel>> {
    return messagesPatch$Plain(this.http, this.rootUrl, params, context)
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagesPatch$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  messagesPatch$Plain(params: MessagesPatch$Plain$Params, context?: HttpContext): Observable<MessageUpdateContextViewModel> {
    return this.messagesPatch$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<MessageUpdateContextViewModel>): MessageUpdateContextViewModel => r.body))
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagesPatch$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  messagesPatch$Json$Response(params: MessagesPatch$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<MessageUpdateContextViewModel>> {
    return messagesPatch$Json(this.http, this.rootUrl, params, context)
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagesPatch$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  messagesPatch$Json(params: MessagesPatch$Json$Params, context?: HttpContext): Observable<MessageUpdateContextViewModel> {
    return this.messagesPatch$Json$Response(params, context).pipe(map((r: StrictHttpResponse<MessageUpdateContextViewModel>): MessageUpdateContextViewModel => r.body))
  }

  /** Path part for operation `messagesGetSingle()` */
  static readonly MessagesGetSinglePath = '/v1/conversations/{conversationId}/messages/{messageId}'

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagesGetSingle$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagesGetSingle$Plain$Response(params: MessagesGetSingle$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Message>> {
    return messagesGetSingle$Plain(this.http, this.rootUrl, params, context)
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagesGetSingle$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagesGetSingle$Plain(params: MessagesGetSingle$Plain$Params, context?: HttpContext): Observable<Message> {
    return this.messagesGetSingle$Plain$Response(params, context).pipe(map((r: StrictHttpResponse<Message>): Message => r.body))
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagesGetSingle$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagesGetSingle$Json$Response(params: MessagesGetSingle$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Message>> {
    return messagesGetSingle$Json(this.http, this.rootUrl, params, context)
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagesGetSingle$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagesGetSingle$Json(params: MessagesGetSingle$Json$Params, context?: HttpContext): Observable<Message> {
    return this.messagesGetSingle$Json$Response(params, context).pipe(map((r: StrictHttpResponse<Message>): Message => r.body))
  }
}
